<template>
  <div id="app">
    <!-- <MainLayout/> -->
    <v-main>
      <component :is="layout"></component>
    </v-main>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import MainLayout from "./components/layout/Main.vue"
  import ClearLayout from "./components/layout/LayoutClear.vue";
  export default {
    components:{
      MainLayout,
      ClearLayout
    },
    data (){
      return {
        clientId: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
        domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN
      }
    },
    computed:mapState (['layout']),
    methods:{
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}

.v-main{
  background: #f5f5f5;
}

.color-text-glya{
  color: rgba(0, 0, 0, 0.6);
}
.line-height-glya{
  line-height: 1.2rem;
}
.v-chip.v-size--small {
  height: 18px;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1600px;
  }
}


.v-sheet.v-card:not(.v-sheet--outlined) {

  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);

}
.v-sheet.v-card {

  border-radius: 10px;
}

/* ESTILOS DE FORMULARIOS (IINPUT, SELECT, SELECT SEARCH, DATE, TETX AERA, CHECKBOX, RADIO, BUTTON) */

.theme--light.v-input input::placeholder, .theme--light.v-input textarea::placeholder {
  color: rgba(0, 0, 0, 0.60);
}


.inputstiliced > .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 47px
}
.inputstiliced > .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 47px
}
.inputglya > .v-input, .inputglya> .v-input > .v-label {
    font-size: 14px;
}
.inputglya > .v-input > .v-text-field {
    padding-top: 6px;
    margin-top: 2px;
}
.element > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 30px;
}
.element > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
    top: 6px;
    left: 0px !important;
}
.element > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label--active {
    transform: translateY(-15px) scale(0.75);
}
.text-uppercase input {
    text-transform: uppercase;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.inputglya .error--text::before {
  color: #7d7d7d;
}
.inputglya .mdi::after, .mdi-set {
  color: #7d7d7d;
}
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  color: #7d7d7d !important
}

.filter > .v-input__control > .v-input__slot, .login > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 30px;
}
.filter .v-input__prepend-outer,.login .v-input__prepend-outer,  .v-input__append-outer {
  margin-top: 5px;
}
 .v-application--is-ltr .inputdate .filter  .v-label, .login .v-text-field__slot  .v-label{
  left: -15px !important;
}
.filter.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: 30px;
}
.v-select.filter:not(.v-text-field--single-line) .v-select__selections {
  padding: 0px ;
}
.filter .v-input__prepend-outer .v-icon.v-icon {
  font-size: 17px;

}
.v-select.element:not(.v-text-field--single-line) .v-select__selections {
  padding: 0px ;
}
.filter .v-input__append-inner, .login .v-input__append-inner {
  margin-top: 6px;
}
.element> .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 30px;
}
.v-application--is-ltr .v-text-field .v-label {
  transform-origin: top left;
  top: 6px;
  color: #6f6f6f !important;
}
.label_none > .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label,
.label_none > .v-text-field > .v-input__control > .v-input__slot > .v-select__slot > .v-label {
    display: none !important
}

.element .v-input__append-inner {
  margin-top: 4px;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 2px;
}
.inputglya > .v-input, .inputglya> .v-input > .v-label {
    font-size: 14px;
}
.inputglya > .v-input > .v-text-field {
    padding-top: 6px;
    margin-top: 2px;
}
.text-uppercase input {
    text-transform: uppercase;
}
.switchglya {
    margin-top: 0px;
}
.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 2px;
  }
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
      margin-left: 5px;
  
    }
    .negative-margin-25{
  margin-top: -25px;
  }
  .negative-margin-20{
  margin-top: -20px;
  }
  .negative-margin-30{
  margin-top: -30px;
  }
  .negative-margin-8{
  margin-top: -8px;
  }
  .element-switch {
  font-size: 13px;
  }
  .switchglya {
  margin-top: -7px;
  }

  .v-select.v-select--chips .v-select__selections {
    min-height: 31px;
}
.v-input {
    font-size: 14px;
}


.riesgos .v-alert__icon {
    align-self: center;
    height: 35px;
    min-width: 35px;
}

.login>.v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
    left: 0px !important;
}

.v-snack__wrapper {

    transition-duration: 0.55s;
    border: 0.5px solid;
}
.v-date-picker-title__date > div {
    display: none !important;
}

.attention {
        background-color: azure;
        border: solid 1px lightgray;
        border-radius: 5px;
        padding: 10px;
    }

  /* TERMINA ESTILOS DE FORMULARIOS (IINPUT, SELECT, SELECT SEARCH, DATE, TETX AERA, CHECKBOX, RADIO, BUTTON) */
</style>