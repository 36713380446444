<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn outlined class="mr-4 mt-2 pa-5"  color="indigo darken-2" @click="setToday">
        Hoy
      </v-btn>
      <v-select v-model="typec" :items="typecalendar" dense outlined hide-details class="ma-2" label="Tipo">
      </v-select>

      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()" >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-col class="ml-5 mr-5">
      <v-row>
        <v-col v-for="state in status" :key="state.value" cols="12" sm="auto">
          <v-icon class="pr-2" :color="statuscativity(state.value)">mdi-circle</v-icon>{{state.name}}
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pa-5">
      <v-toolbar-title v-if="$refs.calendar">
        <span class="text-button deep-purple--text lighten-1">{{ $refs.calendar.title }}</span>
      </v-toolbar-title>
      <v-calendar ref="calendar" v-model="value" :interval-format="intervalFormat" :type="typec" :weekdays="weekday" :events="events"  @click:event="showEvent" @click:date="viewDay"  @change="dateChanged"></v-calendar>
      <v-dialog v-model="selectedOpen" persistent max-width="500px">
        <v-card min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark >
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="!permissions.manage_activities">
              <v-btn v-if="selectedEvent.status_code == 0" :disabled="!permissions.manage_activities" icon>
                <v-icon>{{edit==false ? icon_edit : icon_back}}</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn v-if="selectedEvent.status_code == 0" icon @click="edit=!edit">
                <v-icon>{{edit==false ? icon_edit : icon_back}}</v-icon>
              </v-btn>
            </div>
          </v-toolbar>
          <v-card-text v-if="edit" class="mt-3">
            <v-row>              
              <v-col cols="12" sm="6" class="pt-0 pb-0" >
                <BaseDate limitType="expiration" label="Fecha de toma" v-model="selectedEvent.programmed_date" validador="required" ></BaseDate>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                <BaseSelectMult type="text" label="Hora de toma" validador="required" list_type="horas" v-model="selectedEvent.programmed_time"></BaseSelectMult>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else class="mt-3">
            <span class="mb-2"><b>Fecha y Hora:</b> {{selectedEvent.start | formatDate}} </span><span v-if="timeZone != user.time_zone">({{timeZone}})</span>
            <br>
            <span><b>Estado:</b><v-chip outlined :color="selectedEvent.color" class="ml-2 pt-0 pb-0 pl-5 pr-5">{{selectedEvent.status}}</v-chip></span>
            <div v-if="!permissions.manage_activities">           
              <v-btn v-if="selectedEvent.status_code==0" :disabled="!permissions.manage_activities" block class="pl-5 pr-5 mt-2 white--text" depressed color="orange" small>
                Cancelar actividad
              </v-btn>
            </div>
            <div v-else>           
              <v-btn v-if="selectedEvent.status_code==0" @click="cancelActivity(selectedEvent)" block class="pl-5 pr-5 mt-2 white--text" depressed color="orange" small>
                Cancelar actividad
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="closeAll" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >
              Cerrar
            </v-btn>
            <v-btn v-if="edit" @click="saveAction(selectedEvent)" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small>
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import BaseSelectMult from '@/components/forms_items/SelectMultiple.vue'
import BaseDate from '@/components/forms_items/BaseDate.vue'
import AxiosServices from "@/services/AxiosServices";

export default {
  data() {
    return {
      events: [],
      value:'',
      typec: 'week',
      typecalendar: [
        { text: 'Mes', value:'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Día', value: 'day'}
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      permissions:{},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      edit:false,
      icon_edit:'mdi-pencil',
      icon_back: 'mdi-keyboard-return',
      status:[
        {value:0, name:'Programada'},
        {value:1, name:'En proceso'},
        {value:2, name:'Completada'},
        {value:3, name:'Cancelada'},
        {value:4, name:'Fallida'},
        {value:5, name:'Suspendida'},
        {value:6, name:'No reportada'}
      ]
    };
  },
  props: {
    patient_id: {},
    timeZone:{
      type: String,
      default: "", 
    },
    order_id: {},
    type: {
      type: String,
      default: "patient", // patient, order
    },
    inicial: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dateChanged() {
      this.getCalendar()
      },
    intervalFormat(interval) {
      return interval.time
  },
    closeAll:function(){
      this.selectedOpen = false
      this.edit = false
    },
    getCalendar: function () {
      let params = {star_date: this.$refs.calendar.lastStart.date, end_date: this.$refs.calendar.lastEnd.date }
      if (this.type == "patient") {
        AxiosServices.patientActivityAllList(this.patient_id, params).then((res) => {
          this.events = []
          this.edit = false
          this.rendercalendar("patient",res.data.data,null)
          this.activities = res.data.activities;
        }).catch((error) => {
          if (error.code !='ECONNABORTED') {
            this.$store.dispatch("addNotification", error.response.data);
            this.$store.dispatch("render404");          
          }         
        });
      } else if (this.type == "order") {
        AxiosServices.patientActivityByOrder(this.patient_id, this.order_id).then(
          (res) => {
            this.events = []
            this.edit = false
            this.rendercalendar("order",res.data.data.activities);
          }
        ).catch((error) => {
          if (error.code !='ECONNABORTED') {
            this.$store.dispatch("addNotification", error.response.data);
            this.$store.dispatch("render404");          
          }
        });
      }
    },
    updateActivity:function(order_id,activity_id,activity){
      AxiosServices.patientOrdersActivitiesUpdate(this.patient_id,order_id,activity_id,activity)
      .then(res => {
        this.$store.dispatch("addNotification", res.data)
        this.getCalendar()
      }).catch( error => {
        console.log('error al actualizar',error);
        this.$store.dispatch("addNotification", error.response.data);
      })
      this.closeAll()
    },
    saveAction:function(event){
      let activity = {}
      activity['programmed_at'] = this.formatTime(event.programmed_date,event.programmed_time)
      this.updateActivity(event.order_id,event.id,activity)
    },
    cancelActivity:function(event){
      let activity = {}
      activity['status']=3
      this.updateActivity(event.order_id, event.id, activity)
    },
    formatTime: function (date, time) {
      var date_only = date.split(" ")[0];
      var date_time = date_only + " " + time;
      return date_time
    },
    formatdate: function (date){
      let fecha2 = new Date(date);
      let fecha = new Date(fecha2.toLocaleString("en-US", { timeZone: this.timeZone }));
      return fecha
    },
    rendercalendar:function(type,activities){
      if (type=="order"){
        for (let act of activities){
          let event={}
          let activity = act.order_description.split('los días')
          let activitymeddicine = act.order_description.split('cada')
          event.start= this.formatdate(act.programmed_at)
          event.test = act.programmed_at
          event.end= new Date(event.start.getTime()+(60*60*1000))
          event.timed=(60*60*1000)
          event.name=activity[0]
          event.name=activitymeddicine[0]
          event.status=act.status_name
          event.status_code = act.status
          event.color=this.statuscativity(act.status)
          event.id=act.id
          event.programmed_date = this.splitDate(this.formatdate(act.programmed_at), 'date')
          event.programmed_time = this.splitDate(this.formatdate(act.programmed_at), null)
          this.events.push(event)
        }
      } else if (type=="patient"){
        for (let act of activities){
          let event={}
          let activity = act.order_description.split('los días')
          event.start=this.formatdate(act.programmed_at)
          event.end=new Date(event.start.getTime()+(60*60*1000))
          event.name=activity[0]
          event.status=act.status_name
          event.status_code = act.status
          event.color=this.statuscativity(act.status)
          event.timed=(60*60*1000)
          event.id = act.id
          event.programmed_date = this.splitDate(act.programmed_at,'date')
          event.programmed_time = this.splitDate(act.programmed_at, null)
          this.events.push(event)
        }      
      }
    },
    splitDate:function(date,type){
      var dateTime = new Date(date);
      var timeZoneOffset = dateTime.getTimezoneOffset();
      dateTime.setMinutes(dateTime.getMinutes() - timeZoneOffset);
      var date_only = dateTime.toISOString().split('T')[0];
      var time = dateTime.toISOString().split('T')[1].slice(0, 5);
      if (type=='date'){
        return date_only
      }else{
        return time
      }   
    },
    statuscativity:function(status){
      switch (status){
        case 0:
            return "blue"
        case 1:
            return "yellow darken-3"
        case 2:
            return "green"
        case 3:
            return "red"
        case 4:
            return "grey"
        case 5:
            return "black"
        case 6:
            return "deep-purple accent-2"
      }
    },
    setToday () {
      this.value = ''
    },
    showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      viewDay ({ date }) {
        this.value = date
        this.typec = 'day'
      },
      getPermission: function (){
        AxiosServices.patientDetail(this.patient_id).then(res => {
            this.permissions=res.data.data.permissions
        }).catch(error => {
            console.log('el error es', error);
        })
      },
  },
  mounted() {
    this.$store.dispatch("reseterror");
    this.getPermission()
  },
  computed: {
    prueba: function () {
      var variable1 = this.selectedEvent.programmed_at;
      // if (variable1 == true) {
      //   this.getCalendar()
      // }
      return variable1;
    },
    user:function(){
      return this.$store.state.user
  }
  },
  created() {},
  components: {
    BaseSelectMult,
    BaseDate
  },
};
</script>
<style>

.v-calendar .v-event-summary {
  white-space: normal;
}
.v-calendar .v-event {
  height: auto !important;
}
.v-calendar-weekly__day-label .theme--light.v-btn.v-btn--has-bg {
  background-color: #e5e0ff;
}
.v-calendar-weekly__day-label .v-btn::before {
  background-color: #6ec1da;
}

.v-calendar .v-event-summary {
  line-height: 13px;
}
.v-calendar .v-event-summary {
  max-height: 45px;
}
</style>
