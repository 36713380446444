<template>
    <div class="inputglya">
        <v-radio-group v-model="radioGroup" :row="rowin" :column="columnin" @change="updateValue" :error-messages="nameErrors">
            <v-radio v-for="item in list" :key="item['key']" :label="item['key']" :value="item['value']" class="mr-5" ></v-radio>
        </v-radio-group>       
    </div>
</template>
<script>


import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

    name: "RadioButtonGroup",   

    props: {
        rowin: {
            type: Boolean,
            default: false
        },
        columnin: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array
        },
        validador: {
            default: 'none'
        },
        label:{
            type: String,
            default: 'selección simple vacía'
        }
    },
    model: {
        prop: "value",
        event: "blur"
    },
    mounted() {
        this.eval_errors();
    },
    data() {
        return {
            column: false,
            row: false,
            radioGroup:this.$attrs.value,
            fromvuex: this.$store.state.validations2[this.$props.validador],
            id: `${this.$props.label} ${Math.floor(Math.random() * 1000000)}`,
            minl: 5
        }
    },
    methods: {
        updateValue(value) {
            value=this.radioGroup
            this.$emit('blur', value);
            this.eval_errors()
        },
        eval_errors: function () {
            if (this.nameErrors.length == 0) {
                this.$store.dispatch("puterror", { [this.id]: 0 })
            } else {
                this.$store.dispatch("puterror", { [this.id]: 1 })
            }
        }
    },     
    components: {
    },
    computed: {
        eval_required_show: function () {
            return this.$store.state.show_required
        },
        nameErrors() {
            let errors = []
            if (!this.$v.radioGroup.required && this.fromvuex['required'] !== undefined) {
                if (this.eval_required_show) {
                    errors.push('¡El campo es requerido!')
                } else {
                    errors.push(' ')
                }
            }
            return errors
        }
    },
    mixins: [validationMixin],
    validations: function () {
        if (this.minl > 0) {
            return {
                radioGroup: eval(this.fromvuex)
            }
        }
        return {
            x: {
                required
            }
        }
    }

}

</script>
<style>

</style>