<template>
    <div>
        <!-- abre informacion basica -->
        <v-row>
            <v-col class="pa-2" cols="12">
                <BasicDataPatient :patient_id="patient_id" @reloadData="getPatient"></BasicDataPatient> 
            </v-col>
        </v-row>
        <!-- cierra informacion basica -->
        <!-- abre HC  -->
        <v-row>
            <v-col cols="12" md="8">
                <v-card class="mx-auto">
                    <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1">
                            Resumen Historia Clinica
                        </span>
                        <v-spacer></v-spacer>
                        <v-dialog transition="dialog-bottom-transition" v-model="dialogHC" max-width="800" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-if="!permissions.add_tags" :disabled="!permissions.add_tags" icon color="white" class="edit-carer">
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                                <v-btn v-else @click="getPatient" icon color="white" class="edit-carer" v-bind="attrs" v-on="on">
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                                <v-card>
                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Resumen Historia Clinica</v-toolbar>
                                    <v-card-text>
                                        <div class="text-h2 mt-4">
                                            <v-row justify="center">
                                                <v-col cols="12 mt-3" class="carer-modal">
                                                    <div>
                                                        <v-row>
                                                            <v-col cols="12" class="pt-0">
                                                                <p class="text-body-2 ">Proporciona la información relevante para completar el resumen de Historia clínica en el siguiente campo de texto.</p>
                                                                <BaseTextArea type="text" rows="3" v-model="patient.clinic_history" :outlined="true" ></BaseTextArea>                                                                                       
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="justify-end">
                                        <v-btn @click="closeHC" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                        <v-btn disabled v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                        <v-btn v-else @click="saveHistory" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-title>
                    <v-card-text class="text-body-2 pt-3">
                        <v-card v-if="patient.clinic_history!='' && patient.clinic_history!= null" color="blue-grey lighten-5" class="pa-3 scrollable3 mb-2" elevation="0">
                            <span class="myHC">{{patient.clinic_history}}</span>
                        </v-card>                     
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="50">mdi-clipboard-text-outline</v-icon>
                                <h3>No tiene Información</h3>
                                <h5>Escribe un resumen de Historia clínica</h5>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        <!-- cierra HC -->
        <!-- abre Prescripcion  -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title class="indigo white--text pt-1 pb-2 card-title-show">
                        <span class="text-body-1">
                            Prescripción actual
                        </span>
                    </v-card-title>
                    <v-card-text class="text-body-2 pa-2">
                        <v-card v-if="medications_prescription.length != 0 || vitals_prescription.length != 0" class="pa-0 scrollable3 mb-2" elevation="0">
                            <v-alert v-for="medication in medications_prescription" :key="medication.id"  class="pa-1 mb-2 prescription" dense outlined icon="mdi-pill" @click="goOrder(medication.id,patient.id)">
                                <h4>{{medication.medication}}</h4>
                                <v-row class="d-flex justify-space-between">
                                    <v-col cols="8">
                                        <p class="text-body-2 pa-0 ma-0 primary--text">{{medication.frecuency}}</p>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-chip color="success" small>{{medication.progress}}</v-chip>
                                    </v-col>
                                </v-row>
                            </v-alert>
                            <v-alert v-for="vital in vitals_prescription" :key="vital.id"  class="pa-1 mb-2 prescription" dense outlined icon="mdi-pulse" @click="goOrder(vital.id,patient.id)">
                                <h4>{{vital.clinical_element}}</h4>
                                <v-row class="d-flex justify-space-between">
                                    <v-col cols="8">
                                        <div id="app">
                                            <div v-for="(day, index) in weekdays" :key="index" :class="['day-box', { 'blue': vital.days.includes(index) }]">{{ day }}</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-chip color="success" small>{{vital.progress}}</v-chip>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-card>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="50">mdi-card-bulleted</v-icon>
                                <h3>No tiene Prescripción</h3>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- cierra prescripcion -->
        <!-- abre contenedor general -->
        <v-row>
            <!--abre información de contacto -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1">
                            Información de contácto
                        </span>
                        <v-spacer></v-spacer>
                        <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="800" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-if="!permissions.edit" :disabled="!permissions.edit" icon color="white" class="edit-carer">
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                                <v-btn v-else @click="getPatient" icon color="white" class="edit-carer" v-bind="attrs" v-on="on">
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                                <v-card>
                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Información de contacto</v-toolbar>
                                    <v-card-text>
                                        <div class="text-h2 mt-4">
                                            <v-row justify="center">
                                                <v-col cols="12 mt-3" class="carer-modal">
                                                    <div>
                                                        <v-row>
                                                            <v-col cols="12" md="6" class="pt-0 pb-0">
                                                                <SelectSearch label="Ciudad" model="cities" :country="patient.country_id" v-model="patient.city_id" validador="required"
                                                                :valuex="patient.city_id == null ? 1 : {id:patient.city_id,text:patient.city_name}"></SelectSearch>                                                            
                                                            </v-col>
                                                            <v-col cols="12" md="6" class="pt-0 pb-0">
                                                                <BaseInput v-model="patient.address" type="text" label="Dirección de residencia" validador="required"></BaseInput>
                                                            </v-col>
                                                            <v-col cols="12" md="6" class="pt-0 pb-0">                                                                
                                                                <BaseInput v-model="patient.neighborhood" type="text" :label="patient.country_id == 26 ? 'Barrio' : 'Colonia'" validador="required"></BaseInput>                                                                
                                                            </v-col>
                                                            <v-col cols="4" md="2" class="pt-0 pb-0">
                                                                <SelectSearch label="Indicativo" model="prefixes" v-model="patient.prefix" :prefixes="true" validador="required"
                                                                :valuex="patient.prefix == null ? {id:prefix_default,text:prefix_default} : {id:patient.prefix, text:patient.prefix}"></SelectSearch>                                                            
                                                            </v-col>
                                                            <v-col cols="8" md="4" class="pt-0 pb-0">
                                                                <BaseInput v-model="patient.cellphone" type="text" label="Teléfono (WhatsApp)" validador="phoneRequired"></BaseInput>
                                                            </v-col>                                                            
                                                            <v-col cols="12" md="6" class="pt-0 pb-0">
                                                                <BaseInput v-model="patient.email" type="email" label="Correo Electronico" validador="emailRequired"></BaseInput>
                                                            </v-col>                                                               
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="justify-end">
                                        <v-btn @click="clearPatient" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                        <v-btn disabled v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                        <v-btn v-else @click="saveContactInfo" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-title>
                    <v-card-text class="text-body-2 pt-3">
                        <p class="mb-0">
                            <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-home</v-icon>
                            <span v-if="patient.address == '' || patient.address == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                Dato Incompleto</v-chip>
                            </span>
                            <span v-else>{{patient.address}} 
                                <span>{{ patient.neighborhood }} </span>                                
                            </span>
                        </p>
                        <p class="mb-0">
                            <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-city</v-icon>
                            <span v-if="patient.city_name == '' || patient.city_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                Dato Incompleto</v-chip>
                            </span>
                            <span v-else>{{patient.city_name}}</span>
                        </p>
                        <p class="mb-0">
                            <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-cellphone</v-icon>
                            <span v-if="patient.cellphone == '' || patient.cellphone == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                Dato Incompleto</v-chip>
                            </span>                            
                            <span v-else>{{patient.prefix}} {{ patient.cellphone }}</span>
                        </p>
                        <p class="mb-0">
                            <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-email</v-icon>
                            <span v-if="patient.email == '' || patient.email == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                Dato Incompleto</v-chip>
                            </span>
                            <span v-else>{{patient.email}}</span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- cierra información de contacto -->
            <!-- abre tags -->
            <v-col cols="12" sm="6" md="4">
                <v-card class="mx-auto" min-height="140">
                    <v-card-title class="indigo white--text pa-0 pl-5">
                        <span class="text-body-1">
                            Etiquetas
                        </span>
                        <v-spacer></v-spacer>
                        <FormTags :id="patient.id" label="Seleccione un color" :icon="patient.tags.length >= 0 ? 'mdi-pencil-circle' : 'mdi-plus-circle'" modal_name="Agregar etiqueta" @updatetags="reload_page()"></FormTags>
                    </v-card-title>
                    <v-card-text class="text-body-1 mt-5">
                        <div v-if="patient.tags.length >= 1">
                            <v-row class="d-flex justify-start">
                                <div class="ma-1" v-for="tag_show in patient.tags" :key="tag_show.id">
                                    <ShowTag send_tag_id="delete_tag_id" :tag_item="tag_show"></ShowTag>
                                </div>
                            </v-row>
                        </div>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="50">mdi-tag</v-icon>
                                <h3>No existen etiquetas</h3>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- cierra tags -->
            <!-- abre alertas -->
            <v-col cols="12" sm="6" md="4">
                <v-card class="mx-auto" min-height="140">
                    <v-card-title class="amber darken-2 white--text pa-0 pl-5">
                        <span class="text-body-1 pt-1 pb-2">
                            Alertas
                        </span>                  
                    </v-card-title>  
                    <v-card-text  class="text-body-1 pt-2">
                        <v-col v-if="patient.active_alerts_count!='' && patient.active_alerts_count!=0">
                            <v-row class="pt-3">
                                <div  @click="goTabAlert" class="count_alert mr-3"><h1><v-icon size="20" class="pb-1 pr-3" dark>mdi-bell</v-icon>{{ patient.active_alerts_count }}</h1></div>
                                <div><h2 class="pt-3">Alertas activas</h2></div>
                            </v-row>
                        </v-col>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="50">mdi-bell</v-icon>
                                <h3>No hay alertas activas</h3>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- cierra alertas  -->
        </v-row>
        <!-- cierra contenedor general -->
        
        <!-- abre tabs -->
        <v-row>
            <v-col cols="12">
                <TabsData  ref="tabs" :patient_id="patient.id"></TabsData>
            </v-col>
        </v-row> 
        <!-- cierra tabs -->
    </div>
</template>

<script>
import BasicDataPatient from '@/components/views/BasicData.vue'
import AxiosServices from '@/services/AxiosServices';
// import AlertsPatient from '@/components/views/AlertsPatient.vue'
// import FormBasicData from "@/components/forms/FormBasicData.vue";
import FormTags from "@/components/forms/FormTags.vue";
import BaseInput from '@/components/forms_items/BaseInput.vue'
// import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import BaseTextArea from '@/components/forms_items/BaseTextArea.vue'
import SelectSearch from '@/components/forms_items/SelectSearch.vue';
import TabsData from '@/components/views/TabsData.vue'
import ShowTag from '@/components/views/ShowTag.vue';
import axios from 'axios'
export default {
    name: "ShowPatient",
    mounted(){
        this.getPatient()        
    },
    
    data(){
        return{  
            patient_id:this.$route.params.id,
            model_prop: {
                    type:String,
                    default: "patients"
                },
                dialog:false,
                dialogHC:false,
                city:false,
                tags:[],
                on:false,
                alerts:[],
                patient:{tags:[]},
                alertgenerates:[],
                ciudad:false,
                permissions:{},
                weekdays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                vitals_prescription:[],
                medications_prescription: []
            }
        },
        props:{
            model: {
                type: String,
                default: 'Patient'
            },
            id: {
                type: String,
                default:''
            }
        },
        beforeMount() {
            this.$root.$on('send', data =>{
                this.city = data;
                
            })
        },
        methods:{ 
            goTabAlert() {
                this.$refs.tabs.alertsTab(); 
            },
            clearPatient: function(){
                this.dialog=false
                this.patient={tags:[]}
                this.getPatient()
            },
            getPatient: function () {  
                AxiosServices.patientDetail(this.patient_id).then(res => {
                    this.patient = res.data.data
                    this.permissions = res.data.data.permissions
                    this.medications_prescription = res.data.data.medications_prescription
                    this.vitals_prescription = res.data.data.vitals_prescription
                }).catch((error) => {
                    if (error.code !='ECONNABORTED') {
                        this.$store.dispatch("addNotification", error.response.data);
                        this.$store.dispatch("render404");          
                    }                    
                })
            },
            saveContactInfo:function(){
                let patient={}
                patient['address']=this.patient.address
                patient['neighborhood']=this.patient.neighborhood
                patient['city_id']=this.patient.city_id
                patient['cellphone']=this.patient.cellphone
                patient['prefix']=this.patient.prefix
                patient['email']=this.patient.email
                this.saveAction(patient)
            },
            closeHC:function(){
                this.dialogHC= false
                this.getPatient()               
            },
            saveHistory:function(){
                let patient={}
                patient.clinic_history=this.patient.clinic_history
                this.saveAction(patient)
            },
            saveAction: function (patient) {
                this.$store.dispatch("show_required")
                AxiosServices.patientUpdate(this.patient.id, patient).then(res => {
                    this.dialog = false
                    this.dialogHC = false
                    this.$store.dispatch("addNotification", res.data)
                    this.getPatient()
                })
            },
            chageStatus: function () {
                    window.location.reload();       
            },
            coloralerta:function(color){
                switch (color){
                    case "temp":
                    return "warning"
                    case "fc":
                    return "error"
                    case "spo2":
                    return "info"
                    case "tasistolica":
                    return "success"
                    case "glucometria":
                    return "red darken-4"
                }
            },
            get_tags:function(){  
                axios.get(this.$URL +"tags/get_tags_model?"+`model_name=${this.$props.model}&model_id=${this.$route.params.id}`).then(res =>{
                    this.tags=res.data
                })              
            },
            reload_page:function(){
                this.getPatient()
                //this.getPatient()          
            },
            iconalerta:function(color){
                switch (color){
                    case "programada":
                        return "mdi-bell-ring"
                    case "fc":
                        return "mdi-heart-pulse"
                    case "spo2":
                        return "mdi-lungs"
                    case "fr":
                        return "mdi-lungs"
                    case "tasistolica":
                        return "mdi-pulse"
                    case "tadiastolica":
                        return "mdi-pulse"
                    case "glucometria":
                        return "mdi-water"
                    case "temp":
                        return "mdi-thermometer"
                }
            },
            goOrder:function(order,patient_id){  
                setTimeout(() => {
                    this.$router.push({name: 'orders', params: { order_id: order,patient_id:patient_id}})    
                }, 500); 
            },
        },
        watch:{
            'patient.status' (newVal, oldVal){
                if (oldVal==2 && newVal==1 ){
                    this.chageStatus(newVal);               
                }
            }
        },
        computed: {           
            verror:function(){
                return this.$store.state.form.reserror
            },
            prefix_default:function(){
                return  localStorage.getItem('user_prefix')
            },
        },
        components:{
        BasicDataPatient,
        // AlertsPatient,
        BaseInput,
        // BaseSelect,
        BaseTextArea,
        SelectSearch,
        TabsData,
        // FormBasicData,
        FormTags,
        ShowTag
    }
    
}
</script>
<style>
.theme--light.table-glya > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff0;
}
.button-edit-child{
    position: absolute;
    top: 0px;
    right: 0px;
}
.number-alert{
    background: #ffa000;
    color: white;
    padding: 15px;
    border-radius: 5px;
}
.count_alert{
    padding: 10px;
    background: #ffa000;
    border-radius: 5px;
    cursor: pointer;
}
.count_alert h1{
    color: white;
}
.br-responsive{
        display: none;
        margin-top: 5px;
    }
.scrollable3 {
    max-height: 207px; 
    overflow-y: auto; 
    overflow-x :hidden;
    border: 1px solid #ccc;
    padding: 10px;
}
.day-box {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 3px;
    text-align: center;
    line-height: 15px;
    font-size: 10px;
    font-weight: bold;
    color: #a5c2d5;
    border-radius: 2px;
    border: 1px solid #a5c2d5;
}
.myHC{
    white-space: pre-line;
}
.prescription{
    cursor: pointer;
}

    .blue { 
        background-color: #3498db; 
        color: white;
    }


    @media (max-width: 600px) {
        .br-responsive{
        display: block;
    } 
    }
</style>