<template>
  <div>
    <div ref="chartAlertsTotal" class="graph2"></div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AxiosServices from "@/services/AxiosServices";
export default {
  name: "alertsTotalChart",
  data() {
    return {
      data: [
        {
          type: "Sin Asignar",
          low: 1,
          medium: 2,
          high: 1,
        },
        {
          type: "Asignadas",
          low: 4,
          medium: 2,
          high: 1,
        },
      ],
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    rebuid: function () {
      this.buildchart()
        if (this.root){
          this.root.dispose()
        }
        this.fetchData().then(res=>{
          res
          this.buildchart();
        });
    },
    fetchData:async function () {
      this.buildchart()
      await AxiosServices.reportsClientAlertsOpenedMetrics(this.$store.state.user.client_id)
      .then(res => {
        this.setDataFormate(res.data.data.by_assignment_and_priority)
      })
      .catch(error => {
        console.log('error en get',error);
      })
      .finally(() => {
        this.makeseries()
      })      
    },
    setDataFormate: function(by_assignment_and_priority){
      let data = [
        {
          type: "Sin Asignar",
          low: by_assignment_and_priority['without_user']['low'],
          medium: by_assignment_and_priority['without_user']['medium'],
          high: by_assignment_and_priority['without_user']['high'],
        },
        {
          type: "Asignadas",
          low: by_assignment_and_priority['with_user']['low'],
          medium: by_assignment_and_priority['with_user']['medium'],
          high: by_assignment_and_priority['with_user']['high'],
        },
      ]
      this.data=data
    },
    buildchart: function () {
      let root = am5.Root.new(this.$refs.chartAlertsTotal);
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          pinchZoomX: false,
          pinchZoomY: false,
          panX: false,
          wheelX: false,
          wheelY: false,
          maxTooltipDistance: 0,
          layout: root.verticalLayout,
        })
      );
      var xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });
      xRenderer.grid.template.setAll({
        location: 1,
        strokeOpacity: 0.0,
      });
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "type",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      let yRendered = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      });
      yRendered.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          //strictMinMax: true,
          calculateTotals: true,
          renderer: yRendered,
        })
      );
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
        );
      this.xAxis = xAxis;
      this.yAxis = yAxis;
      //this.series = series;
      this.root = root;
      this.chart = chart;
      this.legend = legend;
      // this.makeseries();
    },
    makeseries: function () {
      var data=this.data 
      var root=this.root
      let bar_color = {
        low: 0x1976d2,
        medium: 0xf9a825,
        high: 0xff5252,
      };
      let seriesdef = [
        {
          nombre: "Bajo",
          cod: "low",
        },
        {
          nombre: "Medio",
          cod: "medium",
        },
        {
          nombre: "Alto",
          cod: "high",
        },
      ];
      for (let element of seriesdef) {
        var series = this.chart.series.push(
          am5xy.ColumnSeries.new(this.root, {
            name: element.nombre,
            stacked: true,
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            clickable: true,
            valueYField: element.cod,
            //valueYShow: "valueYTotalPercent",
            categoryXField: "type",
            fill: am5.color(bar_color[element.cod])
          })
        );
        series.columns.template.setAll({
          fillOpacity: 0.7,
          strokeWidth: 0,
        });

        series.bullets.push(function (root2,series2,dataitem2) {
          let visible=dataitem2.get("valueY")>0;
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              //text: series.height(),
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              fontSize: 13,
              populateText: true,
              visible: visible,
              masa:(root2,series2) //JUAQUIN
            }),
          });
        });




        series.data.setAll(data);

        this.legend.data.push(series);
      }
      this.xAxis.data.setAll(data);
      this.chart.appear(1000, 100);
      

    },
  },
};
</script>

<style scoped>
.graph2 {
  height: 380px
}
</style>
