<template>
    <div>
        <span class="ghost">{{saved_vaccine}}</span>
        <TitleForm title="Formulario vacunación" color="deep-purple lighten-2" icon="mdi-needle mt-1"> </TitleForm>
        <v-row> 
            <v-col cols="12" md="12">
                <v-card class="mx-auto pt-2">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <p class="text-overline blue-grey--text">Infomación de vacuna</p>
                            </v-col>
                            <v-col cols="12" md="3" sm="4" class="pt-0">
                                <BaseSelect v-model="vaccine_type" element="element" :outlined="true" type="text" model="medication" list="Vaccine_type" label="Tipo de Biológico"  validador="required"></BaseSelect>
                            </v-col>
                            <v-col cols="12" md="9" sm="8"  class="pt-0 pb-0 negative-margin-25">
                                <p class="mb-4">Laboratorio</p>
                                <div v-if="vaccine_type == '' ">
                                    <v-alert dense text color="blue-grey">
                                        Seleccione primero un tipo de biológico
                                    </v-alert>
                                </div>
                                <v-row>
                                    <v-col cols="12" md="6" class="pt-0 pb-0" v-for="vaccine in vaccines[vaccine_type]" :key="vaccine.id">
                                        <v-btn dark block small class="mb-2" @click="data_encounter.medication_id = vaccine._id" :color="vaccine._id == data_encounter.medication_id ? 'deep-purple lighten-1' : 'blue-grey lighten-2'">
                                            <v-card class="text-caption wrap-text" color="transparent" elevation="0">
                                                {{vaccine.generic_name}}
                                            </v-card>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" v-if="data_encounter.medication_id == null && vaccine_type != ''" class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div class="v-messages__message ml-3">¡El campo es requerido!</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="mb-3"></v-divider>
                            </v-col>
                            <!-- <v-col cols="12" md="3" sm="4" v-if="data_encounter.medication_id == ''">
                                <SelectSearch label="Lote" :outlined="true" element="element" disabled  model="stock" v-model="data_encounter.used_batches" :parameter="`[medical_supply_id:'${data_encounter.medication_id}']`" validador="required" :valuex="data_encounter.used_batches"></SelectSearch>
                            </v-col> -->
                            <v-col cols="12" md="3" sm="4">
                                <SelectSearch label="Lote" :disabled="data_encounter.medication_id == ''" :outlined="true" element="element"  model="stock" :parameter="`[medical_supply_id:'${data_encounter.medication_id}']`"  validador="none"></SelectSearch>
                            </v-col>
                            <v-col cols="12" md="9" sm="8" class="pt-0 pb-0 negative-margin-25 pb-0">
                                    <p class="mb-4 mt-4">Dosis</p>  
                                    <div v-if="data_encounter.medication_id == null ">
                                        <v-alert dense text color="blue-grey">
                                            Seleccione primero un laboratorio
                                        </v-alert>
                                    </div>                 
                                    <div v-for="vaccine in vaccines[vaccine_type]"  :key="vaccine.id">
                                        <div v-if="vaccine._id == data_encounter.medication_id">
                                                <v-row>
                                                <v-col cols="12" md="6" class="pt-0 pb-0"  v-for="dosis in vaccine.scheme"  :key="dosis.id">
                                                    <v-btn dark block small class="mb-2" @click="data_encounter.dose = dosis.nombre" :color="dosis.nombre == data_encounter.dose ? 'deep-purple lighten-1' : 'blue-grey lighten-2'">
                                                        <v-card class="text-caption wrap-text" color="transparent" elevation="0">
                                                            {{dosis.nombre}}
                                                        </v-card>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <v-col cols="12" v-if="data_encounter.dose == null && data_encounter.medication_id != null" class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div class="v-messages__message ml-3">¡El campo es requerido!</div>
                                        </div>
                                    </v-col>   
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6" md="3" sm="4" class="pt-0">
                                <SelectSearch label="Dispositivo" :outlined="true" element="element"  model="stock" v-model="device_type" validador="none" :valuex="data_encounter.dispositivo"></SelectSearch>
                            </v-col>
                            <v-col cols="6" md="3" sm="4" class="pt-0">
                                <SelectSearch label="Lote dispositivo" :outlined="true" element="element"  model="stock" validador="none" ></SelectSearch>
                            </v-col>
                            <v-col cols="6" md="3" sm="4" class="pt-0">
                                <BaseSelect element="element" :outlined="true" type="text" model="encounter" list="Handling"  v-model="data_encounter.management" label="Manejo"></BaseSelect>
                            </v-col>
                            <v-col cols="6" md="3" sm="4" class="pt-0">
                                <BaseSelect element="element" :outlined="true" type="text" model="encounter" list="Vaccination_card"  v-model="data_encounter.vaccination_card" label="¿Entrega carnet?"></BaseSelect>
                            </v-col>
                            <v-col cols="12" md="12" sm="8" class="pt-0">
                                <BaseTextArea type="text" rows="1" label="observaciones" :outlined="true" v-model="data_encounter.observations" ></BaseTextarea>
                            </v-col>
                        </v-row>    
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-card class="mx-auto pt-2 ">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <p class="text-overline blue-grey--text">Signos vitales y Sintomas</p>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="fc"  :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="fr"  :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="tasistolica" :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="tadiastolica"  :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="temp" :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                            <v-col cols="4" md="2" sm="2" class="pt-0">
                                <Element type="spo2"  :encounter="encounter" :save="update" validator="numericVital" sigla></Element>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <!-- <v-btn @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Guardar <v-icon right dark> mdi-check</v-icon>
                </v-btn> -->
            </v-col>
        </v-row> 
    </div>
</template>
<script>
import Element from '@/components/forms/FormElement.vue'
import TitleForm from '@/components/views/TitleForm.vue'
// import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseTextArea from '@/components/forms_items/BaseTextArea.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
// import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
import SelectSearch from '@/components/forms_items/SelectSearch.vue'
import axios from 'axios'

export default {
    data() {
        return {            

            vaccine_type:"",
            device_type:"",
            encounter_type:"encounters",
            patient_id:"",
            data_encounter:{},
        }
    },
    props: {
        encounter: {
            type: String
        },
        update:{
            type: Boolean,
            default: false
        },
        save_data:{
            type: Boolean,
            default: false
        }

    },
    mounted() {      
        this.get_vaccines(),
        this.getvacc()
        this.saveAction()
    },
    methods: {  
        get_vaccines: function () {
            this.$store.dispatch("putVaccines")
            console.log("carga....", this.vaccines);
        },
        getvacc:function(){
            console.log("entro!");
            axios.get(this.$URL + "encounters/" + this.$props.encounter + ".json").then(res => {
                console.log("trae aa111111a", res.data );
                this.data_encounter = res.data.encounter
                this.encounter_type = res.data.type
                this.patient_id = res.data.patient.id
                this.$props.save_data = false   
            })
        },
        saveAction: function (){

            console.log("envio----------", this.data_encounter);
            console.log("validador", this.data_encounter.medication_id, this.data_encounter.dose);
                if (this.data_encounter.medication_id != null && this.data_encounter.dose != null) {
                    axios.patch(this.$URL + `${this.encounter_type}/${this.data_encounter.id}.json`, this.data_encounter).then(res => {
                        console.log("respuesta" , res.data);
                        this.getvacc()
                        this.$router.push({ name: 'patient', params: { id: this.patient_id, reload: true } }).catch(() => { })
                    })
                    
                } else {
                    this.$props.save_data = false
                }

            

        },    
    },
    computed: {
        vaccines: function(){
            return this.$store.state.vaccines
        },
        saved_vaccine: function () {
            let saved = this.$props.save_data
            if (saved == true) {
                this.saveAction()
            }
            return saved
        }

    },
    components: {
        Element,
        TitleForm,
        // BaseInput,
        BaseSelect,
        // BaseCheckbox,
        SelectSearch,
        BaseTextArea

    }
}
</script>
<style>
    .ghost{
        position: absolute;
        right: 0px;
        writing-mode: vertical-rl;
        text-orientation: upright;
        font-size: 1pt;
        color: transparent;
    }
</style>
