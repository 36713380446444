<template>
    <div class="inputglya">
        <v-autocomplete 
        :placeholder="placeholder"
        :class="filter || element"
        :items="datasearch"  
        :deletable-chips="multiple" 
        :multiple="multiple" 
        :small-chips="multiple" 
        :label="label_show" 
        :disabled="disabled"
        :solo="solo" 
        :outlined="outlined" 
        :filled="filled" 
        :search-input.sync="searchinput" 
        @update:search-input="search"
        :value="valuex" 
        item-value="id"  
        @input="updateValue" 
        v-model="selection" 
        :error-messages="nameErrors"
        @focus="campoSeleccionado = true"
        @blur="campoSeleccionado = false"
        :prepend-icon="icon"></v-autocomplete>
        <span class="ghost">{{length_data}}</span>
    </div>

</template>

<script>

    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import AxiosServices from '@/services/AxiosServices'

    export default {
    name: "SelectSearch",

    props:{
        // styles
        label:{
            type:String,
            default:'select vacio'
        },
        placeholder:{
            type:String,
            default:'Escribe para buscar más opciones...'
        },
        multiple:{
            type:Boolean,
            default:false
        },
        solo:{
            type:Boolean,
            default:false
        },
        outlined:{     
            default:false
        },
        filled:{
            type:Boolean,
            default:false
        },
        filter:{},
        element:{},
        icon:{},
        disabled:{
            default:false
        },

        // end 

        // functioning
        model:{
            type:String,  // is the type for the get in backend
            default:''
        },
        country:null,  // is the country id for some type gets
        
        valuex:{
            default:{}  // is the value default
        },
        validador:{   // is the validadtion type
            default:'none'
        },
        prefixes:{   // is the another type in get for to do something
            default:false
        },
        reloader:{  // is for reload component since
            default:false
        }

        // end       
    },
    model: {
        prop: "value",
        event: "input"
    },
    data() {
        return {
            datasearch: [this.valuex], // is the array results, the default is the valuex
            selection:this.valuex.id,  // is the id selected of array, the default is the id from valuex
            searchinput:this.valuex.text, // is the text selected of array, the default is the text from valuex
            fromvuex: this.$store.state.validations2[this.$props.validador],  // load the valdiations, do not touch
            minl:5, // used for the validations, do not touch
            id: this.$props.label,  // id in the valuex validation for to identify the field in the form
            showlabel: false, // to show label
            campoSeleccionado: false,
        }
    },
    mounted() {
        this.eval_errors();
    },
    watch:{
        selection(newVal){
            // eval when change selection
            if (newVal != null ){
                this.datasearch.forEach((element,index) => {
                    if (element.id == newVal) {
                        if (this.$props.prefixes){
                            this.change_input(newVal,index)
                        }
                        this.$emit('label_search', element.text)
                    }
                })
            }
        },
        valuex(newVal,oldValue){
            // eval when change valuex and reload componente
            if (typeof(newVal) == typeof(oldValue)) {
                if (newVal['id'] != oldValue['id']) {
                    this.reload_component(newVal)
                }
            } else {
                this.reload_component(newVal)
            }
        },
        reloader(newVal){
            if (newVal){
                this.selection = undefined
                this.searchinput = null
            }
        },
        campoSeleccionado(newVal) {
        if (newVal == false && this.multiple == true) {
            this.searchinput = ""
        }
    }
},
    methods: {
        async change_input(value,index){
            this.datasearch[index].text = value
            await this.$nextTick();
        },
        reload_component(newVal){
            if (newVal == this.valuex) {
                this.selection = this.valuex.id
                this.searchinput = this.valuex.text
                this.eval_errors()
            }
        },
        updateValue(value) {
            this.$emit('input', value);
            this.eval_errors()
        },
        seakerSearchIputInDatasearch(search) {
            // search the input in the response array, and if self found, ignore the next get
            let response = true
            this.datasearch.forEach((element) => {
                if (element.text == search) {
                    response = false
                }
            })
            return response
        },
        getToBackend(params){
            if (params != undefined || params != null) { // limit get without params
                // get to backend with params
                AxiosServices.listingsSearch(params).then(res => {
                    this.datasearch = this.setList(res.data.data.data)
                })
            }
        },
        search: function () {
            let params ={'page':1, 'per_page':20}  // set pagination default
            // let search_input = encodeURIComponent(this.searchinput);  // format spaces
            if (this.searchinput == null) {   // if the searchinput is null, to get without searchinput, only type or model
                params['type'] = this.model
                if (this.country != null || this.country != undefined || this.country != ''){ // if country is present, add country to params to get
                    params.country_id = this.country  
                }
                this.getToBackend(params)
            } else {  
                let ignore = this.seakerSearchIputInDatasearch(this.searchinput) // boolean to ignore the next get
                if (ignore && this.searchinput != '') { // limit get withot ''
                    params['type'] = this.model,
                    params['q'] = this.searchinput
                    if (this.country != null || this.country != undefined || this.country != ''){ // if country is present, add country to params to ge
                        params.country_id = this.country  
                    }
                    this.getToBackend(params)
                } 
            }
        },
        setList: function (data) {
            // format list
            const datasearch = []
            if(data.length>0){
                data.forEach(function(object){
                    let temp = {}
                    temp.id = object.id
                    temp.text = object.name
                    datasearch.push(temp)               
                });
            }
            return datasearch            
        },
        set_label: function () {
            this.showlabel = true
            return this.showlabel
        },
        eval_errors: function () {
            // eval error of validations
            if (this.nameErrors.length == 0) {
                this.$store.dispatch("puterror", { [this.id]: 0 })
            } else {
                this.$store.dispatch("puterror", { [this.id]: 1 })
            }
        }
    },
    computed:{
        eval_required_show: function () {
            return this.$store.state.show_required            
        },
        length_data: function () {
            let activator = false
            if (this.datasearch.length == 0 && this.searchinput != null &&  this.searchinput != "") {
                this.$emit('count_nil');
                this.$emit('label_search', this.searchinput)
                activator = false
            }else{
                this.$emit('count_data');
                activator = true
            }
            return activator
        },
        label_show: function () {
            let label = this.$props.label
            if (this.showlabel) {
                label = `${this.$props.label}${'*'}`
            }
            return label
        },
        nameErrors() {
            let errors = []

            if (!this.$v.selection.required && this.fromvuex['required'] !== undefined) {
                if (this.eval_required_show) {
                    errors.push('¡El campo es requerido!')
                } else {
                    errors.push(' ')
                }
                this.set_label()
            }
            return errors
        }
    },
    mixins: [validationMixin],
    validations: function () {
        if (this.minl > 0) {
            return {
                selection: eval(this.fromvuex)
            }
        }
        return {
            x: {
                required
            }
        }
    }


}

</script>

<style>

</style>