<template>
    <v-dialog transition="dialog-bottom-transition" max-width="800" v-model="dialog" persistent >
        <template v-slot:activator="{ on, attrs }">
            <!-- <a v-show="showbutton" elevation='0' class="showbutton links_name router-link-active pl-0" v-bind="attrs" v-on="on" @click="getpatient()"><v-icon>{{icon}}</v-icon><span>{{label}}</span></a> -->
            <a v-show="showbutton" elevation='0' v-bind="attrs" v-on="on" @click="getpatient()">
                <i class="bx" :class="icon" />
                <span class="links_name">{{ label }}</span>
            </a>
            <div v-show="showicon">
                <v-btn v-if="!permissions.edit" :disabled="!permissions.edit" icon :color="color" class="edit-carer">
                    <v-icon size="30px">{{icon}}</v-icon>
                </v-btn>
                <v-btn v-else icon :color="color" class="edit-carer" v-bind="attrs" v-on="on" @click="getpatient()">
                    <v-icon size="30px">{{icon}}</v-icon>
                </v-btn>
            </div>
        </template>
        <template v-slot:default>
            <v-card >
                <v-toolbar color="indigo lighten-1" dark height="50">
                    <p class="mb-1 text-h6">{{modal_name}}</p>
                </v-toolbar>
                <v-card-text>
                    <div class="text-h2 mt-4">
                        <v-row justify="center">
                            <v-col cols="12 mt-3" class="carer-modal">
                                <div>
                                    <v-row>
                                        <v-col cols="12" :sm="createsForm ? '2' : '12'" class="pt-0 pb-0" v-if = "!show" >
                                            <SelectSearch label="Pais Residencia" model="countries2" v-model="patient.country_id" validador="required" @label_search="get_searching"
                                                :valuex="patient.country_id == null ? {id: country_id_default,text:country_name_default} : {id:patient.country_id,text:patient.country_name} "></SelectSearch>
                                        </v-col>
                                        <v-col cols="3" :sm="createsForm ? '2' : '3'" class="pt-0 pb-0">
                                            <BaseSelect v-model="patient.id_type" type="text" list="id_types" :country="patient.country_id == null ? country_id_default : patient.country_id" validador="required" label="Tipo ID" @update_validations="nid_validation_reloaded=nid_validation_reloaded + 1"></BaseSelect>
                                        </v-col>
                                        <v-col cols="9" :sm="createsForm ? '5' : '9'" class="pt-0 pb-0">
                                            <BaseInput :disabled="!createsForm" v-model="patient.nid" type="text" label="Número de identificación" :outlined="true" @validation-updated="handleValidation" validador="nid" :idtype="patient.id_type" inputsize="inputstiliced" :reload_validation="to_reload_validation"></BaseInput>
                                        </v-col>
                                        <v-col v-show="createsForm" class="pa-0">
                                            <v-btn v-if="documentoInvalid==true || patient.nid == null" min-height="45" block class=" white--text" depressed color="blue darken-4" small disabled> Validar paciente </v-btn>
                                            <v-btn v-else min-height="45" @click="validatePatient" block class=" white--text" depressed color="blue darken-4" small> Validar paciente </v-btn>
                                        </v-col>
                                        <!-- <v-dialog transition="dialog-bottom-transition" max-width="500" v-model="dialog_adres" > -->
                                            <!-- <template v-slot:activator="{ on, attrs }">
                                                <v-col v-show="createsForm" class="pa-0">
                                                    <v-btn min-height="45" @click="validatePatient" v-bind="attrs" v-on="on" block class=" white--text" depressed color="blue darken-4" small > Validar paciente </v-btn>
                                                </v-col>                                                                                                                             
                                            </template> -->
                                            
                                            
                                            <!-- <template v-slot:default>
                                                <v-card >
                                                    <v-toolbar color="light-blue darken-4" dark height="50">
                                                        <p class="mb-1 text-h6">Información encontrada</p>
                                                    </v-toolbar>
                                                    <v-card-text v-show="spinneradres">
                                                        <div>
                                                            <h4><i><span class="as fa-sync-alt fa-spin">Buscando en ADRES...</span></i></h4>                                                   
                                                        </div>
                                                    </v-card-text>
                                                    <v-card-text v-if="modal_adres" class="mt-5">
                                                        <table class="basic_table color-text-glya">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Estado</th>
                                                                    <td>{{response.estadoAfiliacion}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Tipo de Afiliado</th>
                                                                    <td>{{response.tipoAfiliado}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Ciudad de Afiliación</th>
                                                                    <td>{{response.nomMunicipio}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Nombres</th>
                                                                    <td>{{response.primerNombre}} {{response.segundoNombre}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Apellidos</th>
                                                                    <td><p> {{response.primerApellido}} {{response.segundoApellido}} </p></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>EPS</th>
                                                                    <td>{{response.nombreEps}}{{response.nombreEpsAux}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Régimen</th>
                                                                    <td>{{response.nomTipoRegimenSalud}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>                                                    
                                                    <v-card-text  v-if="error_adres==404" class="mt-5">
                                                        <table class="basic_table color-text-glya" >
                                                            <tbody>
                                                                <tr>
                                                                    <th>Estado</th>
                                                                    <td><span class="red--text text--lighten-2">No se ha encontrado información. </span><br> Verifique el número y tipo de documento, o si este pertenece a algún regimen especial.</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                    <v-card-text  v-if="error_adres!=404&&error_adres!=''" class="mt-5">
                                                        <table class="basic_table color-text-glya" >
                                                            <tbody>
                                                                <tr>
                                                                    <th>Estado</th>
                                                                    <td><span class="red--text text--lighten-2"><b>Error Desconocido,</b> Adres no permite conexión</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end mt-3">
                                                        <v-btn @click="dialog_adres=false,show=true, showbuttons=true, response={},error_adres=''" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                                        <v-btn v-if="modal_adres" @click="acceptInfo" class="pl-5 pr-5 white--text" depressed color="blue lighten-1" small> Aceptar Información <v-icon right dark > mdi-check</v-icon> </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template> -->
                                        <!-- </v-dialog> -->
                                        <v-col v-if="spinnerglya" cols="12" class="d-flex align-center flex-column message-no-data">
                                            <v-icon size="100" class="icon-load">mdi-loading</v-icon>
                                            <span class="text-h4">Buscando en el sistema...</span>
                                        </v-col> 
                                        <v-col cols="12" v-if = "show">
                                            <v-row>
                                                <v-col cols="12" sm="6" class="pt-0 pb-0" >
                                                    <BaseInput v-model="patient.first_name" type="text" validador="namesSurnamesRequired" label="Nombres"
                                                        transform="text-uppercase"></BaseInput>
                                                </v-col>                                                
                                                <v-col cols="12" sm="6" class="pt-0 pb-0" >
                                                    <BaseInput v-model="patient.last_name" type="text" label="Apellidos" validador="namesSurnamesRequired"
                                                        transform="text-uppercase"></BaseInput>
                                                </v-col>
                                                <v-col cols="12" sm="3" class="pt-0 pb-0" >
                                                    <SelectSearch label="Pais Residencia" model="countries2" v-model="patient.country_id" validador="required"
                                                        :valuex="patient.country_id == null ? {id:country_id_default,text:country_name_default} : {id:patient.country_id,text:patient.country_name}" @label_search="get_searching"></SelectSearch>
                                                </v-col>
                                                <v-col cols="12" sm="3" class="pt-0 pb-0" >
                                                    <SelectSearch label="Ciudad Residencia" model="cities" :country="patient.country_id == null ? country_id_default : patient.country_id" v-model="patient.city_id" validador="required"
                                                        :valuex="city_valuex"></SelectSearch>
                                                </v-col>
                                                <v-col cols="12" sm="6" class="pt-0 pb-0" >
                                                    <BaseDate limitType="birthdate" v-model="patient.birth_date" label="Fecha de nacimiento" validador="required" :valueinit="patient.birth_date"></BaseDate>
                                                </v-col>
                                                <v-col cols="12" md="6" class="pt-0 pb-0" >
                                                    <RadioButtonGroup v-model="patient.gender" validador="required" :list="genderlist"></RadioButtonGroup>
                                                </v-col>
                                                <v-col cols="6" sm="4" class="pt-0 pb-0" >
                                                    <BaseSelect v-model="patient.blood_type" type="text" list="blood_types" label="Grupo Saguineo"
                                                        validador="required"></BaseSelect>
                                                </v-col>
                                                <v-col cols="6" sm="2" class="pt-0 pb-0" >
                                                    <BaseSelect v-model="patient.rh_factor" type="text" list="rh_factors" label="RH" validador="required"
                                                        :valuex="patient.rh_factor"></BaseSelect>
                                                </v-col>
                                                    <v-col cols="12" md="8" class="pt-0 pb-0" v-if="country_label == 'COLOMBIA'">
                                                        <SelectSearch label="EAPB" model="eapbs" v-model="patient.eapb_id" 
                                                        :valuex="patient.eapb_id == null ? 1 : {id:patient.eapb_id,text:patient.eapb_name}"></SelectSearch>
                                                    </v-col>
                                                    <v-col cols="12" md="4" class="pt-0 pb-0" v-if="country_label == 'COLOMBIA'">
                                                        <BaseSelect v-model="patient.regime" type="text" list="regimes" label="Régimen"></BaseSelect>
                                                    </v-col>
                                                
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end mt-3">
                    <v-btn @click="close" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                    <div  v-if="(showbuttons || !createsForm)">
                        <div  v-if="verror" class="ml-3">
                            <v-btn class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                        </div>
                        <div  v-else class="ml-3">
                            <v-btn @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

</template>

<script>
    import BaseInput from '@/components/forms_items/BaseInput.vue'
    import BaseSelect from '@/components/forms_items/BaseSelect.vue'
    import SelectSearch from '@/components/forms_items/SelectSearch.vue';
    import BaseDate from '@/components/forms_items/BaseDate.vue'
    import RadioButtonGroup from '@/components/forms_items/RadioButtonGroup.vue'
    import axios from 'axios'
    import AxiosServices from '@/services/AxiosServices'
    export default {
        name: 'FormBasicData',
        mounted(){
            this.getpatient()
            this.$store.dispatch("reseterror")  
            this.label_eapb()
        },
        data:function() {
            return {
                patient:{id_type:1, country_name:localStorage.getItem('user_country_name'), eapb_name:'',country_id:parseInt(localStorage.getItem('user_country_id'))},
                patientValidate:{},
                permissions:{},
                dialog:false,
                dialog_adres:false,
                show:false,
                name:"",
                modal_adres:false,
                response:{},
                info_eps:false,
                error_adres:"",
                caregiver_exists:false,
                especial_eps:false,
                showbuttons:false,
                spinneradres:false,
                spinnerglya:false,
                label_search:'',
                documentoInvalid: false,
                country_label:'COLOMBIA',
                nid_validation_reloaded:0,
                reload_country_name:false
            }
        },
        props: {
            patient_id:{},
            modal_name:{
                type: String,
            },
            label: {
                type: String
            },
            createsForm: {
                type:Boolean,
                default: false
            },
            showbutton:Boolean,
            showicon: {
                type:Boolean,
                default: true
            },
            icon: {
                type: String,
                default: "mdi-pencil-circle"
            },
            color: {
                type: String,
                default: "white"
            },
            value: {
                type: Object,
                default: function(){
                    return {}
                }
            },
            model_prop: {
                type:String,
                default: "patients"
            },
            adres:{
                type: Boolean,
                default:false
            },
            caregiver_id:{
                type: String
            },
            nid:{
                type:String,
                default:""
            }
        },
        watch: {
            'patient.country_id'(newVal){
                this.get_searching(newVal)
            }
        },
        methods: {
            get_searching: function (label) {
                this.country_label = label
                if (typeof(label) != 'number'){
                    this.patient.country_name = label
                    this.reload_country_name = true 
                }
                this.patient.city_id=null
            },
            handleValidation(isInvalid) {
                this.documentoInvalid = isInvalid;
                },
            changeUppercase: function (objeto) {  
                for (const dato in objeto) {
                    if (typeof objeto[dato] === 'string') {
                    objeto[dato] = objeto[dato].toUpperCase();
                    }
                }
            },
            updatePatient:function(patient_id,patient){
                this.changeUppercase(patient)   
                AxiosServices.patientUpdate(patient_id,patient).then(res=>{
                    this.$router.push({ name: 'patient', params: { id: patient_id, reload: true } }).catch(() => { })
                    this.$store.dispatch("addNotification", res.data)
                    this.dialog = false
                    this.$emit('updatePatient');
                    this.getpatient()
                    this.resetPatient()
                }).catch(error =>{
                    this.$store.dispatch("addNotification", error.response.data)
                    this.dialog = false
                })
            },
            createPatient:function(patient){ 
                this.changeUppercase(patient)    
                AxiosServices.patientsCreate(patient).then(res => {
                    this.$store.dispatch("addNotification", res.data)
                    this.$router.push({ name: 'patient', params: { id: res.data.data.id, reload: true } }).catch(() => { })                    
                    this.dialog = false
                    this.resetPatient()
                    if (this.$route.name == 'patient'){
                        window.location.reload()
                    }
                }).catch(error => {
                    this.$store.dispatch("addNotification", error.response.data)
                    this.dialog = false
                })
                this.resetPatient()
            },
            saveAction: function(){
                this.$store.dispatch("show_required")
                if (!this.verror) {                    
                    if (this.createsForm) {
                        this.createPatient(this.patient)                        
                    }else {
                        let patient = {}
                        patient['id_type'] = this.patient.id_type
                        patient['nid'] = this.patient.nid
                        patient['first_name'] = this.patient.first_name
                        patient['last_name'] = this.patient.last_name
                        patient['country_id'] = this.patient.country_id
                        patient['city_id'] = this.patient.city_id
                        patient['birth_date'] = this.patient.birth_date
                        patient['blood_type'] = this.patient.blood_type
                        patient['rh_factor'] = this.patient.rh_factor
                        patient['eapb_id'] = this.patient.eapb_id
                        patient['regime'] = this.patient.regime
                        patient['gender'] = this.patient.gender
                        this.updatePatient(this.patient.id,patient)
                    }                    
                } 
                else {
                    this.$store.dispatch("show_required")
                }
                this.close()
            },
            putCaregiver:function(caregiver_id){
                axios.get(this.$URL + `api/v1/patients/patient_id=${this.$route.params.id}/set_caregiver?caregiver_id=${caregiver_id}&relationship=prueba&patient_id=${this.$route.params.id}`,this.$store.getters.header).then(res => {
                    this.$store.dispatch("getpatient", this.$route.params.id)
                    this.$router.push({ name: 'patient', params: { id: this.$route.params.id, reload: true } }).catch(() => { })
                    console.log(res.data);
                })
                // axios.post(this.$var regular = this.$store.state.idTypeListRegex[this.patient.id_type]tch("getpatient", this.$route.params.id)
                // this.$emit('updatePatient');
            },
            resetPatient:function(){
                this.patient = { id_type: 1, nid: null, first_name: null, last_name: null, country_id: parseInt(localStorage.getItem('user_country_id')), country_name:localStorage.getItem('user_country_name'), birth_date:new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().split('T')[0], blood_type: null, rh_factor: null, eapb_id: null, regime: null, gender: null, eapb_name:'' }
            },
            getpatient:function(){
                this.$store.dispatch("reseterror")
                // this.patient = { id_type: 'CC', nid: '', names: '', surnames: '', nationality: { id: 'CO', text: 'Colombia' }, eapb: { id: '110011', text: "FONDO FINANCIERO DISTRITAL DE LA SALUD FFDS" }, current_residence: { id: '11001', text: 'BOGOTÁ D.C' }, occupation: { id: '9999', text: "No aplica" } }
                if (!this.createsForm) {
                    this.show = true
                    AxiosServices.patientDetail(this.patient_id).then(res=>{
                        this.patient=res.data.data
                        this.permissions=res.data.data.permissions
                    }).catch((error) => {
                        if (error.code !='ECONNABORTED') {
                            this.$store.dispatch("addNotification", error.response.data);
                            this.$store.dispatch("render404");          
                        }                       
                    })
                } else{
                    this.resetPatient()                    
                }
                // var regular = this.$store.state.idTypeListRegex[this.patient.id_type]
                // this.$store.dispatch("putValidationNid", regular)
            },
            validatePatient:function(){
                this.spinnerglya=true
                AxiosServices.patientValidate(this.patient.id_type,this.patient.nid).then(res => {
                    this.$store.dispatch("addNotification", res.data)
                    this.$router.push({ name: 'patient', params: { id: res.data.data.id, reload: true } }).catch(() => { })
                    this.close()
                }).catch(error => {
                    console.log(error);
                    this.show=true
                    this.showbuttons=true
                })
                this.spinnerglya=false
            },
            acceptInfo:function(){
                this.dialog_adres = false;
                this.patient.names = this.response.primerNombre + " " + this.response.segundoNombre;
                this.patient.surnames = this.response.primerApellido + " " + this.response.segundoApellido;
                this.patient.eapb.text= this.response.nombreEps;
                this.patient.regime = this.response.nomTipoRegimenSalud.toLowerCase();
                if (!this.especial_eps){
                    this.info_eps = true;
                }
                this.show = true;
                this.showbuttons = true
            },
            close: function(){
                this.$store.dispatch("reseterror")
                this.response = {}
                this.dialog = false
                this.show = false
                this.info_eps = false
                this.caregiver_exists = false
                // this.patient={id_type:'CC',nid:'',nationality:{id:'CO',text:'Colombia'},eapb: { id: '110011', text: "FONDO FINANCIERO DISTRITAL DE LA SALUD FFDS" },current_residence: { id: '11001', text: 'BOGOTÁ D.C' }, occupation: { id: '9999', text: "No aplica" }}
            },
            evalFields: function(){
                for (const field in this.$store.state.fields_select){
                    if (typeof this.patient[field] == "object"){
                        this.patient[field]=this.patient[field].id!=null ? this.patient[field].id : this.$store.state.fields_select[field].id
                    }
                }
            },
            label_eapb:function(){
                return this.country_label = this.patient.country_name          
            },
        },
        computed:{
            genderlist:function(){
                return this.$store.state.genderList
            },
            city_valuex: function(){
                return this.patient.city_id == null ? {id:null,text:''} : {id:this.patient.city_id,text:this.patient.city_name}
            },
            verror:function(){
                var show=false
                if (this.$store.state.form.reserror){
                    show=true
                }
                return show
            },
            id_empty:function(){
                if (this.patient.nid){
                    return false
                }
                else{
                    return true
                }
            },
            country_id_default:function(){
                return  parseInt(localStorage.getItem('user_country_id'))
            },
            country_name_default:function(){
                return  this.reload_country_name ? this.country_label : localStorage.getItem('user_country_name')
            },            
            to_reload_validation:function () {
                return this.nid_validation_reloaded
            }

        },
        components: {
        BaseInput,
        BaseSelect,
        SelectSearch,
        BaseDate,
        RadioButtonGroup
        },
    }
</script>

<style>
        .showbutton {
        justify-content: left;
        background:transparent !important;
        color: white !important;
        }
        /* .showbutton::active {
        background-color: aqua !important;
        } */
        .modal-fade-in{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: #FFF;
            padding: 20px;
            border-radius: 15px;
            box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
            z-index: 101;
        }
        .basic_table {
            border-collapse: collapse;
            font-family: Tahoma, Geneva, sans-serif;
            width: 100%;
        }
        .basic_table tbody td, .basic_table tbody th {
            color: #636363;
            border: 1px solid #dddfe1;
            padding: 5px;
        }
        .basic_table tbody tr {
            background-color: #f9fafb;
        }
        .basic_table tbody tr:nth-child(odd) {
            background-color: #ffffff;
        }
        .icon-load{
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }
        @media (min-width: 960px) {
        .carerDialog{
            width: 600px;
        }
        }
</style>