<template>
    <div>
        <BasicData :patient_id="$route.params.patient_id" :editform="false"></BasicData>
        <div class="mt-3 mb-5">
            <v-row>
                <v-col cols="12" md="8" lg="6">
                    <v-card>
                        <v-card-text class="pt-1">
                            <v-col class="pt-0">
                                <v-row class="mt-0">
                                    <v-col class="color-text-glya pa-0" cols="12">
                                        <h4 :class="colorPriority(alert.alarm.priority)+'--text'">{{ alert.alarm.name_only }}</h4>
                                    </v-col>
                                    <v-col class="color-text-glya pa-0 separator-line" cols="12" sm="8">                                
                                        <table class="table-info-alert">
                                            <tr>
                                                <th><v-icon small :color="colorPriority(alert.alarm.priority)">{{iconalerta(alert.measurement.clinical_element)}}</v-icon></th>
                                                <th class="text-body-2"><span class="text-caption">{{alert.measurement.clinical_element_name}}</span></th>
                                            </tr>
                                            <tr>
                                                <th><v-icon small>mdi-calendar</v-icon></th>
                                                <th class="text-body-2"><span class="text-caption">{{ alert.measurement.when | formatDateSimple(alert.time_zone_patient) }}</span></th>
                                            </tr>
                                            <tr>
                                                <th><v-icon small>mdi-doctor</v-icon></th>
                                                <th class="text-body-2"><span class="text-caption">{{alert.user_id !== null ? alert.user_name : 'Sin responsable'}}</span></th>   
                                            </tr>
                                        </table>                      
                                    </v-col>
                                    <v-col class="d-flex flex-column justify-center align-center val-alert pa-0">
                                        <h3>{{ alert.measurement.value }}<span class="element-clnc pa-0 ma-0 black--text">{{ alert.measurement.clinical_element_unit }}</span></h3>
                                        <p><v-chip :color="colorPriority(alert.alarm.priority)" small outlined >{{alert.alarm.minimum_condition  }} - {{ alert.alarm.maximum_condition }}</v-chip></p>
                                    </v-col>  
                                </v-row>
                            </v-col>
                        </v-card-text>
                        <v-col class="pa-0 text-body-2 text-center time_alert" :class="colorPriority(alert.alarm.priority)">
                            <div class="show_id">ID:{{alert.id}}</div>
                            <span class="white--text"><v-icon color="white" small>mdi-clock</v-icon> <b>Abierta: {{ getTimer(alert.measurement.when) }}</b></span>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" lg="6">
                    <v-card class="mx-auto">
                        <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                            <span class="text-body-1">
                                Información de contácto
                            </span>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="text-body-2 pt-3">
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-home</v-icon>
                                <span v-if="patient.address == '' || patient.address == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.address}} 
                                    <span>{{ patient.neighborhood }} </span>                                
                                </span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-city</v-icon>
                                <span v-if="patient.city_name == '' || patient.city_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.city_name}}</span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-cellphone</v-icon>
                                <span v-if="patient.cellphone == '' || patient.cellphone == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>                            
                                <span v-else>{{patient.prefix}} {{ patient.cellphone }}</span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-email</v-icon>
                                <span v-if="patient.email == '' || patient.email == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.email}}</span>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header color="indigo"><h3 class=" white--text">Gestionar alerta</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!-- <v-col class="pb-0">
                                    <v-alert text color="info" dense >
                                        <v-row class="d-flex justify-space-between pa-3">
                                            <p class="mb-0"><b> <v-icon color="info">mdi-account-circle</v-icon> Responsable:</b> Pepito Perez Pinilla</p>
                                            <v-btn small class="pl-9 pr-9" color="blue darken-2" dark>
                                                Editar responsable
                                            </v-btn>
                                        </v-row>
                                    </v-alert>
                                </v-col> -->
                                <v-row class="mt-2 pa-3">
                                    <v-col cols="12" md="6">           
                                        <v-card elevation="1" class="pa-3">
                                            <h3 class="pb-3">Historial de gestión</h3>
                                            <v-card color="blue-grey lighten-5" class="pa-3 scrollable2 mb-2" elevation="0">
                                                <div v-if="alert.entries.length >= 1">
                                                    <v-card-text v-for="entry_alert in alert.entries" :key="entry_alert.id"  class="white pa-2 mb-2">
                                                        <v-row class="d-flex justify-space-between">
                                                            <v-col cols="12" class="pb-0">
                                                                <p class="mb-0">
                                                                    <img src="../../assets/Glady-color.svg" alt="Glady" width="25" class="glady-message" v-if="entry_alert.user_name=='Glya'">
                                                                    <span class="text-subtitle-2 indigo--text text--lighten-2 pr-1 capitalize">{{nameCapitalize(entry_alert.user_name)}}:</span>{{entry_alert.description}}</p>
                                                            </v-col>
                                                            <v-col cols="12" class="pt-0">
                                                                <p class="mb-0 text-caption text-right indigo--text">{{entry_alert.created_at | formatDate()}}</p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-col class="text-center" v-if="alert.status == 2">
                                                        <v-icon size="50px" color="grey">mdi-check-decagram</v-icon>
                                                        <h3 class="grey--text">Alerta cerrada por <b>{{alert.entries[alert.entries.length - 1].user_name}}</b></h3>
                                                    </v-col>   
                                                </div>
                                                <v-card-text v-else>
                                                    <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                                        <v-icon size="40">mdi-bell</v-icon>
                                                        <h3>No existen entradas</h3>
                                                    </v-col>
                                                </v-card-text>                             
                                            </v-card> 
                                            <v-card-actions>
                                                <v-col class="pa-1 new-entry">
                                                    <v-row cols="6" md="6" class="relative">
                                                        <v-textarea class="text-area-entry" v-model="entry.description" type="text" :placeholder="placeholderDescription" outlined="outlined" rows="1" auto-grow >    
                                                        </v-textarea>
                                                        <v-btn disabled v-if="disabledButton || alert.status == 2" class="pl-1 pr-3 white--text btn-send-entry" depressed color="indigo lighten-1" small><v-icon right dark > mdi-send</v-icon></v-btn>
                                                        <v-btn v-else @click="createEntry(false)" class="pl-1 pr-3 white--text btn-send-entry" depressed color="indigo lighten-1" small><v-icon right dark > mdi-send</v-icon></v-btn>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" class="pa-0">
                                                            <!-- <v-btn :disabled="disabledButton" @click="createEntry(false)" class="pl-5 pr-5 white--text" block depressed color="indigo lighten-1" small> Guardar Entrada <v-icon right dark > mdi-check</v-icon></v-btn> -->
                                                        </v-col>                                                           
                                                        <v-col>
                                                            <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-row class="d-flex justify-space-between pa-0 pt-3">
                                                                        <v-btn  v-bind="attrs" v-on="on" @click="liberate()" :disabled="$store.state.user.id != alert.user_id || alert.status == 2"  class="pl-5 pr-5 mb-1 white--text" depressed color="red accent-2" small > Liberar alerta <v-icon right dark > mdi-account-off</v-icon></v-btn>
                                                                        <v-btn   v-bind="attrs" v-on="on"  @click="finalize()" :disabled="$store.state.user.id != alert.user_id || alert.status == 2" class="pl-5 pr-5 mb-1 white--text" depressed color="green lighten-1" small > Cerrar Alerta <v-icon right dark > mdi-check-bold</v-icon></v-btn>
                                                                    </v-row>
                                                                </template>
                                                                <template v-slot:default>
                                                                    <v-card>
                                                                        <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{action=='liberate' ? 'Liberar alerta' : 'Cerrar alerta'}}</v-toolbar>
                                                                        <v-card-text>                    
                                                                            <v-row >
                                                                                <v-col cols="12" class="mt-5">                          
                                                                                    <v-row>
                                                                                        <v-col cols="12" class="pb-0">
                                                                                            <p class="mb-0">Por favor describa el motivo por el cual desea <b>{{action=='liberate' ? 'Desasignar' : 'Cerrar'}}</b> esta alerta (mínimo 15 caracteres)</p>
                                                                                        </v-col>
                                                                                        <v-col cols="12">
                                                                                            <v-textarea v-model="entryAction.description" type="text" :placeholder="placeholderDescription" outlined="outlined" rows="1" auto-grow ></v-textarea>
                                                                                        </v-col>
                                                                                    </v-row>                        
                                                                                </v-col>                        
                                                                            </v-row>
                                                                        </v-card-text>
                                                                        <v-divider></v-divider>
                                                                        <v-card-actions class="justify-end">
                                                                            <v-btn @click="closeAction" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cancelar
                                                                                <v-icon right dark> mdi-close</v-icon>
                                                                            </v-btn>
                                                                            <v-btn  v-if="disabledButtonModal" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> guardar y {{action=='liberate' ? 'Liberar' : 'Finalizar'}}
                                                                                <v-icon right dark> mdi-check</v-icon>
                                                                            </v-btn>
                                                                            <v-btn v-else @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> guardar y {{action=='liberate' ? 'Liberar' : 'Finalizar'}}
                                                                                <v-icon right dark> mdi-check</v-icon>
                                                                            </v-btn>
                                                                        </v-card-actions>
                                                                    </v-card>
                                                                </template>
                                                            </v-dialog>
                                                        </v-col>
                                                    </v-row>                                                          
                                                </v-col>
                                            </v-card-actions>                                           
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" md="6" >           
                                        <v-card elevation="0" class="pa-3 card-chat">
                                                
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-spacer></v-spacer>
                        <v-expansion-panel>
                            <v-expansion-panel-header color="indigo"><h3 class=" white--text" light>Mediciones</h3></v-expansion-panel-header>
                            <v-expansion-panel-content color white>
                                <v-card elevation="0">
                                    <v-card-text>
                                        <patientMeasures  :measurement_id="alert.measurement.id" :pid="$route.params.patient_id" :load="true" :type="alert.measurement.clinical_element" :date="alert.measurement.when"></patientMeasures>                          
                                    </v-card-text>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </div>     
    </div>
</template>
<script>
import BasicData from '../views/BasicData.vue';
import AxiosServices from '@/services/AxiosServices';
import patientMeasures from '../charts/patientMeasures.vue';
export default {
    name: 'ShowAlert',

    data() {
        return {
            patient:{},
            alert:{alarm:{},measurement:{},entries:[]},
            alldata:[],
            alldata2:[],
            measurementsAfterAlert: [],
            measurementsAfterAlert2: [],
            last10measurements:[],
            last10measurements2:[],
            chartdata:[],
            chartdata2:[],  
            entry:{description:""},
            entryAction:{description:""},
            show_responsible:true,
            tab: null, 
            panel: [0,1],
            readonly: false,
            disabled:false,
            dialog:false,
            action:''
        };
    },

    mounted() {
        this.getInfo()
        this.getPatient()
        this.getAlert()

    },

    methods: {
        liberate: function(){
            this.action='liberate'
            // this.entry.description=''
        },
        finalize: function(){
            this.action='close'
            // this.entry.description=''
        },

        saveAction: function() {
            let action = this.action
            if (action=='liberate') {
                this.looseAlert()
            } else if (action=='close') {
                this.closeAlert()
            } else {
                this.dialog=false
            }
            this.action=''
            this.entryAction.description=''
            this.dialog=false
        },
        closeAction: function() {
            this.action=''
            this.entryAction.description=''
            this.dialog=false
        },
        getPatient: function () {
            AxiosServices.patientDetail(this.$route.params.patient_id).then(res => {
                this.patient = res.data.data
            }).catch((error) => {
                if (error.code !='ECONNABORTED') {
                    this.$store.dispatch("addNotification", error.response.data);
                    this.$store.dispatch("render404");          
                }
            })
        },
        getAlert:function(){
            AxiosServices.patientAlertsDetail(this.$route.params.patient_id,this.$route.params.alert_id)
            .then(res => {
                this.alert=res.data.data
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
                this.$store.dispatch("render404"); 
            })
        },
        close:function(patient_id){
            this. createEntry()
            this.setEntry()
            this.$router.push({name:"patient",params:{id:patient_id,reload: true}})            
        },
        looseAlert:function(){
            this.createEntry(true)
            this.setEntry()
            let alert={user_id:0}
            this.updateAlert(this.alert.id,alert,true)            
        },
        getInfo:function(){       
            switch (this.alert.type) {
                case  0:
                    this.getMeasurements(this.alert.measurement.clinical_element)                    
                    break
                case  1:
                    console.log(this.alert.type);
                    break
                case  2:
                    console.log(this.alert.type);
                    break
            }
        },
        getMeasurements:function (clinical_element) {
            if (clinical_element == 3 || clinical_element == 4 ){
                this.formatDoubleMeasurements(this.$route.params.patient_id,3,4)                
            } else {
                this.formatMeasurements(this.$route.params.patient_id,clinical_element)               
            }            
        },
        formatDoubleMeasurements:function(patient_id,clinical_element1,clinical_element2){
            AxiosServices.patientMeasurementsList(patient_id,clinical_element1)
            .then(res => {
                let data3 = res.data.data;
                data3.sort((a, b) => new Date(b.when) - new Date(a.when));
                AxiosServices.patientMeasurementsList(patient_id,clinical_element2) 
                .then(res => {
                    let data4 = res.data.data;
                    data4.sort((a, b) => new Date(b.when) - new Date(a.when));
                    let rp1 = [];
                    let rp2 = [];
                    for(var c=0;c<data4.length;c++){
                        rp1.push({date: data3[c].when, value: Number(data3[c].value),  unit: data3[c].clinical_element_unit})
                        rp2.push({date: data4[c].when, value: Number(data4[c].value),  unit: data4[c].clinical_element_unit})
                    }
                    this.alldata = rp1;
                    this.alldata2 = rp2; 
                    this.measurementsAfterAlert=this.setMeasurementsAfterAlert(this.alldata)
                    this.measurementsAfterAlert2=this.setMeasurementsAfterAlert(this.alldata2)
                    this.last10measurements=this.filterLast10Measuerements(this.alldata)
                    this.last10measurements2=this.filterLast10Measuerements(this.alldata2)
                    this.changeToMeasurementsAfterAlert()
                            
                })
            })
        },
        formatMeasurements:function(patient_id,clinical_element){
            AxiosServices.patientMeasurementsList(patient_id,clinical_element)
            .then(res => {
                let data2 = res.data.data;
                data2.sort((a, b) => new Date(b.when) - new Date(a.when));
                if (data2.length > 0) {
                    this.empty = false;
                    let rp = [];
                    for (let item of data2){
                        rp.push({ date: item.when, value: Number(item.value), unit: item.clinical_element_unit })
                    }            
                    this.alldata = rp; 
                    this.measurementsAfterAlert=this.setMeasurementsAfterAlert(this.alldata)
                    this.last10measurements=this.filterLast10Measuerements(this.alldata)
                    this.changeToMeasurementsAfterAlert()
                }
            })
        },
        changeToMeasurementsAfterAlert:function(){                    
            this.chartdata=this.measurementsAfterAlert
            this.chartdata2=this.measurementsAfterAlert2
        },
        changeTolast10Measurements:function(){
            this.chartdata=this.filterLast10Measuerements(this.alldata)
            this.chartdata2=this.filterLast10Measuerements(this.alldata2)            
        },
        changeAllMeasurements:function(){
            this.chartdata=this.alldata
            this.chartdata2=this.alldata2
        },
        setMeasurementsAfterAlert:function(array){
            const alertDate = new Date(this.alert.measurement.when).getTime();
            const filterMeasuements = array.filter((measurement) => {
                const measurementWhen = new Date(measurement.date).getTime();
                return measurementWhen > alertDate
            });  
            filterMeasuements.sort((a,b) => new Date(a.date) - new Date(b.date));
            return filterMeasuements
        },
        filterLast10Measuerements:function(array){           
            return array.slice(0,10)
        },
        iconalerta: function (color) {
            switch (color) {
                case "programada":
                    return "mdi-bell-ring";
                case 0:
                    return "mdi-heart-pulse";
                case 1:
                    return "mdi-heart-pulse";
                case 2:
                    return "mdi-lungs";
                case "fr":
                    return "mdi-lungs";
                case 3:
                    return "mdi-pulse";
                case 4:
                    return "mdi-pulse";
                case 6:
                    return "mdi-water";
                case 5:
                    return "mdi-thermometer";
            }
        },
        colorPriority: function (priority) {
            switch (priority) {
                case 1:
                    return "error";
                case 2:
                    return "orange";
                case 3:
                    return "primary";
                default:
                    return "black";
            }
        },
        getTimer: function (dateTime) {
            var show = [];
            var currentTime = new Date(new Date().toLocaleString("en-US", { timeZone: this.alert.time_zone_patient }))
            var alertTime = new Date(dateTime)
            var difference = (currentTime - alertTime);
            var days = Math.floor(difference / (1000 * 60 * 60 * 24));
            var hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            
            if (days > 0) {
                show.push(`${days} dias `);
            }
            if (hours > 0) {
                show.push(`${hours} horas `);
            }
            if (minutes >= 0) {
                show.push(`${minutes} minutos `);
            }
            if (show.length > 0) {
                show = show.toString();
            }
            return show;
        },
        setEntry:function() {
            this.entry={description:''}
        },        
        updateAlert:function(alert_id,alert_update,redirect){            
            let alert = {alert:alert_update}
            AxiosServices.patientAlertsUpdate(this.$route.params.patient_id,alert_id,alert)
            .then(res => {
                this.$store.dispatch("addNotification", res.data)                
                if(redirect){
                    this.$router.push({name:"patient",params:{id:this.$route.params.patient_id}})
                } else {
                    this.getAlert()
                }
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
                if(redirect){
                    this.$router.push({name:"patient",params:{id:this.$route.params.patient_id}})
                }
            })            
        },
        createEntry:function(redirect){
            let entry = ''
            if (!this.dialog) {
                entry = this.entry
            } else {
                entry = this.entryAction
            }
            AxiosServices.patientAlertsEntriesCreate(this.$route.params.patient_id,this.alert.id,entry)
            .then(res => {
                this.$store.dispatch("addNotification", res.data)
                if(!redirect){
                    this.getAlert()
                }
                this.setEntry()            
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
                this.setEntry()
            })
        },
        closeAlert:function(){            
            this.createEntry(true)
            let alert={status:2}
            this.updateAlert(this.alert.id,alert,true)            
        },
        nameCapitalize(texto) {
            return  String(texto).toLowerCase();
        }      
    },
    computed: {
        user:function(){
            return this.$store.state.user
        },
        disabledButton() {
            let disabled = true
            if (this.$store.state.user.id == this.alert.user_id && this.entry.description != ''){
                disabled = false
            }
            return disabled
        },
        disabledButtonModal() {
            let disabled = true
        if (this.$store.state.user.id == this.alert.user_id && this.entryAction.description.length >= 15){
                disabled = false
            }
            return disabled
        },
        placeholderDescription(){
            let placeholder="Escribe una nueva entrada"
            if (this.$store.state.user.id != this.alert.user_id){
                placeholder=`No puede crear una nueva entrada, solo ${this.alert.user_name} puede crear entradas en esta alerta`
            }
            return placeholder
        }
    },
    components: {
        BasicData,
        patientMeasures
    },
};
</script>
<style>
    .v-expansion-panel {
        margin-bottom: 16px;    
    }
    .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: white !important
    }
    .new-entry .v-text-field__details{
        display: none;
    }
    .card-chat{
        height: 50vh;
        background-image: url('/src/assets/bg-chat-01.png');
        background-size: cover;
    }
    .chat {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 90vh;
        max-height: 38rem;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 1rem;
        box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 0rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
    }
    .chat .messages {
        padding: 1rem;
        background-image: url('/src/assets/bg-chat-01.png');
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.5);
        flex-shrink: 2;
        overflow-y: auto;
        box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05), inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
    }
    .chat .messages .message {
        box-sizing: border-box;
        font-size: 15px;
        padding: 0.5rem 1rem;
        margin: 1rem 0rem;
        background: #FFF;
        border-radius: 1.125rem 1.125rem 1.125rem 0;
        min-height: 2.25rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 66%;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
    }
    .chat .messages .message.message-out {
        margin: 1rem 0rem 0rem auto;
        border-radius: 1.125rem 1.125rem 0 1.125rem;
        background: #d5ffcb;
        color: black;
    }
    .seen{
        font-size: 12px;
        display: flex;
        place-content: flex-end;
        margin-bottom: 0px;
    }
    .no-msg{
        color: #999;
        text-align: center;
    }
    .nature-chat{
        margin-top: 20px;
        margin-right: 5px;
    }
    .natute-out{
        order: 1;
        margin-left: 5px;
    }
    .check-delive{
        color: rgb(171, 171, 171);
    }
    .check-read{
        color: rgb(0, 170, 255);
    }
    .glady-message{
        position: relative;
        top: 6px;
    }
    .btn-send-entry{
        position: absolute !important;
        right: 5px;
        bottom: 12px;
    }
    .relative{
        position: relative;
    }
    .v-application--is-ltr .text-area-entry.v-text-field--enclosed .v-text-field__slot textarea{
        padding-bottom: 15px;
        padding-right: 60px;
    }

    .theme--light.v-input input::placeholder, .theme--light.v-input textarea::placeholder {
        font-size: small;
        line-height: normal;
    }

</style>