<template>
    <div class="inputglya" :class="inputsize">
        <v-text-field  :type="type" :label="label_show" :placeholder="place || label" :solo="solo" :outlined="outlined" :filled="filled" v-model="inputIn"
        @input="updateValue" required :error-messages="nameErrors" :class="transform || filter || element || login" :prefix="prefix" :disabled="disabled"
></v-text-field>
    <span class="ghost">{{ watch_inputIn }}</span>
    </div>



</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'




export default {

    name:"BaseInput",

    props: {
        validador:{
            default:"namesSurnames"
        }, value:{},filter:{},login:{},element:{}, prefix:{}, solo:{}, outlined:{default:false}, filled:{},type:{},label:{},transform:{},inputsize:{},idtype:{default:"CC"},
        place:{},disabled:{default:false},reload_validation:{default:0}
    },
    model:{
        prop:"value",
        event:"blur"
    },
    data() {
        return {
            // id: Math.floor(Math.random() * 1000000),
            id:this.$props.label,
            minl: 3,
            namein:"inputIn",
            inputIn:this.$props.value,
            validations: {
                namesSurnames: {
                    minLength: 3
                }
            },
            showlabel:false,
            jsback: this.$store.state.validations,

            test: {
                required,
                minLength: minLength(3)
            }, 
            // regular:this.$store.state.idTypeListRegex[this.$props.idtype],
            // regularExpressionIdType: this.$store.dispatch("putValidationNid",this.regular[this.$props.idtype]),
            // fromvuex:this.$store.state.validations2[this.$props.validador],
            // fromback: this.$store.state.validations[this.$props.validador]

            // validacion:this.$store.state.validaciones[this.validador]
            // validacion: {
            //     minLength: eval("minLength(this.minl)")
            // },
            
        
        }
    },
    mounted() {
        this.eval_errors();
    },
    created() {
        this.eval_errors()
    },
    methods: {
        updateValue(value) {
            value = value.replace(/(^\s+|\s+$|\t+|&#160;+|\xA0)/g, '');
            this.$emit('blur', value);
            this.eval_errors()
            this.$emit('changeglya');
            this.$v.inputIn.$touch();
            this.$emit('validation-updated', this.$v.inputIn.$invalid);
        },
        changeglya() {
            this.$emit('changeglya');
        },
        uppercase() {
            if (this.validador == 'namesSurnamesRequired' || this.validador == "namesSurnames") {                
                this.inputIn = this.inputIn.toUpperCase();
            }
        },
        eval_errors: function () {
            if (this.nameErrors.length == 0) {
                this.$store.dispatch("puterror", { [this.id]: 0 })
            } else {
                this.$store.dispatch("puterror", { [this.id]: 1 })
            }
        },
        set_input: function () {
            return true
        },
        set_label: function () {
            this.showlabel = true
            return this.showlabel
        },
        setValue: function (newValue) {
            this.inputIn = newValue
            this.$v.$touch()
            this.$v.$reset()
            this.eval_errors()
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setValue(newValue)
            }
        },
        reload_validation(newVal){
            if (newVal>0){
                this.eval_errors()
            }
                
        }
    },
    computed: {       
        label_show: function() {
            let label=this.$props.label
            if (this.showlabel) {
                label = `${this.$props.label}${'*'}`
            }
            return label
        },
        watch_inputIn: function(){
            return this.set_input()            
        }, 
        idtypeList: function () {
            return this.$store.state.idTypeList
        },
        validator: function () {
            return this.$store.state.validations2[this.$props.validador]
        },
        eval_required_show: function(){
            return this.$store.state.show_required
        },
        hola() {
            return this.$v.inputIn.required
        },
        nameErrors() {
            let errors = []
            if (!this.$v.inputIn.minLength && this.validator['minLength'] !== undefined ) {
                errors.push("Debe tener mínimo 3 caracteres")
            }
            if (!this.$v.inputIn.maxLength && this.validator['maxLength'] !== undefined ) {
                errors.push("Debe tener máximo 8 caracteres")
            }
            if (!this.$v.inputIn.maxLength_numeric && this.validator['maxLength_numeric'] !== undefined ) {
                errors.push("Maximo 3 caracteres")
            }
            if (!this.$v.inputIn.numeric && this.validator['numeric'] !== undefined) {
                errors.push('¡Ingrese solo números')
            }
            if (!this.$v.inputIn.required && this.validator['required'] !== undefined) {
                if (this.eval_required_show) {
                    errors.push('¡El campo es requerido!')                    
                } else {
                    errors.push(' ')
                }
                this.set_label()
            }
            if (!this.$v.inputIn.nidVal && this.validator['nidVal'] !== undefined) {
                errors.push('¡El valor ingresado no es válido o esta incompleto!')
            }
            if (!this.$v.inputIn.phone2Val && this.validator['phone2Val'] !== undefined) {
                errors.push('¡Ingrese solo números y un máximo de 11 carácteres!')
            }
            if (!this.$v.inputIn.phoneVal && this.validator['phoneVal'] !== undefined) {
                errors.push('¡Ingrese solo números, entre 7 y 10 carácteres!')
            }
            if (!this.$v.inputIn.email && this.validator['email'] !== undefined) {
                errors.push('¡El formato de correo no es válido!')
            }
            if (!this.$v.inputIn.urlVal && this.validator['urlVal'] !== undefined) {
                errors.push('¡El formato de la URL no es válido!')
            }
            if (!this.$v.inputIn.serialVal && this.validator['serialVal'] !== undefined) {
                errors.push('El valor ingresado no es válido o esta incompleto,debe contener 16 carácteres')
            }
            
            return errors
        }       
        
    },    
    components: {
    },

    mixins: [validationMixin],

    validations: function(){  
        if (this.minl>0) {

            return {
                inputIn: eval(this.validator)
            }  
        
        }
        return {
            x: {
                required,
                minLength: minLength(this.minl)
            }
        }
    }
}

</script>
