<template>
    <div>
        <div class="d-flex justify-space-between">
            <h2>HL7 FHIR Webhook</h2>
            <img src="../../assets/HL7_FHIR_logo.webp" alt="gdi" width="130">
        </div>
        <v-card class="mx-auto pa-5 mt-4">
            <div v-if="whRegistered==false">
                <v-row>
                    <v-col cols="12" sm="6">
                        <BaseInput v-model="hl7.webhook_url" type="text" validador="urlRequired" label="URL Webhook" element="element" outlined ></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <InputPassword v-model="hl7.security_phrase" type="text" validador="codRequired" label="Palabra de seguridad" element="element" outlined ></InputPassword>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-btn disabled v-if="verror" class="btn-hl7 white--text" block color="indigo" small> Guardar</v-btn>
                        <v-btn v-else class="btn-hl7 white--text" block color="indigo" small @click="saveWeebhook()"> Guardar</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-col cols="12" class="pt-0">
                    <table class="table-wh">
                        <tbody>
                            <tr>
                                <td class="pa-0">
                                    <span class="text-caption indigo--text "><b>Weebhook URL</b></span> 
                                </td>
                                <td class="pa-0">
                                    <span class="text-caption indigo--text"><b>Palabra de seguridad</b></span> 
                                </td>
                            </tr>
                            <tr>
                                <td class="pa-0">
                                    <span class="color-text-glya pa-0 ma-0"><v-icon v-show="hl7.validate" small color="success" class="mr-2">mdi-check-decagram</v-icon>{{hl7.webhook_url}}</span>
                                </td>
                                <td class="pa-0">
                                    <p class="color-text-glya pa-0 ma-0"><v-icon v-show="hl7.validate" small color="success" class="mr-2">mdi-check-decagram</v-icon>{{tokenOcult(hl7.security_phrase)}}</p>
                                </td>
                                <td class="text-right pa-0" >
                                    <div v-if="loader" class="d-flex flex-center text-center mt-5">
                                        <v-col class="pa-0">
                                            <span class="loader"></span>
                                        </v-col>
                                    </div> 
                                    <div v-else>  
                                        <v-btn class="btn-hl7 white--text ma-1" color="indigo" small @click="validateWeebhook()" :disabled="hl7.validate"> Validar <v-icon small class="ml-2">mdi-check-underline</v-icon></v-btn>
                                        <v-btn class="btn-hl7 white--text ma-1" color="green" small @click="editWeebhook()"> Editar <v-icon small class="ml-2">mdi-pencil</v-icon></v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <v-divider class="mt-5"></v-divider>
                </v-col>
            </div>
            <v-col>
                <h3>Suscripciones: </h3>
                <v-col cols="12" sm="6" class="mt-2 pa-0">
                    <table class="table-sub">
                        <tbody>
                            <tr>
                                <td class="pt-4">
                                    <div class="d-flex justify-center" width="100%">
                                        <v-switch class="switchglya" color="indigo" type="t ype" v-model="hl7.webhook_measurements" @change="saveWeebhook"></v-switch>
                                    </div>
                                </td>
                                <td>Mediciones</td>
                                <td class="text-center"><v-chip :color="hl7.webhook_measurements==true ? 'green' : 'red'" outlined small>{{hl7.webhook_measurements==true ? 'Suscrito' : 'No suscrito'}}</v-chip></td>
                            </tr>
                            <tr>
                                <td class="pt-4 justify-center">
                                    <div class="d-flex justify-center" width="100%">
                                        <v-switch class="switchglya" color="indigo" type="t ype" v-model="hl7.webhook_alerts" @change="saveWeebhook"></v-switch>
                                    </div>
                                </td>
                                <td>Alertas de valor</td>
                                <td class="text-center"><v-chip :color="hl7.webhook_alerts==true ? 'green' : 'red'" outlined small>{{hl7.webhook_alerts==true ? 'Suscrito' : 'No suscrito'}}</v-chip></td>
                            </tr>
                        </tbody>
                    </table>
                </v-col>
            </v-col>     
        </v-card>
    </div>
</template>
<script>
import AxiosServices from '@/services/AxiosServices';
import BaseInput from '../forms_items/BaseInput.vue';
import InputPassword from '@/components/forms_items/InputPassword.vue'
export default {
    name: 'WebWebHooks',

    data() {
        return {
            hl7:{},
            whRegistered: false,
            loader:false,
            ocult:''
        };
    },
    mounted() {
        this.getClient()
        this.weebhookSaved()
    },
    watch:{
        hl7(newValue) {
            this.ocult = this.tokenOcult(newValue)
        },
    },
    methods: {
        getClient:function(){
            AxiosServices.clientDetail(this.$store.state.user.client_id)
            .then(res => {
                this.hl7=res.data.data
            }).catch(error => {
                console.log('el error es',error);
            })
        },
        saveWeebhook:function(){
            if (!this.verror ||  this.whRegistered== true) {
                AxiosServices.clientUpdate(this.$store.state.user.client_id,this.hl7).then(res =>{
                    this.$store.dispatch("addNotification", res.data)
                    this.$store.dispatch("reseterror")
                    this.whRegistered= true
                }).catch(error => {
                    console.log('el error es',error);
                    this.clearForm()
                })
                
            }
        },
        weebhookSaved() {
            if (this.hl7.webhook_url!='' && this.hl7.security_phrase!='') {
                this.whRegistered = true
            }
        },
        validateWeebhook:function(){
            this.loader=true
            AxiosServices.clientValidate(this.$store.state.user.client_id,this.hl7).then(res =>{
                this.$store.dispatch("addNotification", res.data)
                console.log('trae la validacion',res.data);
                this.loader=false
            }).catch(error => {
                this.loader=false
                this.$store.dispatch("addNotification", error.response.data);
                console.log('el error es',error);
            }).finally(() => {
                this.getClient()
            })
            
            // setTimeout(() => {
            //     this.loader = false;
            //     this.hl7.validate = true
            // }, 2000);
        },
        editWeebhook:function(){
            this.whRegistered= false
            this.hl7.validate = false
        },
        tokenOcult:function(token){
            var ocult
            if (token != undefined && this.whRegistered==true) {
                ocult='*'.repeat(token.length)
            }
            return ocult
        }
    },
    computed:{
        verror:function(){
                var show=false
                if (this.$store.state.form.reserror){
                    show=true
                }
                return show
            },
    },
    components: {
        BaseInput,
        InputPassword,
    },
};
</script>
<style>
    .btn-hl7:not(.v-btn--round).v-size--small {
    height: 32px;
    }
    .table-wh{
        border-collapse: collapse;
        width: 100%;
    }
    .table-wh tr td{
        padding: 5px;
        border: 0px solid rgb(183, 173, 250);
    }
    .table-wh td{
        width: 150px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .loader {
        width: 30px;
        height: 30px;
        border: 5px solid rgb(186, 186, 186);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .table-sub{
        border-collapse: collapse;
        width: 100%;
    }
    .table-sub tr td{
        padding: 5px;
        border: 1px solid rgb(202, 197, 236);
    }
</style>