<template>
  <div>
    <v-card class="mx-auto mt-1 mb-2" elevation="2" outlined>
      <router-link class="link" :to="{ name: 'patient', params: { id: patient.id } }">
        <v-card-title
          class="pa-1"
          :class="colorestado(patient.status)"
        ></v-card-title>
        <v-list-item class="d-flex align-start">
          <v-avatar size="70px" class="ma-3">
            <img
              alt="Femenino"
              v-if="patient.gender_name == 'Femenino'"
              :src="profileF"
            />
            <img
              alt="Masculino"
              v-else-if="patient.gender_name == 'Masculino'"
              :src="profileM"
            />
            <img alt="Indeterminado" v-else :src="profile" />
          </v-avatar>
          <v-list-item-content class="pa-1">
            <v-row>
              <v-col cols="12" sm="8" class="ma-0 card-data">
                <v-list-item-title class="text-h6 text-uppercase">
                  {{ patient.first_name }} {{ patient.last_name }} | {{patient.age}} años  <v-chip x-small class="status white--text" :class="colorestado(patient.status)">{{patient.status_name}}</v-chip>
                  </v-list-item-title
                >
                <v-list-item-content class="color-text-glya pa-0 mt-1">
                  <p class="mb-1">
                    <span class="pr-4">
                      {{patient.id_type_name}} {{patient.nid}}
                    </span>
                    <v-icon small class="cyan--text">mdi-cellphone</v-icon>
                    <span
                      v-if="patient.cellphone == '' || patient.cellphone == null"
                      class="pr-4"
                      ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined
                        >Teléfono Incompleto</v-chip
                      ></span
                    >
                    <span v-else class="pr-4">{{ patient.cellphone }}</span>
                    <v-icon small class="cyan--text">mdi-email</v-icon>
                    <span v-if="patient.email == '' || patient.email == null"
                      ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined
                        >Email Incompleto
                      </v-chip></span
                    >
                    <span v-else>{{ patient.email }}</span>
                  </p>
                  <p class="mb-1">
                    <v-icon small class="cyan--text">mdi-home</v-icon>
                    <span v-if="patient.address == '' || patient.address == null">
                      <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined
                        >Dirección incompleta</v-chip
                      >
                    </span>
                    <span v-else>{{ patient.address }} {{patient.city_name}}</span>
                  </p>
                  <p class="mb-1" v-if="patient.country_id == 26">
                    <v-icon small class="cyan--text">mdi-hospital-building</v-icon>
                    <span> {{ patient.eapb }} </span>
                    <span v-if="patient.regime == '' || patient.regime == null"
                      ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined
                        >Sin Regimen
                      </v-chip></span
                    >
                    <span v-else>{{ patient.regime }}</span>
                  </p>
                </v-list-item-content>
              </v-col>
              <!-- <v-col cols="12" sm="3" class="pa-0 mt-1 card-status">
                <v-chip
                  class="pl-5 pr-5 chip-status"
                  :color="colorestado(patient.status)"
                  text-color="white"
                  label
                  large
                  >{{ patient.status_name }}</v-chip
                >
              </v-col> -->
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-card>
  </div>
</template>
<script>
import profileF from "@/assets/perfilesvacf2.jpg";
import profileM from "@/assets/perfilesvacm2.jpg";
import profile from "@/assets/perfilesvac.jpg";

export default {
  props: {
    pid: String,
    patient_info: {},
  },

  data() {
    return {
      patient: this.$props.patient_info,
      profileF,
      profileM,
      profile,
    };
  },

  methods: {
    gopatient: function () {
      this.$router.push({ name: "patient", params: { id: this.patient.id } });
    },
    colorestado: function (estado) {
      switch (estado) {
        case 0:
          return "grey";
        case 1:
          return "success";
        case 2:
          return "red";
        case 3:
          return "black";
      }
    },
  },

  mounted() {
    // axios.get(`${this.$URL}api/v1/patients/${this.pid}.json?card=true`,this.$store.getters.header).then(res=>{
    //     this.patient=res.data.patient
    // })
  },

  components: {},
};
</script>
<style>
.status{
  margin-top: -5px;
}
.link {
  text-decoration: none;
  color: inherit;
}
.chip-status {
  margin-top: -5px;
  margin-right: 12px;
}
.chip-status.v-size--large {
  height: 33px;
}
.card-status {
  text-align: right;
}
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
@media (max-width: 600px) {
  .card-status {
    text-align: left;
  }

  .card-data {
    order: 2;
  }
  .card-status {
    order: 1;
  }
}
</style>
