<template>
    <div>
        <h2>Gestor de alertas</h2>
        <v-card class="mt-4">
            <v-tabs v-model="tab" background-color="indigo" centered dark >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1">
                    <span class="pl-5 pr-5"><v-icon small class="pr-2 pb-1">mdi-folder-open</v-icon> Abiertas</span>
                </v-tab>
                <v-tab href="#tab-2">
                    <span class="pl-5 pr-5"><v-icon small class="pr-2 pb-1">mdi-folder</v-icon> Cerradas</span>
                </v-tab>
            </v-tabs>
        
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-card>
                                        <v-card-title class="red lighten-1 white--text pa-0 pl-5 card-title-show">
                                            <span class="text-body-1 pt-1 pb-2">
                                                Alertas sin asignar ({{openAlerts.length}})
                                            </span>
                                        </v-card-title>
                                        <v-card-text class="text--primary pa-1 scrollableAlerts alerts-tab" >
                                            <div v-if="openAlerts.length > 0 ">
                                                <v-row class="pa-3">
                                                    <v-col cols="12"  class="pa-2" v-for="alert in openAlerts" :key="alert.id">
                                                        <CardAlert @reloadAlerts="getAlerts()" source="management" :patient_id="alert.patient.id" :alert_prop="alert"></CardAlert>                       
                                                    </v-col> 
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                                    <v-icon size="50">mdi-bell</v-icon>
                                                    <h3>No existen Alertas por gestionar</h3>
                                                </v-col>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <v-card>
                                        <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                                            <span class="text-body-1 pt-1 pb-2">
                                                Alertas asignadas ({{alerts_assigned.length}})
                                            </span>
                                        </v-card-title>
                                        <v-card-text class="text--primary pa-1 scrollableAlerts alerts-tab" >
                                            <div v-if="alerts_assigned.length > 0 ">
                                                <v-row class="pa-3">
                                                    <v-col cols="12" class="pa-2" v-for="alert_assigned in alerts_assigned" :key="alert_assigned.id">
                                                        <CardAlert @reloadAlerts="startComponent()" source="management" :patient_id="alert_assigned.patient.id" :alert_prop="alert_assigned"></CardAlert>                       
                                                    </v-col> 
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                                    <v-icon size="50">mdi-bell</v-icon>
                                                    <h3>No existen Alertas por gestionar</h3>
                                                </v-col>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-2">
                    <v-card flat>
                        <v-card-text>
                            <v-col>
                                <v-row class="d-flex justify-center pt-0">
                                    <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                                    <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                                        {{ filtersType[button]['label'] }}
                                    </v-btn>
                                    
                                </v-row>
                            </v-col>
                            <v-row class="mt-4 d-flex justify-center">
                                <v-col v-if="filtersType['date']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0 col-form-icon" >
                                    <BaseDate v-model="filter.start_at" :valueinit="filter.start_at" limitType="birthdate" label="Fecha Inicial" outlined="outlined" filter="filter" ></BaseDate>
                                </v-col>
                                <v-col v-if="filtersType['date']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0 col-form-icon" >
                                    <BaseDate v-model="filter.end_at" :valueinit="filter.end_at" limitType="birthdate" label="Fecha final" outlined="outlined" filter="filter" ></BaseDate>
                                </v-col>
                                <v-col v-if="filtersType['user_id']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                    <SelectSearch v-model="filter.user_id" :valuex="filter.user_id == '' ? 1 : {id:filter.user_id,text:filter.user_name}" label="Responsable" model="users" outlined="outlined" filter="filter"></SelectSearch>
                                </v-col>
                                <v-col v-if="filtersType['type']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                    <BaseSelectMult v-model="filter.alert_type" type="text" label="Tipo" list_type="tipo_alerta" outlined="outlined" filter="filter"></BaseSelectMult>
                                </v-col>
                                <v-col v-if="filtersType['clinical_element']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                    <BaseSelect v-model="filter.clinical_element" type="text" label="Elemento Clínico" list="clinical_elements_constants" outlined="outlined" filter="filter"></BaseSelect>
                                </v-col>
                                <v-col v-if="filtersType['priority']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                    <BaseSelectMult v-model="filter.priority" type="text" label="Prioridad" list_type="priodad_alarma" outlined="outlined" filter="filter"></BaseSelectMult>
                                </v-col>
                            </v-row>
                            <v-row v-if="showBtnFilter.length != 0" justify="center" class="pb-5 pt-0">
                                <v-col cols="12" sm="6" class="pb-5 pt-0">
                                    <v-btn @click="toFilter" :disabled="disableFilter" block color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- <v-col cols="12"  md="4" class="pa-0">
                                <v-text-field class="mb-2 pt-2 pb-1 filter" placeholder="Buscar" v-model="search" append-icon="mdi-magnify" single-line hide-details outlined></v-text-field>
                            </v-col> -->
                            <v-data-table :headers="headers" :items="closedAlerts"  :server-items-length="totalItems" :loading="spinnerClosed" :search="search" class="elevation-1 GdiTable" item-key="item.id" 
                            :footer-props="{'items-per-page-options': [5, 10, 20],'items-per-page-text': 'Alertas por página'}"
                            @update:page="updatePage"
                            @update:items-per-page="updateItemsPerPage">
                                <template v-slot:[`item.description`]="{ item }">
                                    <p v-if="item.type==0" class="mb-0"> {{ item.measurement.clinical_element_name }} <v-icon>mdi-chart-timeline-variant</v-icon> ({{item.alarm.minimum_condition}}-{{item.alarm.maximum_condition}}) </p>
                                    <p v-if="item.type == 1" class="mb-0">{{ getFormattedText(item.order.to_human, 'a las', 0) }} 
                                        <v-icon size="15px">mdi-clock</v-icon> {{item.activity.programmed_at | formatHour}}</p>
                                    <p v-if="item.type == 2" class="mb-0"> {{ getFormattedText(item.order.to_human, 'cada', 0) }} 
                                        <v-icon size="15px">mdi-clock</v-icon> {{item.activity.programmed_at | formatHour}}</p>
                                </template>
                                <template v-slot:[`item.measurement.value`]="{ item }">
                                    <!-- SE QUITA EL ICONO POR QUE NO HAY PRIORIDAD -->
                                    <!-- <p v-if="item.type==0" class="mb-0">  <v-chip outlined color="blue-grey"><v-icon :color="colorPriority(item.alarm.priority)" class="mr-2">mdi-flag</v-icon>{{ item.measurement.value }} {{ item.measurement.clinical_element_unit }}</v-chip></p> -->
                                    <p v-if="item.type==0" class="mb-0">  <v-chip outlined color="blue-grey">{{ item.measurement.value }} {{ item.measurement.clinical_element_unit }}</v-chip></p>
                                    <p v-if="item.type>0" class="mb-0"> <v-chip outlined color="blue-grey">Actividad Incumplida</v-chip></p>
                                </template>
                                <template v-slot:[`item.fecha`]="{ item }">
                                    <p class="mb-0"> {{ item.type==0 ? item.measurement.when : item.activity.updated_at | formatDate(item.time_zone_patient)}}
                                        <v-tooltip bottom v-if="item.time_zone_patient != user.time_zone">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="indigo" small dark v-bind="attrs" v-on="on">mdi-web</v-icon>
                                            </template>
                                            <span>{{item.time_zone_patient}} </span>
                                        </v-tooltip>
                                    </p>
                                </template>
                                <template v-slot:[`item.status_name`]="{ item }">
                                    <p class="mb-0"> <v-chip outlined :color="item.status_name=='Activa' ? 'orange darken-1' : 'grey'"> {{ item.status_name}} </v-chip> </p>
                                </template>
                                <template v-slot:[`item.patient.full_name`]="{ item }">
                                    <p class="mb-0 capitalize"> {{ item.patient.full_name}} </p>
                                </template>
                                <!-- SE QUITA COLUMNA HISTORIALO POR QUE NO HAY ENTRADAS -->
                                <!-- <template v-slot:[`item.historial`]="{ item }">
                                    <v-dialog v-if="item.entries.length!=0" transition="dialog-bottom-transition" max-width="800" v-model="dialogs[item.id]">
                                        <template v-slot:activator="{ on, attrs }">            
                                            <v-btn color="indigo lighten-1" x-small dark  v-bind="attrs" v-on="on">
                                                <v-icon small class="mr-1">mdi-clipboard-text-clock</v-icon> Ver historial
                                            </v-btn>
                                        </template>
                                            <template v-slot:default>
                                                <v-card >
                                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Historial de gestión de alerta</v-toolbar>
                                                    <v-card-text class="pb-0">                                             
                                                        <v-col>                                                            
                                                            <v-card color="blue-grey lighten-5" class="pa-3 scrollable2" elevation="0">
                                                                <v-card-text  v-for="entry_alert in item.entries" :key="entry_alert.id" class="white pa-2 mb-2">
                                                                    <v-row>
                                                                        <v-col cols="12" class="pb-0">
                                                                            <p class="mb-0">
                                                                                <img src="../../assets/Glady-color.svg" alt="Glady" width="25" class="glady-message" v-if="entry_alert.user_name=='Glya'">
                                                                                <span class="text-subtitle-2 indigo--text text--lighten-2 pr-1 capitalize">{{nameCapitalize(entry_alert.user_name)}}:</span>{{entry_alert.description}}</p>
                                                                        </v-col>
                                                                        <v-col cols="12" class="pt-0">
                                                                            <p class="mb-0 text-caption text-right indigo--text">{{entry_alert.created_at | formatDate}}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>                                
                                                                <v-col class="text-center">
                                                                    <v-icon size="50px" color="grey">mdi-check-decagram</v-icon>
                                                                    <h3 class="grey--text">Alerta cerrada por <b>{{item.entries[item.entries.length - 1].user_name}}</b></h3>
                                                                </v-col>
                                                            </v-card>
                                                        </v-col>                                             
                                                    </v-card-text>
                                                    <v-card-actions class="d-flex justify-end">                                              
                                                        <v-btn @click="dialogs[item.id]=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Salir <v-icon right dark > mdi-close</v-icon> </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>     
                                    <p v-else class="grey--text mb-0 pt-0"><v-icon color="grey" x-small>mdi-note-off</v-icon> Sin historial </p>
                                </template> -->
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>
<script>
import AxiosServices from '@/services/AxiosServices';
import CardAlert from '../views/CardAlert.vue';
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";
import SelectSearch from '@/components/forms_items/SelectSearch.vue';
import BaseDate from '@/components/forms_items/BaseDate.vue'

export default {
    name: 'AlertsManagement',
    mounted () {
        this.startComponent()
    },

    data() {
        return {
            tab: null,
            openAlerts:[],
            closedAlerts:[],
            alerts_assigned:[],
            headers: [
                { text: "ID", value: "id" },
                { text: "Estado", value: "status_name" },
                { text: "Fecha", value: "fecha" },
                { text: "Paciente", value: "patient.full_name"},
                { text: "Descripción", value: "description" },
                { text: "Valor", value: "measurement.value" },
                // { text: "Historial", value: "historial" },
            ],
            dialogs:{},
            dialog:false,
            search: '',
            filter:{
                start_at:new Date(new Date().getTime() - 604800000).toISOString().substr(0, 10), 
                end_at:new Date(Date.now()).toISOString().substr(0,10),
                user_id:'',
                user_name:'',
                alert_type:'',
                clinical_element:'',
                priority:'',
                client_id:this.$store.state.user.client_id,
                type:1
            },
            filtersType:{
                'date':{'active':false, 'label':'Fecha', 'filter':['start_at','end_at']},
                'user_id':{'active':false, 'label':'Responsable', 'filter':['user_id']},
                'type':{'active':false, 'label':'Tipo', 'filter':['alert_type']},
                'clinical_element':{'active':false, 'label':'Elemento Clínico', 'filter':['clinical_element']},
                'priority':{'active':false, 'label':'Prioridad', 'filter':['priority']}
            },
            spinnerClosed:false,
            totalItems: 10,
            per_page:10,
            page:1,

            // show_table:false
        };
    },
    computed:{
        user:function(){
            return this.$store.state.user
        },
        showBtnFilter() {
            let select = []
            Object.keys(this.filtersType).forEach(element => {
                if (this.filtersType[element]['active']) {
                    select.push(this.filtersType[element]['filter'])
                }
            });
            return select.flat()
        },
        disableFilter() {
            let disable = []
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] == '' || this.filter[field] == null) {
                    if (this.filter[field]!==0){
                        disable.push(field)
                    }
                }
            });
            return disable.length > 0
        }
    },
    methods: {
        updatePage(page){
            this.page = page
            this.getClosedAlerts()
        },
        updateItemsPerPage(per_page){
            this.per_page = per_page
            this.page = 1
            this.getClosedAlerts()
        },
        changeButton:function(button){
            if (this.filtersType[button]['active']){
                this.filtersType[button]['filter'].forEach(field => {
                    this.setDefaultFilter(field)
                })
            }
            this.filtersType[button]['active']= !this.filtersType[button]['active']
        },
        setDefaultFilter:function(nameField){
            if (nameField=='start_at' || nameField=='end_at') {
                this.filter[nameField]=(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0])
            } else {
                this.filter[nameField]=''
            }
        },
        toFilter: async function() {
            await this.buildParams()
            this.getClosedAlerts()
        },   
        buildParams:function () {
            let params = {'client_id':this.$store.state.user.client_id,'type':1,'page': this.page, 'per_page': this.per_page}
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] != '' || this.filter[field]==0){
                    params[field] = this.filter[field]
                }
            });
            return params
        },
        selectedFilter(index) {
            this.buttonFilters[index].selected = !this.buttonFilters[index].selected;
        },
        startComponent:function(){
            this.getOpenAlerts()
            this.getClosedAlerts()
        },
        getOpenAlerts:function(){
            AxiosServices.clientAlertsList(this.$store.state.user.client_id,0)
            .then(res => {
                this.filterAndDivideAlerts(res.data.data.data)
            }).catch(error => {
                console.log('el error es',error);
            })
        },
        getClosedAlerts:function(){
            let params = this.buildParams()
            this.spinnerClosed = true
            AxiosServices.clientAlertsClosedList(params)
            .then(res => {
                this.closedAlerts=res.data.data.data
                this.createDialogsIDs(res.data.data.data)
                this.totalItems = res.data.data.total
                this.page = res.data.data.page
                
            }).catch(error => {
                console.log('el error es',error);
            })
            .finally(() =>{
                this.spinnerClosed=false
            })
        },
        createDialogsIDs:function(alerts){
            const filtered = alerts.filter((alert) => {
                return (                    
                    Array.isArray(alert.entries) &&
                    alert.entries.length > 0
                );
            });
            this.dialogs = filtered.reduce((dialogs, alert) => {
                dialogs[alert.id] = false;
                return dialogs;
            }, {});

        },
        filterAndDivideAlerts:function(data){
            this.openAlerts=data.filter((alert) => alert.user_id === null);
            this.alerts_assigned=data.filter((alert) => alert.user_id !== null);

            this.openAlerts.sort((a, b) => a.alarm.priority - b.alarm.priority);
            this.alerts_assigned.sort((a, b) => a.alarm.priority - b.alarm.priority);
        },
        colorPriority: function (priority) {
            switch (priority) {
                case 1:
                    return "error";
                case 2:
                    return "yellow darken-3";
                case 3:
                    return "primary";
                default:
                    return "black";
            }
        },
        getFormattedText(originalText, text, part) {
            if (typeof originalText === "string") {

                const parts = originalText.split(text);
                if (parts.length > 1) {
                    return parts[part].trim();
                }
            }
            return "";
        },
        nameCapitalize(texto) {
            return  String(texto).toLowerCase();
        }    
    },
    watch: {
        showBtnFilter(newValue, oldValue) {
            if (newValue.length == 0 && oldValue.length > 0) {
                this.getClosedAlerts()
            }
        }
    },

    components: {
        CardAlert,
        BaseDate,
        BaseSelect,
        SelectSearch,
        BaseSelectMult
    },
};
</script>
<style scoped>
    .alerts-tab{
        min-height: 400px;
    }
    .scrollableAlerts {
  height: 80vh;
  overflow-y: auto; 
  overflow-x :hidden;
  border: 1px solid #ccc;
  padding: 10px;
}
</style>