import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuex from 'vuex';
import store from './store';
import Rollbar from 'vue-rollbar';

Vue.filter('formatDateSimple', function(time, myTimeZone) {
  const meses = [
    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
  ];
  let fecha = ''
  if (myTimeZone != undefined) {
    fecha = new Date(time.toLocaleString("en-US", { timeZone: myTimeZone }));
  } else {
    fecha = new Date(time);
  }
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = meses[fecha.getMonth()];
  const año = fecha.getFullYear().toString();
  const hora = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  return `${dia}/${mes}/${año} ${hora}:${minutos}`;
})
Vue.filter('formatDate', function(time, myTimeZone) {
  let fecha = ''
  if (myTimeZone != undefined) {
    fecha = new Date(time.toLocaleString("en-US", { timeZone: myTimeZone }));
  } else {
    fecha = new Date(time);
  }
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const año = fecha.getFullYear().toString();
  const hora = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  const segundos = fecha.getSeconds().toString().padStart(2, '0');
  return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
})
Vue.filter('formatTimeZone', function(time, myTimeZone) {
  let fecha2 = new Date(time);
  let fecha = new Date(fecha2.toLocaleString("en-US", { timeZone: myTimeZone }));
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const año = fecha.getFullYear().toString();
  const hora = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  const segundos = fecha.getSeconds().toString().padStart(2, '0');
  return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
})
Vue.filter('formatHour', function(time) {
  const fecha = new Date(time);
  const hora = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  return `${hora}:${minutos}`;
})

Vue.config.productionTip = false

// Vue.prototype.$URL="https://gladyapi.glya.co/";
Vue.prototype.$URL=process.env.VUE_APP_DOMAINURL;

Vue.use(Rollbar,{
  accessToken:process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  source_map_enabled: true,
  environment: process.env.VUE_APP_ENVIRONMENT,
  payload: {
    client: {
        javascript: {
          code_version: '1.0'
        }
    }
  }
});


Vue.use(Vuex)


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
