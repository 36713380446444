<template>
    <div>
        <TitleView title="Gestión de órdenes"></TitleView>
        <v-card class="mt-4">
            <v-tabs v-model="tab" background-color="indigo" centered dark >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1" @click="getorders(status.tab1)">
                    <span class="pl-5 pr-5"><v-icon small class="pr-2 pb-1">mdi-text-box-multiple</v-icon> No Gestionadas</span>
                </v-tab>
                <v-tab href="#tab-2" @click="getorders(status.tab2)">
                    <span class="pl-5 pr-5"><v-icon small class="pr-2 pb-1">mdi-folder-open</v-icon> Programadas</span>
                </v-tab>
                <v-tab href="#tab-3" @click="getorders(status.tab3)">
                    <span class="pl-5 pr-5"><v-icon small class="pr-2 pb-1">mdi-folder</v-icon> Cerradas</span>
                </v-tab>
            </v-tabs>
        
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <v-card flat>
                        <v-card-text>
                            <div>
                                <v-row>
                                    <v-col>         
                                        <v-data-table :headers="headers" :items="tabs[0].orders" :server-items-length="tabs[0].total_items" :loading="spinnerClosed" class="elevation-1 GdiTable" item-key="name"
                                        :footer-props="{'items-per-page-options': [5, 10, 20], 'items-per-page-text': 'Alertas por página'}"
                                        @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                                            <template v-slot:[`item.created_at`]="{item}">
                                                <p>{{item.created_at|formatDate}}</p> 
                                            </template>                                    
                                            <template v-slot:[`item.order_type_name`]="{item}">
                                                <p>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip  v-bind="attrs"
                                                            v-on="on" class="pa-1 mr-1" :color="item.notify==true ? 'success' : 'grey'" label x-small text-color="white"> <v-icon x-small class="pl-0 pr-0"> mdi-{{item.notify==true ? 'bell' : 'bell-off'}} </v-icon></v-chip>
                                                        </template>
                                                        <span>
                                                            {{item.notify==true ? 'Notificaciones Activas' : 'Notificaciones Pausadas'}}
                                                        </span>
                                                    </v-tooltip>
                                                    <v-icon small>mdi-{{item.order_type==1 ? 'pill' : 'pulse'}} </v-icon>
                                                    {{item.order_type==0 ? 'Medición' : 'Medicamento'}} - {{item.clinical_element}}  {{item.medicine_name}}
                                                </p>
                                            </template>
                                            <template v-slot:[`item.actions`]="{item}">
                                                <v-btn @click="goOrder(item.id, item.patient.id)" color="indigo lighten-1" outlined small>{{item.none}}<v-icon class="mr-1" small>mdi-pencil</v-icon> Gestionar</v-btn> 
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>                                              
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                
                <v-tab-item value="tab-2">
                    <v-card flat>
                        <v-card-text>
                            <v-col>
                                <v-row class="d-flex justify-center pt-0">
                                    <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                                    <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                                        {{ filtersType[button]['label'] }}
                                    </v-btn>                               
                                </v-row>
                                <v-row class="mt-4 d-flex justify-center">
                                    <BaseSelectMult v-if="filtersType['type']['active']" v-model="filter.type" type="text" label="Tipo" list_type="tipo_orden" outlined="outlined" filter="filter"></BaseSelectMult>
                                    <v-btn v-if="showBtnFilter.length != 0" @click="toFilter" elevation="0" class="ml-5 mt-n1" :disabled="disableFilter" color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <div>
                                <v-row>
                                    <v-col>         
                                        <v-data-table :headers="headersClosed" :items="tabs[1].orders" :server-items-length="tabs[1].total_items" :loading="spinnerClosed" class="elevation-1 GdiTable" item-key="name"
                                        :footer-props="{'items-per-page-options': [5, 10, 20], 'items-per-page-text': 'Alertas por página'}"
                                        @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                                            <template v-slot:[`item.created_at`]="{item}">
                                                <p>{{item.created_at|formatDate}}</p> 
                                            </template> 
                                            <template v-slot:[`item.status_name`]="{item}">
                                                <v-chip :color="colorestado(item.status_name)" text-color="white">{{item.status_name}}</v-chip>
                                            </template>                                   
                                            <template v-slot:[`item.order_type_name`]="{item}">
                                                <p>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip  v-bind="attrs"
                                                            v-on="on" class="pa-1 mr-1" :color="item.notify==true ? 'success' : 'grey'" label x-small text-color="white"> <v-icon x-small class="pl-0 pr-0"> mdi-{{item.notify==true ? 'bell' : 'bell-off'}} </v-icon></v-chip>
                                                        </template>
                                                        <span>
                                                            {{item.notify==true ? 'Notificaciones Activas' : 'Notificaciones Pausadas'}}
                                                        </span>
                                                    </v-tooltip>
                                                    <v-icon small>mdi-{{item.order_type==1 ? 'pill' : 'pulse'}} </v-icon>
                                                    {{item.order_type==0 ? 'Medición' : 'Medicamento'}} - {{item.clinical_element}}  {{item.medicine_name}}
                                                </p>
                                            </template>
                                            <template v-slot:[`item.actions`]="{item}">
                                                <v-btn @click="goOrder(item.id, item.patient.id)" color="indigo lighten-1" outlined small>{{item.none}}<v-icon class="mr-1" small>mdi-pencil</v-icon> Gestionar</v-btn> 
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>                            
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-3">
                    <v-card flat>
                        <v-card-text>
                            <v-col>
                                <v-row class="d-flex justify-center pt-0">
                                    <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                                    <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                                        {{ filtersType[button]['label'] }}
                                    </v-btn>                               
                                </v-row>
                                <v-row class="mt-4 d-flex justify-center">
                                    <BaseSelectMult v-if="filtersType['type']['active']" v-model="filter.type" type="text" label="Tipo" list_type="tipo_orden" outlined="outlined" filter="filter"></BaseSelectMult>
                                    <v-btn v-if="showBtnFilter.length != 0" @click="toFilter" elevation="0" class="ml-5 mt-n1" :disabled="disableFilter" color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <div>
                                <v-row>
                                    <v-col>   
                                        <v-data-table :headers="headersClosed" :items="tabs[2].orders" :server-items-length="tabs[2].total_items" :loading="spinnerClosed" class="elevation-1 GdiTable" item-key="name"
                                        :footer-props="{'items-per-page-options': [5, 10, 20], 'items-per-page-text': 'Alertas por página'}"
                                        @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                                            <template v-slot:[`item.created_at`]="{item}">
                                                <p>{{item.created_at|formatDate}}</p> 
                                            </template> 
                                            <template v-slot:[`item.status_name`]="{item}">
                                                <v-chip :color="colorestado(item.status_name)" text-color="white">{{item.status_name}}</v-chip>
                                            </template>                                   
                                            <template v-slot:[`item.order_type_name`]="{item}">
                                                <p>
                                                    <v-icon small>mdi-{{item.order_type==1 ? 'pill' : 'pulse'}} </v-icon>
                                                    {{item.order_type==0 ? 'Medición' : 'Medicamento'}} - {{item.clinical_element}}  {{item.medicine_name}}
                                                </p>
                                            </template>
                                            <template v-slot:[`item.actions`]="{item}">
                                                <v-btn @click="goOrder(item.id, item.patient.id)" color="indigo lighten-1" outlined small>{{item.none}}<v-icon class="mr-1" small>mdi-eye</v-icon>Ver</v-btn> 
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>                            
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import AxiosServices from '@/services/AxiosServices'
import TitleView from '@/components/views/TitleView.vue'
import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";


export default {
    name: 'OrderIndex',
    mounted() {
        this.getorders(0)
    },
    data() {
        return {
            query: this.$route.query.q,
            tab: null,
            headers: [
                    { text: 'Paciente', value: 'patient.name'},
                    { text: 'Fecha', value: 'created_at' },
                    { text: 'Tipo', value: 'order_type_name'},
                    { text: 'Acciones', value: 'actions', active: true, sortable: false},
                ],
            headersClosed: [
                    { text: 'Paciente', value: 'patient.name'},
                    { text: 'Fecha', value: 'created_at' },
                    { text: 'Estado', value: 'status_name'},
                    { text: 'Tipo', value: 'order_type_name'},
                    { text: 'Acciones', value: 'actions', active: true, sortable: false},
                ],
            tabs:[{page:1, per_page:10, total_items:10, orders:[]},{page:1, per_page:10, total_items:10, orders:[]},{page:1, per_page:10, total_items:10, orders:[]}],
            orderlist: [],
            spinnerClosed:false,
            status:{tab1:0,tab2:1,tab3:2},
            tab_name:{tab1:'tab1',tab2:'tab2',tab3:'tab3'},
            actualtab:undefined,
            filter:{
                type:undefined
            },
            filtersType:{
                'type':{'active':false, 'label':'Tipo', 'filter':['type']},
            },
            totalItems: 10,
            per_page:10,
            page:1,
        }   
    },
    methods: {
        updatePage(page){
            this.tabs[this.actualtab].page = page
            // this.page = page   
            this.getorders(this.actualtab)
        },
        updateItemsPerPage(per_page){
            this.tabs[this.actualtab].per_page = per_page
            this.tabs[this.actualtab].page = 1

            // this.per_page = per_page
            // this.page = 1
                
            this.getorders(this.actualtab)
        },
        changeButton:function(button){
            if (this.filtersType[button]['active']){
                this.filtersType[button]['filter'].forEach(field => {
                    this.setDefaultFilter(field)
                })
            }
            this.filtersType[button]['active']= !this.filtersType[button]['active']
        },
        setDefaultFilter:function(nameField){    
                this.filter[nameField]=undefined
                this.getorders(this.actualtab)
        },
        selectedFilter(index) {
            this.buttonFilters[index].selected = !this.buttonFilters[index].selected;
        },
        toFilter(){
            this.getorders(this.actualtab)
        },
        
        getorders: function (status) {
            let params = this.buildParams(status)
            this.spinnerClosed = true
            this.orderlist=[]
            this.spinner = true
            AxiosServices.clientOrdersList(this.$store.state.user.client_id, params).then(res => {
                this.tabs[status].orders = res.data.data.data
                // this.orderlist= res.data.data.data
                this.tabs[status].total_items = res.data.data.total
                this.tabs[status].page = res.data.data.page
                // this.page = res.data.data.page

                this.sortArray(this.orderlist, status)
            }).catch(error => {
                console.log('el error trae', error);
            }).finally(() =>{
                this.spinnerClosed=false
            })             
        },
        buildParams:function (status) {
            let params = {page: this.tabs[status].page, per_page: this.tabs[status].per_page}
            status != undefined ? this.actualtab = status : status=this.actualtab
            if (status !== '' && status !== undefined) {
                params.filter = status.toString();
            }        
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] != '' || this.filter[field] == 0) {
                    params[field] = this.filter[field]
                }
            });
            return params
        },
        sortArray:function(array, status){
            array.sort(function(field1, field2){
                if (field1.created_at < field2.created_at) {
                    return 1;
                } else if (field1.created_at > field2.created_at) {
                    return -1;
                } else {
                    return 0;
                }
            })
            if (status==0) {
                array.reverse()
            }
            return array
        },
        goOrder:function(idOrder, idPatient){
            localStorage.setItem('idPatient', idPatient);
            this.$router.push({name:"orders",params:{order_id:idOrder,patient_id:idPatient}})
        },
        colorestado:function(estado){
            
            switch (estado){
                case "Cancelada":
                return "red"                   
                case "Completada":
                return "success"                    
                case "En ejecución":
                return "yellow darken-3"               
                case "No Gestionada":
                return "grey"                   
                case "Programada":
                return "primary"
                
            }
        },
    },

    watch: {
        //ESTE WATCH ES NECESARIO SOLO POR QUE EL CAMPO DE BUSQUEDA ESTA POR FUERA (SIDEBAR) SI VIENERA
        //DE ADENTRO NO HUBIESE NECESIDAD
        $route() {
            this.query = this.$route.query.q
            this.getpatients()
        }, 
    },
    computed:{
        show_list: function(){
            let value=false
            if(this.orderlist.length > 0){
                value=true
            }
            return value
        },
        showBtnFilter() {
            let select = []
            Object.keys(this.filtersType).forEach(element => {
                if (this.filtersType[element]['active']) {
                    select.push(this.filtersType[element]['filter'])
                }
            });
            return select.flat()
        },
        disableFilter() {
            let disable = []
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] == '' || this.filter[field] == null) {
                    if (this.filter[field]!==0){
                        disable.push(field)
                    }
                }
            });
            return disable.length > 0
        }
    },
    components: {
        TitleView,
        BaseSelectMult
    }
}
</script>
