<template>
  <div>
    <BasicDataPatient :patient_id="patient_id" :editform="false"></BasicDataPatient>
    <v-card class="mx-auto mt-3">
      <v-card-text class="text-body-1 pa-3">
        <v-row>
          <v-col cols="12" class="pa-5">
            <v-row class="d-flex justify-space-between pr-3">
              <v-col>
                <h3 class="pl-3">
                  Descripción de la orden /
                  <v-chip :color="colorestado(order.status_name)" class="white--text">
                    {{ order.status_name}} {{order.status==2 ? order.progress : ''}}</v-chip
                  > 
                </h3>
              </v-col>
              <v-col cols="12" sm="auto">
                <div v-if="order.status == '0'">
                  <FormOrder
                    :patient_id="patient_id"
                    :order_id="order_id"
                    :createform="false"
                    showicon
                    @updateOrders="reload_page()"
                  ></FormOrder>
                </div>
                <div v-if="order.status == '1' || order.status == '2'" >
                  <v-btn @click="notification" depressed :color="order.notify ? 'error' : 'green lighten-1'" class="white--text" small >
                    <span>{{order.notify ? 'Pausar Notificaciones' : 'Activar Notificaciones'}}</span><v-icon right dark> mdi-{{order.notify ? 'bell-off' : 'bell'}}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="order-description">
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Tipo de orden: </label>
                <p class="mb-0">
                  <span
                    v-if="order.order_type_name == '' || order.order_type_name == null"
                    ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                      Dato Incompleto</v-chip
                    >
                  </span>
                  <span v-else>{{ order.order_type_name }}</span>
                </p>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <section>
                  <label class="indigo--text text-subtitle-2">{{order.order_type == 0 ? 'Tipo de Toma:' : 'Medicamento:'}} </label>
                  <p class="mb-0">
                    <span v-if="(order.clinical_element == '' || order.clinical_element == '') && (order.medicine_name=='' || order.medicine_name==null)">
                      <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                        Dato Incompleto
                      </v-chip>
                    </span>
                    <span>{{ order.clinical_element }} {{order.medicine_name}}</span>
                  </p>
                </section>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Duración: </label>
                <p class="mb-0">
                  <span v-if="order.permanent == true"
                    ><v-chip class="pa-0 pl-3 pr-3" color="success" small outlined>
                      Permanente</v-chip
                    >
                  </span>
                  <span v-else>{{ order.during }} días (calendario)</span>
                </p>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Fecha de inicio: </label>
                <p class="mb-0">
                  <span v-if="order.start_at == '' || order.start_at == null"
                    ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                      Dato Incompleto</v-chip
                    >
                  </span>
                  <span v-else>{{ order.start_at | formatTimeZone(order.time_zone_patient) }}</span>
                </p>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Responsable: </label>
                <p class="mb-0">
                  <span v-if="order.user.name == '' || order.user.name == null"
                    ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                      Dato Incompleto</v-chip
                    >
                  </span>
                  <span v-else>{{ order.user.name }}</span>
                </p>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Notificaciones: </label>
                <p class="mb-0">
                  <span>
                    <v-chip class="pa-0 pl-3 pr-3" :color="order.notify == true ? 'success' : 'error'" small outlined>
                      {{order.notify == true ? 'Activas' : 'Pausadas'}}</v-chip>
                  </span>
                  
                </p>
              </v-col>
              <v-col cols="12" class="pt-1 pb-1">
                <label class="indigo--text text-subtitle-2">Descripción: </label>
                <p class="mb-0">
                  <span v-if="order.to_human == '' || order.to_human == null"
                    ><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                      Dato Incompleto</v-chip
                    >
                  </span>
                  <span>
                    <span v-if="order.permanent == true">{{ getFormattedText(order.to_human, "durante") }}, por tiempo
                      indefinido.</span>
                    <span v-else>{{ order.to_human }}.</span
                    >
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-if="order.status_name != 'Cancelada'">
      <v-row class="d-flex justify-center ma-2">
        <v-col cols="12" sm="6" md="4"  v-if="programable">
          <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog_order" persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" rounded color="success" block :disabled="order.status_name == 'Programada' || !permissions.manage_activities">
                      Programar Actividades
                    </v-btn>
                </template>
                <template v-slot:default>
                  <v-card class="pt-5">                        
                    <v-card-text class="mb-5">                                  
                      <v-row>
                        <v-col cols="12" class="pt-5 pb-0">
                          <p>Seleccione fecha y hora de inicio de la programación de actividades</p>
                        </v-col>
                          <v-col cols="12" sm="6" class="pt-0 pb-0">
                            <BaseDate v-model="order_date" label="Fecha de inicio" limitType="expiration" validador="required" :valueinit="order_date"></BaseDate>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-0">
                            <BaseSelectMult v-model="order_time" type="text" label="Hora de inicio" validador="required" list_type="horas"></BaseSelectMult>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-end">
                        <v-btn @click="close" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                            <v-icon right dark> mdi-close</v-icon>
                        </v-btn>
                        <v-btn @click=" orderUpdate" depressed color="green lighten-1" class="white--text" small :disabled="verror">
                          Programar Actividades<v-icon right dark> mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
            </v-dialog>
          
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-btn rounded color="warning" block @click="cancel" :disabled="!permissions.manage_activities ">
            Cancelar Orden
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="showcalendar">
      <v-col>
        <v-card>
          <h3 class="ma-5 pt-5">Calendario de Actividades</h3>
          <calendar-encounter type="order" :timeZone="order.time_zone_patient" :patient_id="patient_id" :order_id="order_id">xxx</calendar-encounter>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AxiosServices from "@/services/AxiosServices";
import FormOrder from "@/components/forms/FormOrder.vue";
import calendarEncounter from "@/components/views/CalendarEncounter.vue";
import profileF from "@/assets/perfilesvacf2.jpg";
import profileM from "@/assets/perfilesvacm2.jpg";
import profile from "@/assets/perfilesvac.jpg";
import calendario from "@/assets/calendario_act2.gif";
import BasicDataPatient from "@/components/views/BasicData.vue";
import BaseDate from '@/components/forms_items/BaseDate.vue'
import BaseSelectMult from '@/components/forms_items/SelectMultiple.vue'

export default {
  mounted() {
    this.get_order();
    this.getPatient();
  },
  data() {
    return {
      order: { user: { name: null }},
      // patient:{eapb:{}}
      start_at:null,
      dialog_order: false,
      order_date: undefined,
      order_time:'',
      profileF,
      profileM,
      profile,
      calendario,
      order_id: this.$route.params.order_id,
      patient_id: this.$route.params.patient_id,
      showcalendar:false,
      patient: {},
      oki: false,
      programable: false,
      permissions:{}
    };
  },
  props: {},
  methods: {

    formatTime:function(date,time){
      var date_only = date.split(" ")[0];
      var date_time = date_only + " " + time;
      return date_time
    },  
    notification(){
      let notify = {notify: !this.order.notify}
      AxiosServices.patientOrdersUpdate(this.patient_id, this.order_id, notify).then(res => {
        this.$store.dispatch("addNotification", res.data)
        this.get_order()
      }).catch(error => {
          console.log('el error es este',error);
      })
    },
    orderUpdate:function(){     
      this.start_at = this.formatTime(this.order_date, this.order_time) 
      let order = this.dialog_order==true ? {start_at:this.start_at} : this.order
      AxiosServices.patientOrdersUpdate(this.patient_id, this.order_id, order).then(res => {
        this.$store.dispatch("addNotification", res.data)
        this.$emit('updateOrders')
        if (this.dialog_order==true) {    
          this.programme()
        }
      }).catch(error => {
          console.log('el error es este',error);
      })
    },
    get_order: function () {
      AxiosServices.patientOrdersDetail(this.patient_id, this.order_id)
      .then((res) => {
        this.order = res.data.data;
        if (res.data.data.status == 0 && res.data.data.activities.length == 0) {
          this.programable = true;
        } else {
          this.showcalendar=true
        }
      }).catch((error) => {
        if (error.code !='ECONNABORTED') {
          this.$store.dispatch("addNotification", error.response.data);
          this.$store.dispatch("render404");          
        }
      });
    },
    reload_page: function () {
      this.get_order()
      this.getPatient()
    },
    getPatient: function () {
      AxiosServices.patientDetail(this.patient_id).then((res) => {
        this.patient = res.data.data;
        this.permissions = res.data.data.permissions;
      }).catch((error) => {
        if (error.code !='ECONNABORTED') {
          this.$store.dispatch("addNotification", error.response.data);
          this.$store.dispatch("render404");          
        }
      })
    },
    close: function (){
      this.order_date= undefined
      this.order_time=''
      this.dialog_order=false
    },
    programme () {
        AxiosServices.createActivitiesByPatientOrder(this.patient_id, this.order_id).then(
          (res) => {
            this.get_order()
            window.location.reload()
            if (res.data.success == "true") {
              this.oki = true;
              window.location.reload()
            }
          },
          this.dialog_order = false
        ).catch(error => {
          console.log("el error es ", error);
          this.$store.dispatch("addNotification", error.response.data);
        })
    },
    cancel: function () {
      this.showcalendar = false;
      this.order.status = 3;
      this.order.status_name = "Cancelada";
      let order = {order : {status : 3 } }
      AxiosServices.patientOrdersUpdate(this.patient_id, this.order_id,order)
      .then(res =>{
        this.$store.dispatch("addNotification", res.data);
        this.reload_page()
      })
      .catch(error =>{
        this.$store.dispatch("addNotification", error.response.data);
      })      
    },
    colorestado: function (estado) {
      switch (estado) {
        case "Cancelada":
          return "red";
        case "Completada":
          return "success";
        case "En ejecución":
          return "yellow darken-3";
        case "No Gestionada":
          return "grey";
        case "Programada":
          return "primary";
      }
    },
    getFormattedText(originalText, text) {
      if (typeof originalText === "string") {
        const parts = originalText.split(text);
        if (parts.length > 1) {
          return parts[0].trim();
        }
      }
    },
  },
  components: {
    FormOrder,
    calendarEncounter,
    BasicDataPatient,
    BaseDate,
    BaseSelectMult
  },
  computed: {
    verror: function () {
      var show = false;
      if (this.$store.state.form.reserror) {
        show = true;
      }
      return show;
    },
  },
};
</script>
<style>
.order-description div {
  border: 1px solid rgb(197, 197, 197);
}
.order-description {
  padding: 10px;
}
.header-order {
  position: relative;
}
.contenedor-imagen {
  max-width: 100%;
  overflow: auto;
}
.calendar {
  display: block;
  width: 100%;
  max-width: 1100px;
  height: auto;
}
@media (max-width: 600px) {
  .btn-edit-order {
    position: absolute;
    top: 1px;
    right: 1px;
  }
  .btn-edit-order .text-btn {
    display: none;
  }
}
</style>
