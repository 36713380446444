<template>
        <v-select :items="datasearch" item-text="key" item-value="value" class="inputglya" :outlined="outlined" :value="modelValue" :label="label_show"  v-model="valuey"
        @change="updateValue" :error-messages="nameErrors" :class="filter || element"
        @focus="campoSeleccionado = true"
        @blur="campoSeleccionado = false">
     </v-select>
</template>

<script>

    import axios from "axios"
    import AxiosServices from '@/services/AxiosServices'
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    export default {

        props:{
            label:{
                type:String,
                default:'simple select vacio'
            },
            variant:{
                type:String,
                default:''
            },
            modelValue:{
                type:[String, Number],
                default:''
            },
            opciones:{
                type:Array,
                default(){
                    return []
                }
            },
            list:{
                type:String
            },
            model:{
                type:String
            },
            country:null,
            valuex:{},          
            validador: {
                default: 'none'
            },
            outlined:{default:false},
            filter:{},
            element:{}
            
        },
        model: {
            prop: "value",
            event: "blur"
        },
        data() {
            return{
                datasearch: [],
                valuey: this.$attrs.value,
                fromvuex: this.$store.state.validations2[this.$props.validador],
                minl: 5,
                // id: Math.floor(Math.random() * 1000000),
                id: this.$props.label,
                showlabel: false,
                campoSeleccionado: false,
            }
        },
        watch:{
            country(newVal) {
                this.searchList(newVal)
                this.emitTypeId(newVal)
                this.reload_component()
            },
            valuex(newVal) {
                console.log(newVal);
                this.valuey= this.$attrs.value
            }
        },
        mounted() {
            this.searchList();
            this.eval_errors();
        },
        beforeMount () {
            if (this.label == "Tipo ID") {
                let country_id = parseInt(localStorage.getItem('user_country_id'))
                if (this.$props.country != null) {
                    country_id = this.$props.country
                }
                AxiosServices.listingsSimpleCountry('id_types_full',country_id).then(res => {
                    let idtypes = {}
                    for (let i = 0; i < res.data.data.length; i++) {
                        let idtype = res.data.data[i];
                        idtypes[idtype.id] = idtype.validation_regex;
                    }
                    this.$store.state.idTypeListRegex = idtypes
                    this.emitTypeId(this.$attrs.value) 
                    this.$emit('update_validations');
                })
            }
        },            
        methods: {
            updateValue(value) {
                value=this.valuey
                this.$emit('blur', value);
                this.$emit('changeglya');
                this.emitTypeId(value)                
                this.eval_errors()
            },
            reload_component(){
                this.valuey =  undefined
                this.eval_errors()
            },
            startIdType: function(value){
                this.searchIdTypes(value)
                var regular = this.$store.state.idTypeListRegex[value]
                this.$store.dispatch("putValidationNid", regular)

            },
            emitTypeId:function(value){
                if (this.label == "Tipo ID") {
                    this.searchIdTypes(this.country)
                    if (value !=0 ){
                        var regular = this.$store.state.idTypeListRegex[value]
                        if (regular != undefined) {
                            this.$store.dispatch("putValidationNid", regular)
                        }
                    } else {
                        this.searchIdTypes(this.country)
                    }
                }
            },
            searchIdTypes: async function (country_id) {
                await AxiosServices.listingsSimpleCountry('id_types_full',country_id).then(res => {
                    let idtypes = {}
                    for (let i = 0; i < res.data.data.length; i++) {
                        let idtype = res.data.data[i];
                        idtypes[idtype.id] = idtype.validation_regex;
                    }
                    this.$store.state.idTypeListRegex = idtypes
                })
            },
            changeglya() {
                this.$emit('changeglya');
            }, 
            set_label: function () {
                this.showlabel = true
                return this.showlabel
            },
            searchList: function () {
                if (this.country !== null) {
                    AxiosServices.listingsSimpleCountry(this.list,this.country).then(res => {
                    this.datasearch = this.setList(res.data.data)
                    }).catch(error => {
                        console.log('el error trae', error);
                    })  
                }else{
                    AxiosServices.listingsSimple(this.list).then(res => {
                        this.datasearch= this.setList(res.data.data)
                    }).catch(error => {
                        console.log('el error trae', error);
                    })             
                }
            },
            setList:function(data){
                const datasearch=[]
                for(let item in data){
                    let temp={}
                    temp.value=data[item]
                    temp.key=item                    
                    datasearch.push(temp)
                }
                return datasearch
            },
            searchList2: function () {
                axios.get(`${this.$URL}parameters/simpleSelect?const=${this.list}&model=${this.model}`).then(res => {
                    this.datasearch = res.data.results
                })
            },
            
            eval_errors: function(){
                if (this.nameErrors.length == 0) {
                    this.$store.dispatch("puterror", { [this.id]: 0 })
                } else {
                    this.$store.dispatch("puterror", { [this.id]: 1 })
                }
            }
        },
        computed: {
            eval_required_show: function () {
                return this.$store.state.show_required
            },
            label_show: function () {
                let label = this.$props.label
                if (this.showlabel) {
                    label = `${this.$props.label}${'*'}`
                }
                return label
            },
            nameErrors() {
                let errors = []
                if (!this.$v.valuey.required && this.fromvuex['required'] !== undefined) {
                    if (this.eval_required_show) {
                        errors.push('¡El campo es requerido!')
                    } else {
                        errors.push(' ')
                    }
                    this.set_label()
                }               
                return errors
            }
        },
        mixins: [validationMixin],
        validations: function () {
            if (this.minl > 0) {
                return {
                    valuey: eval(this.fromvuex)
                }
            }
            return {
                x: {
                    required
                }
            }
        }



    }

</script>

<style>



</style>

