<template>
    <div>
        <v-container class="container-login">

          <v-col cols="12" class="login-logo d-flex align-start">
            <img src="../../assets/glyapxm-01.svg" alt="gdi" width="180">
          </v-col>
          <v-row class="height-row">
            <v-col cols="12"  md="7" class="align-end _order-2">
              <v-row>
                <v-col cols="12">
                  <div class="frase-inicio">
                    <h1>Llevamos la atención en el hogar al siguiente nivel </h1>
                    <p>Transforma la experiencia de tus pacientes, ofrece servicios de atención domiciliaria de última generación con nuestra tecnología.</p>
                </div>
                </v-col>
                <v-col class="boton-url">
                    <v-btn outlined color="white" dark>
                        <a href="https://www.glya.co/"  target="_blank" class="btn bg-light rounded-pill">Ir a www.glya.co</a>
                    </v-btn>
                    
                </v-col>
                <v-col class="redes">
                    <a href="https://www.facebook.com/glya.co" class="ml-3" target="_blank" ><v-icon>mdi-facebook</v-icon></a>
                    <a href="https://www.instagram.com/glya.co" class="ml-3" target="_blank" ><v-icon>mdi-instagram</v-icon></a>
                    <a href="https://co.linkedin.com/company/glya" class="ml-3" target="_blank" ><v-icon>mdi-linkedin</v-icon></a>
                </v-col>
            </v-row>
            </v-col>
            <v-col cols="12"  md="5" class="center _order-1">
              <v-col cols="10" sm="6" md="8">
                <v-card>
                  <v-card-text class="center">  
                      <h1 class="title-login mg-b mg-t">Iniciar sesión</h1>       
                      <v-col cols="10">
                        <BaseInput v-model="user.email" type="email" label="Correo Electronico" validador="emailRequired" outlined="outlined" login="login"></BaseInput>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="user.password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="show ? 'text' : 'password'"
                          name="input-10-2"
                          label="Contraseña"
                          value=""
                          outlined
                          placeholder="Contraseña"
                          class="input-group--focused login logintext"
                          @click:append="show = !show"
                          v-on:keyup.13="$store.dispatch('loginBack', user)"
                        ></v-text-field>   
                      </v-col>
                      <v-col cols="10 mg-t-negative">
                        <h5 class="alert-login mg-b">{{ $store.state.errorlogin }}</h5>
                        <!-- <v-btn class="boton-iniciosesion mg-b" block :disabled="user.email.length < 1 || user.password.length < 1" @click="login_prueba">
                            <b>Iniciar sesión</b>
                        </v-btn> -->

                        <v-btn v-if="user.email.length<1 || user.password.length<1" class="boton-iniciosesion mg-b" block disabled>
                          <b>Iniciar sesión</b>
                        </v-btn>
                        <v-btn v-else class="boton-iniciosesion mg-b" block  v-on:keyup.13="$store.dispatch('loginBack', user)" @click="$store.dispatch('loginBack', user)">
                          <b>Iniciar sesión</b>
                        </v-btn>
                        <div class="ghost">{{ redirect }} {{ $route.name }}</div>
                      </v-col>
                  </v-card-text>
                </v-card>
                <!-- <v-btn block color="primary" dark class="mt-5">
                  <v-icon class="pr-5">mdi-microsoft</v-icon>
                  Iniciar con Microsoft
                </v-btn>
                <v-btn block color="error" class="mt-5">
                  <v-icon class="pr-5">mdi-google</v-icon>
                  Iniciar con Google
                </v-btn> -->
              </v-col>
            </v-col>
          </v-row>

        </v-container>
    </div>
</template>
<script>
import BaseInput from '@/components/forms_items/BaseInput.vue'
export default {
  mounted() {
    this.$store.commit('SET_LAYOUT', 'ClearLayout')
  },

  data() {
    return {
      layout: this.$store.state.layout,
      recarga: false,
      recargaRealizada: false,
      user: { email: "", password: "" },
      show: false,
      rules: {
        required: value => !!value || 'Contraseña requerida',
        min: value => value.length >= 8 || 'Mínimo 8 carácteres'
      },
    }
  },
  methods: {
    loginin: function () {
      this.$router.push({ name: 'dashboard' }).catch(() => { })
    },    
  },
  components: {
    BaseInput
  },
  computed: {
    redirect: function () {
      var redirect = false
      if (this.$store.getters.token.length > 0) {
        redirect = true
        this.loginin()
      }
      return redirect
    }
  }

}
</script>
<style scoped>
/* .logintext > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
  margin-left: 18px;
} */
    
  body{
    background: rgb(49, 1, 62);
  }
    .container-login{
    background-image:linear-gradient(rgba(6, 53, 142, 0.63), rgb(49, 1, 62)),  url(../../assets/wall3.jpg)!important;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(38, 1, 62) ;
    background-position: left;
    height: auto;
    min-height: 100vh;
    width: 100%;
    /* overflow-x:scroll;
    overflow-y:hidden; */
}

.box_inicio_sesion {
  width: 350px;
  background-color: white;
  padding: 40px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 20px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 20px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 20px -11px rgba(0, 0, 0, 0.75);
  color: rgb(54, 54, 54)
}

.box_inicio_sesion h4 {
  font-weight: 600
}

.container-login {
  position: absolute;
  top: 0px;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
  max-width: 5000px;
}

.inicio_sesion {
  position: absolute;
  bottom: 30px;
}

.login-logo {
  text-align: initial;
  padding-top: 20px;
  padding-bottom: 20px;

}

.frase-inicio {
  border-left: 3px solid white;
  max-width: 600px;
  padding-left: 25px;
  height: auto;
  margin-bottom: 40px
}

.frase-inicio h1,
.frase-inicio p {
  position: relative;
  animation-name: moveToRight;
  animation-delay: 0s;
  animation-duration: 5s;
  color: #fff;
  cursor: auto;
}

.frase-inicio h1 {

  font-size: 40px;
  font-weight: 400;

}

.frase-inicio p {

  font-size: 20px;
  font-weight: 100;
}

@keyframes moveToRight {
  0% {
    opacity: 0;
    left: -20px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

/* .box_inicio_sesion .form-check{
  padding-left: 0
}

.box_inicio_sesion .form-check label {
  font-weight: 400
}

.box_inicio_sesion a {
  display: flex;
justify-content: center;
} */

.redes .v-icon {
  padding: 5px 5px;
  background-color: white;
  color: rgb(124, 119, 176);
  border-radius: 30px;
  margin-left: 20px;
}

.boton-url a {
  padding: 0.15rem 2rem !important;
  color: white !important;
}

.theme--light.boton-iniciosesion.v-btn--has-bg {
  background-color: #0d179d !important;
    color: white !important;
}

.center{
  display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.height-row{
  min-height: 65vh;
}
.align-end{
  display: flex;
  align-self: flex-end;
  padding: 30px;
}
.mg-b{
margin-bottom: 20px;
}
.mg-t{
  margin-top: 20px;
}
.mg-t-negative{
  margin-top: -30px;
}
.alert-login{
  color: #f76262;
  text-align: center;
}
</style>