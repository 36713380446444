<template>
    <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="color" v-bind="attrs" v-on="on" dark>
                {{modal_name}} &nbsp; <v-icon>{{icon}}</v-icon> 
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card>
                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{ modal_name }}</v-toolbar>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" class="mt-5">
                            <div>
                                <v-row>
                                    <v-col cols="4" md="2" class="pt-0 pb-0">
                                        <BaseCheckbox v-model="supply.active" color="indigo" positive="Si" negative="No" label="¿Activo?">
                                        </BaseCheckbox>
                                    </v-col>
                                    <v-col cols="8" md="5" class="pt-0 pb-0">
                                        <BaseSelect v-model="supply.type" type="text" model="supply" list="Type" label="Tipo de Insumo" :valuex="supply.type" validador="required">
                                        </BaseSelect>
                                    </v-col>
                                    <v-col cols="12" md="5" class="pt-0 pb-0">
                                        <BaseInput v-model="supply.tradename" type="text" label="Nombre"  validador="required">
                                        </BaseInput>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pt-0 pb-0">
                                        <BaseInput v-model="supply.invima_register" type="text" label="Registro Invima" >
                                        </BaseInput>
                                    </v-col>                                    
                                    <v-col cols="12" md="6" class="pt-0 pb-0">
                                        <BaseDate v-model="supply.date_register" label="Fecha de registro Invima" :valueinit="supply.date_register"></BaseDate>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn @click="dialog = false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>
                        Cerrar
                        <v-icon right dark> mdi-close</v-icon>
                    </v-btn>
                    <v-btn @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small>
                        Guardar <v-icon right dark> mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>

import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import BaseDate from '@/components/forms_items/BaseDate.vue'
import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
import axios from 'axios'


export default {
    name: 'FormSupply',
    data() {
        return {
            supply: { type: "", tradename: "", invima_register: "", date_register :""},
            dialog: false
        }
    },
    mounted() {
        // this.get_batch()
    },
    methods: {
        
        saveAction: function () {  
            this.$store.dispatch("show_required")
            if (!this.verror) {
                this.supply.client_author == this.$store.state.user.client_id
                if (this.createsForm == true) {
                    axios.post(this.$URL + "supplies.json", this.supply).then(res => {
                        console.log('supply created', res.data);
                        this.$router.push({ name: 'supplies', params: { reload: true } }).catch(() => { })
                    })
                    this.dialog = false
                } else {
                    console.log('to here is the supplies patch');
                    // axios.patch(this.$URL + "batches/" + this.batch.id + ".json", this.batch).then(res => {
                    //     this.dialog = false
                    //     this.$router.push({ name: 'batches', params: { id: res.data, reload: true } }).catch(() => { })
                    // })
                }
            } else {
                this.$store.dispatch("show_required")
            }
        },
        seterror: function(){
            this.$store.dispatch("reseterror")
            this.$store.dispatch("putValidations")
        }
    },
    props: {
        createsForm: {
            type: Boolean,
            defaul: true
        },
        supply_id: {
            type: String
        },
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        },
        color: {
            type: String,
            default: "primary"
        },
        modal_name: {
            type: String,
            default: ""
        }

    },
    components: {
        BaseInput,
        BaseSelect,
        BaseDate,
        BaseCheckbox

    },
}

</script>
