<template>
    <div>
        <v-chip
            dark 
            :color="colorPriority(alarm.priority)" 
            :close="close"
            @click:close="send_id()">
            {{alarm.name}}
        </v-chip>
    </div>
</template>
<script>
    export default {

        data() {
            return {
                alarm: this.$props.alarm_item
            }
        },
        props: {
            alarm_id: {
                type: String,
                default: ""
            }, 
            close: {},
            alarm_item:{}
        },
        methods: {           
            send_id: function(){
                this.$emit('send_alarm_id', this.alarm.id)
            },
            colorPriority: function (priority) {
                switch (priority) {
                    case 1:
                        return "error";
                    case 2:
                        return "yellow darken-3";
                    case 3:
                        return "primary";
                }
            },
        }
    }
</script>