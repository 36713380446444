<template>
    <div>
        <h2 class="text-center indigo--text">Tu cumplimiento</h2>
        <div v-if="data[0].value=='N/A'">
            <v-col cols="12" class="ma-5 d-flex align-center flex-column message-no-data pa-0">
                <v-icon size="50">mdi-note-search</v-icon>
                <h3>No tienes órdenes asignadas<br>de toma de signos vitales<br> para medir tu cumplimiento</h3>
            </v-col>
        </div>
        <div v-else>
            <div ref="chartAdherence" class="graphAdh"></div>
            <div class="message-adherence" >
                <p class="text-center" :class="colormessage" >{{ mensaje }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5percent from "@amcharts/amcharts5/percent";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import AxiosServices from "@/services/AxiosServices";

    export default {
        name: "patientMeasureChart",
        data() {
            return {
                color: "0",
                colormessage: '',
                mensaje: '',
                reload: false,
                data: [
                    { adherencia: "Actividades Cumplidas", value: null },
                    { adherencia: "Actividades Incumplidas", value: null }
                ]
            };
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                try {
                const res = await AxiosServices.patientDetail(this.pid);
                this.data[0].value = res.data.data.measurements_adherence;
                this.data[1].value = 100 - this.data[0].value;
                if (this.data[0].value <= 50) {
                    this.color = "#EF5350";
                } else if (this.data[0].value > 50 && this.data[0].value <= 70) {
                    this.color = "#FFCA28";
                } else {
                    this.color = "#9CCC65";
                }
                this.message();
                this.$nextTick(() => {
                    this.createGraph();
                });
                } catch (error) {
                console.error("Error al obtener datos:", error);
                }
            },
            createGraph() {
                if (this.$refs.chartAdherence) {
                    let root = am5.Root.new(this.$refs.chartAdherence);
        
                    root.setThemes([am5themes_Animated.new(root)]);
            
                    let chart = root.container.children.push(
                    am5percent.PieChart.new(root, {
                        startAngle: 160,
                        endAngle: 380,
                        marginTop: 0,     
                        paddingTop: 0,       
                        marginBottom: 0  
                    })
                    );
                    let series0 = chart.series.push(
                    am5percent.PieSeries.new(root, {
                        valueField: "value",
                        categoryField: "adherencia",
                        startAngle: 160,
                        endAngle: 380,
                        radius: am5.percent(80),
                        innerRadius: am5.percent(50)
                    })
                    );
                    series0.set("colors", am5.ColorSet.new(root, {
                    colors: [
                        am5.color(this.color),
                        am5.color(0xE0E0E0),
                    ]
                    }));  
                    series0.ticks.template.set("forceHidden", true);
                    series0.labels.template.set("forceHidden", true);
                    series0.slices.template.set("tooltipText", "");       
                    chart.seriesContainer.children.push(
                    am5.Label.new(root, {
                        textAlign: "center",
                        centerY: am5.p100,
                        centerX: am5.p50,
                        text: `[bold fontSize:30px]${this.data[0].value}%[/]\n[fontSize:18px]de Cumplimiento[/]`
                    })
                    );   
                    series0.data.setAll(this.data);
                }
            },
            message() {
                switch (this.color) {
                case "#EF5350":
                    this.mensaje = "¡Las mediciones nos ayudan a cuidarte, tomártelas hace la diferencia!";
                    this.colormessage = 'red--text'
                    break;
                case "#FFCA28":
                    this.mensaje = "Recuerda tomar tus mediciones con más frecuencia si deseas un mejor resultado.";
                    this.colormessage = 'orange--text'
                    break;
                case "#9CCC65":
                    this.mensaje = "¡Muy bien! Sigue tomando tus mediciones con regularidad.";
                    this.colormessage = 'success--text'
                    break;
                }
            },
        },
        props: ["pid"]
    };
</script>

<style>
    .message-adherence {
        background-color: rgb(255, 255, 255);
        height: 59px;
        margin-top: -55px;
        z-index: 999 !important;
        position: absolute;
        width: 95%;
    }
    .graphAdh {
        min-height: 280px;
        height: 100%;
        margin-top: -40px;
        margin-bottom: 30px;
    }
</style>