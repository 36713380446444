<template>
  <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="indigo" v-bind="attrs" v-if="newalarm" v-on="on" @click="getdispatch" dark>
        Configurar alerta &nbsp; <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn color="success" small v-bind="attrs" v-else v-on="on" @click="getdispatch" dark>
        Editar <v-icon></v-icon>
      </v-btn>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{modal_name}}</v-toolbar>
        <v-card-text>
          <v-col cols="12" class="mt-5">
            <v-row>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseSelectMult v-model="alarm.type" type="text" label="Tipo de alerta" validador="required" list_type="tipo_alarma"></BaseSelectMult>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseInput v-model="alarm.name_only" type="text" validador="required" label="Nombre" element="element"></BaseInput>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseSelect v-model="alarm.clinical_element" type="text" list="clinical_elements_constants" model="patient"
                  validador="required" label="Elemento Clínico" element="element"></BaseSelect>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseSelectMult v-model="alarm.priority" type="text" label="Prioridad" validador="required" list_type="priodad_alarma"></BaseSelectMult>
              </v-col>
              <v-col cols="12" class="pb-0"><b>Define el rango valores para activar la alerta</b>
              <ul>
                <li>Asegúrate de que el valor inicial sea menor al valor final.</li>
              </ul></v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseInput v-model="alarm.minimum_condition" type="number" validador="numericVitalRequired"  label="Valor Inicial (Desde)" element="element"></BaseInput>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <BaseInput v-model="alarm.maximum_condition" type="number" label="Valor Final (Hasta)" validador="numericVitalRequired" element="element"></BaseInput>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-divider> </v-divider>

        <v-card-actions class="justify-end">
          <v-btn @click="close()" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
            <v-icon right dark> mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror">
            Guardar <v-icon right dark> mdi-check</v-icon>
          </v-btn>
          <v-btn v-else @click="create_alarm()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small>
            Guardar <v-icon right dark> mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import BaseInput from "@/components/forms_items/BaseInput.vue";
import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";
import BaseSelect from "@/components/forms_items/BaseSelect.vue";
import AxiosServices from "@/services/AxiosServices";

export default {
  name: "FormAlarm",
  data() {
    return {
      alarm: {
        id: null,
        name: "",
        clinical_element: "",
        // this.alarm.name = alarm_copy.name_only
        // this.alarm.name_only = alarm_copy.name
        minimum_condition: "",
        maximum_condition: "",
        priority: "",
        type: "",
      },
      dialog: false,
    };
  },
  mounted() {
    this.getAlarm()    
  },
  props: {
    createsForm: {
      type: Boolean,
      defaul: true,
    },
    icon: {
      type: String,
      default: "mdi-pencil-circle",
    },
    newalarm: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    modal_name: {
      type: String,
      default: "",
    },
    editAlarm: {
      type: Object,
    },
  },
  methods: {
    close: function(){
      this.dialog = false;
      this.getAlarm()
      this.$store.dispatch("reseterror")
    },
    clear_form: function(){
      this.dialog = false;
      this.$store.dispatch("reseterror")
      this.alarm = {id: null,name: "", clinical_element: "",minimum_condition: "",maximum_condition: "",prrity: "",type: ""}      
    },
    create_alarm: function () {
      if (this.newalarm) {     
        this.alarm.name=this.alarm.name_only
        AxiosServices.clientAlarmsCreate(this.alarm)
          .then((res) => {
            this.$store.dispatch("addNotification", res.data);
            this.$emit('reloadAlarms');
            this.clear_form()
          })
          .catch((error) => {
            console.log("el error es ", error);
            this.$store.dispatch("addNotification", error.response.data);
            this.clear_form()
          });
      } else {
        let alarm_update = {}
        let alarm = {}
        alarm['name'] = this.alarm.name_only
        alarm['clinical_element'] = this.alarm.clinical_element
        alarm['priority'] = this.alarm.priority
        alarm['minimum_condition'] = this.alarm.minimum_condition
        alarm['maximum_condition'] = this.alarm.maximum_condition
        alarm_update['alarm']=alarm
        AxiosServices.clientAlarmsUpdate(this.alarm.id,alarm_update)
        .then((res) => {
          this.$store.dispatch("addNotification", res.data);
          this.clear_form()
          this.$emit('reloadAlarms');          
        })
        .catch((error) => {
          console.log("el error es ", error);
          this.$store.dispatch("addNotification", error.response.data);
          this.clear_form()
        });
      }
    },
    getAlarm:function(){
      if (!this.newalarm) {
        this.alarm = this.editAlarm.item
        this.alarm.type = 1
        this.alarm.clinical_element = this.editAlarm.item.clinical_element_id
        this.alarm.maximum_condition = Number(this.editAlarm.item.maximum_condition)
        this.alarm.minimum_condition = Number(this.editAlarm.item.minimum_condition)
      }
    },

    getdispatch: function () {
      this.$store.dispatch("reseterror");
    },
  },
  computed: {
    verror: function () {
      var show = false;
      if (this.$store.state.form.reserror) {
        show = true;
      }
      return show;
    },
  },

  components: {
    BaseInput,
    BaseSelectMult,
    BaseSelect,
  },
};
</script>

<style>
.full-width {
  width: 100%;
}
.wrap-text {
  word-wrap: anywhere;
}
</style>
