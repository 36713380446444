<template>
    <div>
        <span class="ghost">{{ activeTab }}</span>
        <v-row>
            <v-col cols="12" class="pa-0">
                <SettingsTab></SettingsTab>
            </v-col>
            <!-- <v-col class="ml-5 mr-5">
                <v-row>
                    <v-col v-for="state in status" :key="state.value" cols="12" sm="auto">
                        <p class="text-body-2 mb-0"><v-icon class="pr-2" :color="state.color">mdi-circle</v-icon>{{state.name}}</p>
                    </v-col>
                </v-row>
            </v-col> -->
            <v-col cols="12">
                <span class="ghost">{{ log }}{{ timeout }}{{ start }}</span>
                <v-card>
                    <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1 pt-1 pb-2">
                            Alertas abiertas
                        </span>
                    </v-card-title>
                    <v-card-text class="text--primary pa-1">
                        <div v-if="alerts.filter(alert => alert.type === 0 && alert.status === 1).length > 0 ">
                            <v-row class="pa-3">
                                <v-col cols="12" md="6" lg="4" class="pa-2" v-for="alert in alerts.filter(alert => alert.type === 0 && alert.status === 1)" :key="alert.id">
                                    <CardAlert @reloadAlerts="getAlerts()"  :patient_id="$route.params.id" :alert_prop="alert"></CardAlert>                       
                                </v-col> 
                            </v-row>
                        </div>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="50">mdi-bell</v-icon>
                                <h3>No existen Alertas por gestionar</h3>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>            
            <v-col cols="12" >
                <v-card>
                    <v-card-text class="pa-2">
                        <v-col class="mt-3">
                            <v-row class="d-flex justify-center pt-0">
                                <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                                <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                                    {{ filtersType[button]['label'] }}
                                </v-btn> 
                            </v-row>
                        </v-col>
                        <v-row class="ma-4 mb-0 d-flex justify-center">
                            <v-col v-if="filtersType['type']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                <BaseSelectMult v-model="filter.alert_type" type="text" label="Tipo" list_type="tipo_alerta" outlined="outlined" filter="filter"></BaseSelectMult>
                            </v-col>
                            <v-col v-if="filtersType['clinical_element']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                <BaseSelect v-model="filter.clinical_element" type="text" label="Elemento Clínico" list="clinical_elements_constants" outlined="outlined" filter="filter"></BaseSelect>
                            </v-col>
                            <v-col v-if="filtersType['priority']['active']" cols="12" sm="6" md="4" class="pt-0 pb-0" >
                                <BaseSelectMult v-model="filter.priority" type="text" label="Prioridad" list_type="priodad_alarma" outlined="outlined" filter="filter"></BaseSelectMult>
                            </v-col>
                        </v-row>
                        <v-row v-if="showBtnFilter.length != 0" justify="center" class="pb-5 pt-0">
                            <v-col cols="12" sm="6" class="pb-5 pt-0">
                                <v-btn @click="toFilter" :disabled="disableFilter" block color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <div>
                            <v-data-table
                              :headers="headers"
                              :items="closedAlerts"
                              :server-items-length="totalItems"
                              :loading="spinnerClosed" 
                              class="elevation-1 GdiTable" 
                              item-key="name"
                              :footer-props="{
                                'items-per-page-options': [5, 10, 20],
                                'items-per-page-text': 'Alertas por página'
                              }"
                             
                              @update:page="updatePage"
                              @update:items-per-page="updateItemsPerPage"
                              
                            >
                                <template v-slot:[`item.description`]="{ item }">
                                    <p v-if="item.type==0" class="mb-0"> {{ item.measurement.clinical_element_name }} <v-icon>mdi-chart-timeline-variant</v-icon> ({{item.alarm.minimum_condition}}-{{item.alarm.maximum_condition}}) </p>
                                    <p v-if="item.type == 1" class="mb-0"> {{ getFormattedText(item.order.to_human, 'los días', 0) }} 
                                        <v-icon size="15px">mdi-clock</v-icon> {{item.activity.programmed_at | formatHour}}</p>
                                    <p v-if="item.type == 2" class="mb-0"> {{ getFormattedText(item.order.to_human, 'cada', 0) }} 
                                        <v-icon size="15px">mdi-clock</v-icon> {{item.activity.programmed_at | formatHour}}</p>
                                </template>
                                <template v-slot:[`item.measurement.value`]="{ item }">
                                    <p v-if="item.type==0" class="mb-0"> <v-icon :color="colorPriority(item.alarm.priority)" class="mr-2">mdi-flag</v-icon>  <v-chip outlined color="blue-grey">{{ item.measurement.value }} {{ item.measurement.clinical_element_unit }}</v-chip></p>
                                    <p v-if="item.type>0" class="mb-0"> <v-chip outlined color="blue-grey">Actividad Incumplida</v-chip></p>
                                </template>
                                <template v-slot:[`item.fecha`]="{ item }">
                                    <p v-if="item.type==0" class="mb-0"> {{ item.measurement.when | formatDate}} </p>
                                    <p v-if="item.type>0" class="mb-0"> {{ item.activity.updated_at | formatDate}} </p>
                                </template>
                                <template v-slot:[`item.status_name`]="{ item }">
                                    <p class="mb-0"> <v-chip outlined :color="item.status_name=='Activa' ? 'orange darken-1' : 'grey'"> {{ item.status_name}} </v-chip> </p>
                                </template>
                                <template v-slot:[`item.historial`]="{ item }">
                                    <v-dialog v-if="item.entries.length!=0" transition="dialog-bottom-transition" max-width="800" v-model="dialogs[item.id]" persistent>
                                        <template v-slot:activator="{ on, attrs }">            
                                            <v-btn color="indigo lighten-1" x-small dark  v-bind="attrs" v-on="on">
                                                <v-icon small class="mr-1">mdi-clipboard-text-clock</v-icon> Ver historial
                                            </v-btn>
                                        </template>
                                            <template v-slot:default>
                                                <v-card >
                                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Historial de gestión de alerta</v-toolbar>
                                                    <v-card-text class="pb-0">                                             
                                                        <v-col>
                                                            <v-card color="blue-grey lighten-5" class="pa-3 scrollable2" elevation="0">
                                                                <v-card-text  v-for="entry_alert in item.entries" :key="entry_alert.id" class="white pa-2 mb-2">
                                                                    <v-row>
                                                                        <v-col cols="12" class="pb-0">
                                                                            <p class="mb-0">
                                                                                <img src="../../assets/Glady-color.svg" alt="Glady" width="25" class="glady-message" v-if="entry_alert.user_name=='Glya'">
                                                                                <span class="text-subtitle-2 indigo--text text--lighten-2 pr-1 capitalize">{{nameCapitalize(entry_alert.user_name)}}:</span>{{entry_alert.description}}</p>
                                                                        </v-col>
                                                                        <v-col cols="12" class="pt-0">
                                                                            <p class="mb-0 text-caption text-right indigo--text">{{entry_alert.created_at | formatDate}}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                                <v-col class="text-center">
                                                                    <v-icon size="50px" color="grey">mdi-check-decagram</v-icon>
                                                                    <h3 class="grey--text">Alerta cerrada por <b>{{item.entries[item.entries.length - 1].user_name}}</b></h3>
                                                                </v-col>                               
                                                            </v-card>
                                                        </v-col>                                             
                                                    </v-card-text>
                                                    <v-card-actions class="d-flex justify-end">                                              
                                                        <v-btn @click="dialogs[item.id]=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Salir <v-icon right dark > mdi-close</v-icon> </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>     
                                    <p v-else class="grey--text mb-0 pt-0"><v-icon color="grey" x-small>mdi-note-off</v-icon> Sin historial </p>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AxiosServices from '@/services/AxiosServices'
    import CardAlert from './CardAlert.vue';
    import SettingsTab from "@/components/views/SettingsTab.vue"
    import BaseSelect from '@/components/forms_items/BaseSelect.vue'
    import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";

    export default {
    mounted() {
        this.getAlerts();
        this.getAlertsClosed();
        this.getFormattedText();
    },
    data() {
        return {
            dialogs:{},
            dialog_card: false,
            alerts: [],
            closedAlerts: [],
            test:[1],
            alarms: [],
            prueba:'',
            on: false,
            timenow: new Date(),
            times: {},
            timerId: null,
            timeout: {},
            log: false,
            headers: [
                { text: "Estado", value: "status_name" },
                { text: "Fecha", value: "fecha" },
                { text: "Descripción", value: "description" },
                { text: "Valor", value: "measurement.value" },
                { text: "Historial", value: "historial" },
            ],
            totalItems: 10,
            per_page:10,
            page:1,
            alarm_id:'',
            alarm_label:'',
            show_btn:true,
            disable_btn:true,
            filter:{
                alert_type:'',
                clinical_element:'',
                priority:'',
                client_id:this.$store.state.user.client_id,
                type:1
            },
            filtersType:{
                'type':{'active':false, 'label':'Tipo', 'filter':['alert_type']},
                'clinical_element':{'active':false, 'label':'Elemento Clínico', 'filter':['clinical_element']},
                'priority':{'active':false, 'label':'Prioridad', 'filter':['priority']}
            },
            spinnerClosed:false,
            status:[{color:'primary',name:'Prioridad Baja'}, {color:'yellow darken-3',name:'Prioridad Media'} , {color:'error',name:'Prioridad Alta'}  ],
        };
    },
    props: {
        model: {
            type: String,
            default: "Patient"
        },
        id: {
            type: String,
            default: ""
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    methods: { 
        changeButton:function(button){
            if (this.filtersType[button]['active']){
                this.filtersType[button]['filter'].forEach(field => {
                    this.setDefaultFilter(field)
                })
            }
            this.filtersType[button]['active']= !this.filtersType[button]['active']
        },
        updatePage(page){
            this.page = page
            this.getAlertsClosed()
        },
        updateItemsPerPage(per_page){
            this.per_page = per_page
            this.page = 1
            this.getAlertsClosed()
        },
        setDefaultFilter:function(nameField){
            this.filter[nameField]=''
        },
        toFilter: async function() {
            await this.buildParams()
            this.getAlertsClosed()
        },   
        buildParams:function () {
            let params = {page: this.page, per_page: this.per_page}
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] != '' || this.filter[field]==0){
                    params[field] = this.filter[field]
                }
            });
            return params
        },
        selectedFilter(index) {
            this.buttonFilters[index].selected = !this.buttonFilters[index].selected;
        },
        getAlerts: function () {
            AxiosServices.patientAlertsList(this.$route.params.id).then(res => {
                this.alerts = res.data.data.data
                this.alerts.reverse()
            }).catch(error => {
                if (error.code !='ECONNABORTED') {
                    this.$store.dispatch("addNotification", error.response.data);
                    this.$store.dispatch("render404");          
                }
            })
        },
        async getAlertsClosed() {
            let params = this.buildParams()
            this.spinnerClosed = true
            AxiosServices.patientAlertsList(this.$route.params.id, params).then(res => {
                this.page = res.data.data.page
                this.closedAlerts = res.data.data.data.filter(item => item.status !== 1)
                this.totalItems = res.data.data.total
                // this.totalItems = this.closedAlerts.length
            }).catch(error => {
                if (error.code !='ECONNABORTED') {                                                                                                                                                                                         
                    this.$store.dispatch("addNotification", error.response.data);
                    this.$store.dispatch("render404");          
                }
            })
            .finally(() =>{
                this.spinnerClosed=false
            })
        },
        createDialogsIDs:function(alerts){
            const filtered = alerts.filter((alert) => {
                return (                    
                    Array.isArray(alert.entries) &&
                    alert.entries.length > 0
                );
            });
            this.dialogs = filtered.reduce((dialogs, alert) => {
                dialogs[alert.id] = false;
                return dialogs;
            }, {});
        },         
        colorPriority: function (priority) {
            switch (priority) {
                case 1:
                    return "error";
                case 2:
                    return "yellow darken-3";
                case 3:
                    return "primary";
                default:
                    return "black";
            }
        },
        getFormattedText(originalText, text, part) {
            if (typeof originalText === "string") {
                const parts = originalText.split(text);
                if (parts.length > 1) {
                    return parts[part].trim();
                }
            }
            return "";
        },
        nameCapitalize(texto) {
            return  String(texto).toLowerCase();
        }    
    },
    computed: {
        showBtnFilter() {
            let select = []
            Object.keys(this.filtersType).forEach(element => {
                if (this.filtersType[element]['active']) {
                    select.push(this.filtersType[element]['filter'])
                }
            });
            return select.flat()
        },
        disableFilter() {
            let disable = []
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] == '' || this.filter[field] == null) {
                    if (this.filter[field]!==0){
                        disable.push(field)
                    }
                }
            });
            return disable.length > 0
        },
        activeTab: function () {
            if (this.$parent.$parent.isActive) {
                this.getAlerts();
            }
            return this.$parent.$parent.isActive;
        },
        start: function () {
            return this.timeout;
        },
        patient: function () {
            return this.$store.state.patient.info;
        }
    },
    watch: {
        showBtnFilter(newValue, oldValue) {
            if (newValue.length == 0 && oldValue.length > 0) {
                this.getAlertsClosed()
            }
        }
    },
    components: { 
        CardAlert, 
        SettingsTab,         
        BaseSelect,
        BaseSelectMult }
}
    </script>            
<style>
    .alert_generate{
        position: relative;
    }
    .alert_generate .v-alert__icon.v-icon {
    font-size: 22px;
}
.alert_generate .v-application--is-ltr .v-alert__icon {
    margin-right: 10px;
}
.alert_generate .v-alert__icon {
    align-self: center;
    height: 32px;
    min-width: 32px;
}
.alert-program > .v-icon.v-icon {
    padding-top: 8px;
}
.v-application--is-ltr .v-alert--border.alert_generate .v-alert__icon {
    margin-left: 12px;
}
.dateAlert{
    font-size: 13px;
    color: #2d2d2d;
}
.manage-alert{
    position: absolute;
    bottom: 0;
    right: 0;
}
.scrollable2 {
  max-height: 400px; 
  overflow-y: auto; 
  overflow-x :hidden;
  border: 1px solid #ccc;
  padding: 10px;
}
</style>