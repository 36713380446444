<template>
  <div>
    <v-row>
      <v-col>
        <TitleView title="Alertas"></TitleView>
      </v-col>
      <v-col class="d-flex align-end flex-column">
        <formAlarm modal_name="Configurar Alerta" @reloadAlarms="get_alarms" newalarm></formAlarm>
      </v-col>
    </v-row>

    <v-col>
      <v-data-table
        :headers="headers"
        :items="alarms"
        :server-items-length="totalItems"
        :loading="spinnerClosed" 
        class="elevation-1 GdiTable"
        item-key="id"
        :footer-props="{
          'items-per-page-options': [5, 10, 20],
          'items-per-page-text': 'Alertas por página'
        }"

        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
      >
        <template v-slot:[`item.type`]="{ item }">
          <span><v-icon>mdi-chart-timeline-variant</v-icon></span
          >{{ item.none }} Valor
        </template>
        <template v-slot:[`item.minimum_condition`]="{ item }">
          <v-chip class="pl-3 pr-3" :color="colorPrioridad(item.priority)" outlined>
            {{ item.minimum_condition }} - {{ item.maximum_condition }}</v-chip
          >
        </template>
        <template v-slot:[`item.priority`]="{ item }">
          <v-chip class="pl-3 pr-3" :color="colorPrioridad(item.priority)">
            {{ tipoPrioridad(item.priority) }}</v-chip
          >
        </template>
        <template v-slot:[`item.edit`]="item">
            <formAlarm @reloadAlarms="get_alarms" modal_name="Editar Alerta" :editAlarm="item" ></formAlarm>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>

<script>
import TitleView from "@/components/views/TitleView.vue";
import FormAlarm from "@/components/forms/FormAlarm.vue";
import AxiosServices from "@/services/AxiosServices";
export default {
  mounted() {
    this.get_alarms();
  },

  data() {
    return {
      headers: [
        { text: "Tipo", value: "type" },
        { text: "Nombre", value: "name" },
        { text: "Elemento Clínico", value: "clinical_element_name" },
        { text: "Rango", value: "minimum_condition" },
        { text: "Prioridad", value: "priority" },
        { text: "Editar", value: "edit" },
      ],
      dialog: false,
      alarms: [],
      on: false,
      spinnerClosed:false,
      totalItems: 10,
            per_page:10,
            page:1,
    };
  },
  methods: {
    updatePage(page){
      this.page = page
      this.get_alarms()
    },
    updateItemsPerPage(per_page){
        this.per_page = per_page
        this.page = 1
        this.get_alarms()
    },
    buildParams:function () {
        let params = {page: this.page, per_page: this.per_page}
        return params
    },
    get_alarms: function () {
      this.spinnerClosed = true
      let params = this.buildParams()
      AxiosServices.clientAlarmsList(params).then((res) => {
        this.alarms = res.data.data.data;
        this.totalItems = res.data.data.total
        this.page = res.data.data.page
      });
      this.spinnerClosed = false
    },    
    tipoPrioridad: function (prioridad) {
      switch (prioridad) {
        case 1:
          return "Alta";
        case 2:
          return "Media";
        case 3:
          return "Baja";
      }
    },
    colorPrioridad: function (prioridad) {
      switch (prioridad) {
        case 1:
          return "error";
        case 2:
          return "yellow darken-3";
        case 3:
          return "primary";
      }
    },
  },
  components: {
    TitleView,
    FormAlarm,
  },
};
</script>
