<template>
    <v-text-field
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        :label="label"
        :placeholder="placeholder || label"
        class="input-group--focused element"
        @click:append="show = !show"
        v-model="input"
        @input="updateValue"
        :error-messages="nameErrors"
        :solo="solo" :outlined="outlined" :filled="filled"
    
    ></v-text-field>
</template>
<script>

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
    name:'InputPassword',
    mounted() {
        this.eval_errors()
    },  
    created() {
        this.eval_errors()
    },
    data(){
        return {
            id:this.$props.label,
            show:false,
            input:this.$props.value,
            showlabel:false,
            minl: 8
        }
    },
    model:{
        prop:"value",
        event:"blur" 
    },
    props:{
        confirmation:{type:Boolean,default:false},
        placeholder:{default:'Contraseña'},
        label:{default:'Contraseña'},
        validador:{default:'password'},
        value: {}, 
        solo:{}, 
        outlined:{default:false}, 
        filled:{}
    },
    methods:{
        updateValue(value) {
            this.$emit('blur', value);
            this.eval_errors()
            if (!this.$props.confirmation){
                this.$emit('passwordVuex',value)
            }
        },
        set_label: function () {
            this.showlabel = true
            return this.showlabel
        },
        eval_errors: function () {
            if (this.nameErrors.length == 0) {
                this.$store.dispatch("puterror", { [this.id]: 0 })
            } else {
                this.$store.dispatch("puterror", { [this.id]: 1 })
            }
        },
        setValue:function(newValue){
            this.input=newValue
            this.$v.$touch()
            this.$v.$reset()
            this.eval_errors()
            return this.input
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setValue(newValue)
            }
        }
    },
    computed:{
        nameErrors(){
            let errors=[]
            if (!this.$v.input.minLength6 && this.validator['minLength6'] !== undefined ) {
                errors.push("Debe tener mínimo 6 caracteres")
            }
            if (!this.$v.input.minLength && this.validator['minLength'] !== undefined ) {
                errors.push("Debe tener mínimo 8 caracteres")
            }
            if (!this.$v.input.password && this.validator['password'] !== undefined) {
                errors.push("La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un dígito, un carácter especial (@$!%*?&) y tener una longitud mínima de 8 caracteres.")
            }
            if (!this.$v.input.required && this.validator['required'] !== undefined) {
                if (this.eval_required_show) {
                    errors.push('¡El campo es requerido!')                    
                } else {
                    errors.push(' ')
                }
                this.set_label()
            }
            if (!this.$v.input.password_confirmation && this.validator['password_confirmation'] != undefined){
                errors.push('las contraseñas no coinciden')
            }            
            return errors
        },       
        validator: function () {
            return this.$store.state.validations2[this.$props.validador]
        },        
        eval_required_show: function () {
            return this.$store.state.show_required
        },       
        label_show: function() {
            let label=this.$props.label
            if (this.showlabel) {
                label = `${this.$props.label}${'*'}`
            }
            return label
        }        
    },
    mixins: [validationMixin],
    validations: function () {
        if (this.minl > 0) {

            return {
                input: eval(this.validator)
            }

        }
        return {
            x: {
                required,
                minLength: minLength(this.minl)
            }
        }
    }


}
</script>