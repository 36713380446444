<template>
    <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="color" v-bind="attrs" v-on="on" dark>
                {{modal_name}} &nbsp; <v-icon>{{icon}}</v-icon> 
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card>
                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{modal_name}}</v-toolbar>
                <v-card-text>                    
                    <v-row >
                        <v-col cols="12" class="mt-5">                          
                            <v-row>
                                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                    <BaseSelect v-model="move.type" type="text" model="move" list="Type" label="Tipo de movimiento" :valuex="move.type" validador="required"></BaseSelect>
                                </v-col>
                                <v-col cols="12" md="4"  sm="6" class="pt-0 pb-0">
                                    <BaseInput v-model="move.amount" type="text" label="Cantidad (únidades)"  validador="quantity" ></BaseInput>
                                </v-col>
                                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                    <SelectSearch label="Seleccione Lote" model="stock" v-model="move.stock_id" validador="required" :valuex="move.stock_id"></SelectSearch>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <BaseInput v-model="move.notes" type="text" label="Notas"></BaseInput>
                                </v-col>
                            </v-row>                        
                        </v-col>                        
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn @click="dialog=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                        <v-icon right dark> mdi-close</v-icon>
                    </v-btn>
                    <v-btn @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror"> Guardar <v-icon right dark> mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>

import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
// import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
import SelectSearch from '@/components/forms_items/SelectSearch.vue';
// import BaseDate from '@/components/forms_items/BaseDate.vue'
import axios from 'axios'


export default {
    name: 'FormMoves',
    data() {
        return {
            move: {amount:"",type:"",notes:"",stock_id:""},
            dialog: false,
            // vaccine_type:"",
            // vaccines:{}
        }
    },
    props: {
        createsForm: {
            type: Boolean,
            defaul: true
        },
        stock_id:{
            type: String
        },
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        },
        color: {
            type: String,
            default: "primary"
        },
        modal_name: {
            type: String,
            default: ""
        }

    },  
    methods: {
        saveAction: function (){
            this.$store.dispatch("show_required")
            if (!this.verror) {
                this.move.author = this.$store.state.user.user_id
                axios.post(this.$URL + "moves.json", this.move).then(res => {
                    console.log('moveeee created', res.data);
                    this.$router.push({ name: 'moves', params: { reload: true } }).catch(() => { })
                    this.dialog = false
                })
            } else {
                this.$store.dispatch("show_required")
            }
        },
        seterror: function(){
            this.$store.dispatch("reseterror")
            this.$store.dispatch("putValidations")
        }
    },
    computed:{
        verror:function(){
                return this.$store.state.form.reserror
            },

    },

    components: {
        BaseInput,
        BaseSelect,
        // BaseCheckbox,
        SelectSearch,
        // BaseDate

    },
}

</script>

<style>
    .full-width{
        width: 100%;
    }
    .wrap-text{
        word-wrap: anywhere;
    }
</style>