<template>
    <div>
      <v-row>
        <v-col>       
            <TitleView title="Movimientos"></TitleView>
        </v-col>
            <v-spacer></v-spacer>
        <v-col class="d-flex align-end flex-column">
            <FormMoves :createsForm="true" icon="mdi-plus-circle-outline" color="indigo" modal_name="Crear Movimiento"></FormMoves> 
        </v-col>
        <v-col cols="12">
          <div>
            <v-card class="mx-auto pa-5">
              <v-row>
                <v-col cols="12" md="3" sm="6" class="pb-0 col-form-icon">
                  <BaseDate label="Fecha inicial" v-model="filter.date1" :outlined="true" filter="filter" :valueinit="filter.date1"></BaseDate>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="pb-0 col-form-icon">
                  <BaseDate label="Fecha final" v-model="filter.date2" :outlined="true" filter="filter" :valueinit="filter.date2"></BaseDate>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="pb-0">
                  <BaseInput type="text" label="Serial del lote" v-model="filter.serial" :outlined="true" filter="filter"></BaseInput>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="pb-0">
                  <BaseSelect  type="text" v-model="filter.type" model="move" list="Type" label="Tipo de movimiento" :outlined="true" filter="filter"></BaseSelect>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pa-0">
                  <v-col md="6">
                    <v-btn elevation="2" block color="indigo lighten-1" dark @click="get_moves()">
                      Filtrar
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </div>     
        </v-col>

        <v-col>           
          <v-data-table :headers="headers" :items="moves" class="elevation-1 GdiTable" item-key="name">
            <template v-slot:[`item.actions`]="{item}">
              <router-link :to="`stocks/${item.stock.id}`">
                <v-btn color="indigo lighten-1" outlined small> <v-icon class="mr-1" small>mdi-eye</v-icon> ver lote{{item.none}}</v-btn>
              </router-link>
            </template>
          </v-data-table>
        </v-col>

      </v-row>    
    </div>
</template>
<script>


import TitleView from '@/components/views/TitleView.vue'
import FormMoves from '@/components/forms/FormMove.vue'
import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import BaseDate from '@/components/forms_items/BaseDate.vue'
import axios from 'axios'

export default {
    name: 'IndexMoves',
    data() {
        return {            
            moves:[],
      headers: [

        { text: 'Tipo', value: 'type' },
        { text: 'Cantidad', value: 'amount' },
        { text: 'Notas', value: 'notes' },
        { text: 'Lote', value: 'stock.serial' },
        { text: 'Acciones', value: 'actions', active: true, sortable: false},
      ],
      filter:{date1:new Date(new Date().getTime() - 604800000).toISOString().substr(0, 10), date2:new Date(Date.now()).toISOString().substr(0,10),serial:"",type:""}
        }
    },    
    mounted() {
        this.get_moves()
    },
    methods: { 
        get_moves: function () {
            console.log("entro!");
            axios.get(this.$URL + `moves.json?date1=${this.filter.date1}&date2=${this.filter.date2}&serial=${this.filter.serial}&type=${this.filter.type}`).then(res => {
                console.log('llega esto', res.data)
                this.moves=res.data.moves
            })
        },
    },
    props: {

    },
    computed: {

    },

    components: {
        FormMoves,
        TitleView,
        BaseDate,
        BaseInput,
        BaseSelect,
    },
}

</script>
<style>
  .col-form-icon .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px 28px;
}
.col-form-icon .v-input__slot > .v-text-field__slot > .v-label{
    transform: translateY(-15px) translateX(-15px) scale(0.75);
}
</style>