<template>
    <div>
        <span class="ghost">{{ activeTab }} {{ reload_select }}</span>
        <v-col cols="12">
            <v-card>
                <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                    <span class="text-body-1">
                        Asignación de alertas
                    </span>
                    <v-spacer></v-spacer>
                    <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="800" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="!permissions.config_alerts" icon color="white" class="edit-carer" :disabled="!permissions.config_alerts">
                                <v-icon size="30px">mdi-pencil-circle</v-icon>
                            </v-btn>
                            <v-btn v-else icon color="white" class="edit-carer" v-bind="attrs" v-on="on">
                                <v-icon size="30px">mdi-pencil-circle</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                            <v-card>
                                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Asignar alertas</v-toolbar>
                                <v-card-text>
                                    <v-col cols="12" class="mt-1">
                                        <p>Agregue alertas de rangos para las mediciones.</p>
                                        <v-row justify="center" class="mt-3">
                                            <h4>{{ listen_alarm_id }}</h4>
                                            <v-col cols="9" class="pt-0 pb-0 col-search">
                                                <span v-if="!show_input">
                                                    <SelectSearch v-model="alarm_id" :valuex="alarmdefault" :reloader="reload_select" element="element" icon="mdi-magnify" outlined label="Alertas configuradas" model="alarms" @count_data="change_btn_active()" @count_nil="change_btn_inactive()" @label_search="get_searching"></SelectSearch>
                                                </span>
                                            </v-col>
                                            <v-col cols="12" class=" pt-5">
                                                <v-card class="pa-3" elevation="0" min-height="100" outlined>
                                                    <label class="label_tags color-text-glya">Alertas asignadas</label>
                                                    <v-row class="d-flex justify-start mt-1">
                                                        <div class="ma-1" v-for="alarm_show in alarms" :key="alarm_show.id">                                           
                                                            <ShowAlarm @send_alarm_id="deleteAlarmId" close :alarm_item="alarm_show"></ShowAlarm>
                                                        </div>
                                                    </v-row>                                                    
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="justify-end">
                                    <v-btn @click="close_action()" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                    <v-btn disabled v-if="disable_btn" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    <v-btn v-else @click="save_action()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-card-title>
                <v-card-text class="text--primary pa-1">
                    <v-col class="ml-5 mr-5 mt-1">
                        <v-row>
                            <v-col v-for="state in status" :key="state.value" cols="12" sm="auto">
                                <p class="text-body-2"><v-icon class="pr-2" :color="state.color">mdi-circle</v-icon>{{state.name}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="alarms.length>=1">
                        <v-row>
                            <v-col v-for="alarm in alarms" :key="alarm.id" cols="12" md="6" lg="4" class="mb-0 pa-1">
                                <v-alert outlined class="pa-1 pl-3 ma-2 alert-program" :icon="iconalerta(alarm.clinical_element_id)" :color="colorPriority(alarm.priority)" border="left">
                                    <div class="color-text-glya text--lighten-2">
                                        <p class="line-height-glya text-body-2 mb-0">
                                            <span :class="alarm.priority"><b>{{alarm.clinical_element_name}}</b></span> 
                                            <br>
                                            <span>{{ alarm.name }}</span>
                                        </p>  
                                        <p class="line-height-glya text-body-2 mb-0">
                                            <span>valores entre: <b>{{alarm.minimum_condition  }} y {{ alarm.maximum_condition }} {{alarm.clinical_element_unit}} </b></span>
                                            <!-- <span>{{ alert.condition }} <b>{{ alert.value }} {{ alert.unity }}</b></span>                                                 -->
                                        </p>
                                    </div>
                                </v-alert>
                            </v-col>

                        </v-row>
                    </v-col>
                    <div v-else>
                        <v-col class="d-flex align-center flex-column message-no-data pa-0">
                            <v-icon size="50">mdi-bell</v-icon>
                            <h3>No Hay alertas asignadas</h3>
                        </v-col>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>
<script>
import AxiosServices from '@/services/AxiosServices';
import SelectSearch from '../forms_items/SelectSearch.vue';
import ShowAlarm from './ShowAlarm.vue';
export default {
    name: 'SettingsTab',

    mounted() {
        this.getAlarms();
        this.setError();
        this.getPermission()
    },
    data() {
        return {
            dialog: false,
            dialog_card: false,
            alerts: [],
            test:[1],
            alarms: [],
            prueba:'',
            on: false,
            timenow: new Date(),
            times: {},
            timerId: null,
            timeout: {},
            log: false,
            headers: [
                { text: "Estado", value: "status_name" },
                { text: "Fecha", value: "fecha" },
                { text: "Descripción", value: "description" },
                { text: "Valor", value: "measurement.value" },
            ],
            alarm_id:'',
            alarm_label:'',
            show_btn:true,
            disable_btn:true,
            permissions:{},
            status:[{color:'primary',name:'Prioridad Baja'}, {color:'yellow darken-3',name:'Prioridad Media'} , {color:'error',name:'Prioridad Alta'}  ]
        };
    },
    props: {
        model: {
            type: String,
            default: "Patient"
        },
        id: {
            type: String,
            default: ""
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        save_action:function(){
            AxiosServices.patientUpdate(this.$route.params.id,this.setArray(this.alarms)).then(res => {
                this.$store.dispatch("addNotification", res.data)
                this.getAlarms()
            })
            this.dialog = false
        }, 
        close_action:function(){
            this.dialog = false
            this.getAlarms()
        },
        change_btn_inactive: function () {
            this.show_btn = false
        },
        change_btn_active: function () {
            this.show_btn = true
        },
        get_searching: function (label) {
            this.alarm_label = label
        },
        getAlerts: function () {
            AxiosServices.patientAlertsList(this.$route.params.id).then(res => {
                this.alerts = res.data.data
                this.alerts.reverse()
            }).catch((error) => {
                this.$store.dispatch("addNotification", error.response.data);
                this.$store.dispatch("render404");
            }); 
        },
        getAlarms: function () {
            AxiosServices.patientAlarmsList(this.$route.params.id).then(res => {
                this.alarms = res.data.data;
            }).catch((error) => {
                this.$store.dispatch("addNotification", error.response.data);
                this.$store.dispatch("render404");
            });
        },
        colorPriority: function (priority) {
            switch (priority) {
                case 1:
                    return "error";
                case 2:
                    return "yellow darken-3";
                case 3:
                    return "primary";
                default:
                    return "black";
            }
        },
        setError: function () {
            this.$store.dispatch("reseterror")
        },
        setArray: function (alarms_object) {
            let alarm_ids = []
            let patient = {}
            let alarms = { 'alarms': [] }
            alarms_object.forEach(alarm => {
                alarm_ids.push(alarm.id)
            });
            alarms['alarm_ids'] = alarm_ids
            patient['patient'] = alarms
            return patient
        },
        iconalerta: function (color) {
            switch (color) {
                case "programada":
                    return "mdi-bell-ring";
                case 0:
                    return "mdi-heart-pulse";
                case 1:
                    return "mdi-heart-pulse";
                case 2:
                    return "mdi-lungs";
                case "fr":
                    return "mdi-lungs";
                case 3:
                    return "mdi-pulse";
                case 4:
                    return "mdi-pulse";
                case 6:
                    return "mdi-water";
                case 5:
                    return "mdi-thermometer";
            }
        },
        getAlarm: function (alarm_id) {
            let data = {}
            AxiosServices.alarmsDetail(alarm_id).then(res => {
                data = res.data.data
                if (!this.alarms.includes(data)) {
                    this.addAlarm(data)
                }
            }).catch((error) => {
                this.$store.dispatch("addNotification", error.response.data);
                this.$store.dispatch("render404");
            })
        },
        addAlarm: function (alarm) {
            this.alarms.push(alarm)
            this.disable_btn = false
        },
        deleteAlarmId: function (alarm_id) {
            let temp = this.alarms
            function remove_alarm(obj) {
                if (obj.id != alarm_id) {
                    return true
                }
                return false
            }
            this.alarms = temp.filter(remove_alarm)
            this.disable_btn = false
        },
        set_show_btn: function () {
            this.show_btn = false
        },
        getPermission: function (){
            AxiosServices.patientDetail(this.$route.params.id).then(res => {
                this.permissions=res.data.data.permissions
                console.log(this.permissions.add_tag);
            }).catch(error => {
                console.log('el error es', error);
            })
        },
    },
    computed: {
        activeTab: function () {
            if (this.$parent.$parent.isActive) {
                this.getAlarms();
            }
            return this.$parent.$parent.isActive;
        },
        reload_select(){
            return !this.dialog
        },
        alarmdefault: function(){
            let id = ''
            if (this.alarm_id != null || this.alarm_id != undefined){
                id = this.alarm_id
            }
            return id
        },
        start: function () {
            return this.timeout;
        },
        patient: function () {
            return this.$store.state.patient.info;
        },
        verror: function () {
            return this.$store.state.form.reserror;
        },       
        show_input: function () {
            let show = false;
            return show;
        },
        listen_alarm_id:function(){
            if (this.alarm_id != '' && this.alarm_id != null){
                this.getAlarm(this.alarm_id)
            }
            return null
        }
    },
    components: { SelectSearch, ShowAlarm }
}
    </script>            
<style>
    .alert_generate{
        position: relative;
    }
    .alert_generate .v-alert__icon.v-icon {
    font-size: 22px;
}
.alert_generate .v-application--is-ltr .v-alert__icon {
    margin-right: 10px;
}
.alert_generate .v-alert__icon {
    align-self: center;
    height: 32px;
    min-width: 32px;
}
.alert-program > .v-icon.v-icon {
    padding-top: 8px;
}
.time_alert{
    margin-top: -4px;
    border-radius: 0px 0px 3px 3px;
    position: relative;
}
.v-application--is-ltr .v-alert--border.alert_generate .v-alert__icon {
    margin-left: 12px;
}
.dateAlert{
    font-size: 13px;
    color: #2d2d2d;
}
.manage-alert{
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>