<template>
    <div>
        <v-app-bar elevation="4" color="indigo">
            <v-container>
                <v-row class="d-flex justify-space-between">
                    <v-row>
                        <img src="../../assets/glyapxm-01.svg" alt="gdi" width="80">
                        <h2 class="white--text mt-4 ml-4 d-none d-md-flex">PORTAL DE PACIENTE</h2>
                    </v-row>
                    <v-tooltip bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="logOut" color="indigo lighten-1" elevation="0" dark small>
                                <v-icon dark v-bind="attrs" v-on="on"> mdi-export</v-icon> <span class="d-none d-md-flex pl-3"> Cerrar sesión</span>
                            </v-btn>
                        </template>
                        <span>Salir</span>
                    </v-tooltip>            
                </v-row>
            </v-container>
        </v-app-bar>
        
        <v-container class="container-portal pa-0 pa-sm-5 mt-5">
            <v-row>
                <v-col class="pa-2" cols="12">
                    <BasicData :patient_id="patient.id" :editform="false"></BasicData> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="7">
                    <v-card class="mx-auto mb-2">
                        <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                            <span class="text-body-1">
                                Información de contácto
                            </span>
                            <v-spacer></v-spacer>                        
                        </v-card-title>
                        <v-card-text class="text-body-2 pt-3">
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-home</v-icon>
                                <span v-if="patient.address == '' || patient.address == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.address}} 
                                    <span>{{ patient.neighborhood }} </span>                                
                                </span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-city</v-icon>
                                <span v-if="patient.city_name == '' || patient.city_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.city_name}}</span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-cellphone</v-icon>
                                <span v-if="patient.cellphone == '' || patient.cellphone == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>                            
                                <span v-else>{{patient.prefix}} {{ patient.cellphone }}</span>
                            </p>
                            <p class="mb-0">
                                <v-icon small class="mr-1 text--lighten-2 indigo--text">mdi-email</v-icon>
                                <span v-if="patient.email == '' || patient.email == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.email}}</span>
                            </p>
                        </v-card-text>
                    </v-card>
                    <v-card class="d-none d-md-block">
                        <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                            <span class="text-body-1">
                                Datos demográficos
                            </span>
                            <v-spacer></v-spacer>                        
                        </v-card-title>
                        <v-card-text class="text--primary pa-4">
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b>Ocupación: </b>
                                    <span v-if="patient.occupation_name == '' || patient.occupation_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.occupation_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Escolaridad: </b>
                                    <span v-if="patient.scholarship_name == '' || patient.scholarship_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.scholarship_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Estado Civil:</b>
                                    <span v-if="patient.civil_status_name == '' || patient.civil_status_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.civil_status_name}}</span>
                                </span>
                            </p>
                            <!-- <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Religión:</b>
                                    <span v-if="patient.religion_name == '' || patient.religion_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.religion_name}}</span>
                                </span>
                            </p> -->
                            <!-- <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Grupo Etnico:</b>
                                    <span v-if="patient.ethnic_group_name == '' || patient.ethnic_group_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.ethnic_group_name}}</span>
                                </span>
                            </p> -->
                            <!-- <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">¿Es desplazado?</b>
                                    <span>{{patient.displaced==true ? 'SI' : 'NO'}}</span>
                                </span>
                            </p> -->
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- <v-col cols="12" md="3">
                    <v-card>
                        <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                            <span class="text-body-1">
                                Datos demográficos
                            </span>
                            <v-spacer></v-spacer>                        
                        </v-card-title>
                        <v-card-text class="text--primary pa-4">
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b>Ocupación: </b>
                                    <span v-if="patient.occupation_name == '' || patient.occupation_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.occupation_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Escolaridad: </b>
                                    <span v-if="patient.scholarship_name == '' || patient.scholarship_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.scholarship_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Estado Civil:</b>
                                    <span v-if="patient.civil_status_name == '' || patient.civil_status_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.civil_status_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Religión:</b>
                                    <span v-if="patient.religion_name == '' || patient.religion_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.religion_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Grupo Etnico:</b>
                                    <span v-if="patient.ethnic_group_name == '' || patient.ethnic_group_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                        Dato Incompleto</v-chip>
                                    </span>
                                    <span v-else>{{patient.ethnic_group_name}}</span>
                                </span>
                            </p>
                            <p class="mb-0 color-text-glya">
                                <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">¿Es desplazado?</b>
                                    <span>{{patient.displaced==true ? 'SI' : 'NO'}}</span>
                                </span>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col> -->
                <v-col cols="12" md="5">
                    <v-card>
                        <v-card-text class="text--primary pa-4">
                            <patientAdherence :pid="patient.id"></patientAdherence>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card flat>
                        <!-- <v-card-title class="indigo white--text pa-0 pl-5 card-title-show">
                            <span class="text-body-1">
                                Mediciones
                            </span>
                            <v-spacer></v-spacer>                        
                        </v-card-title> -->
                        <v-row>
                        <v-expansion-panels focusable class="pa-1 pa-sm-3 mt-5" multiple>
                            <v-expansion-panel
                            v-for="(item, i) in listCharts"
                            :key="i"
                            @click="changeload(item)"
                            >
                            <v-expansion-panel-header class="indigo ">
                                <div class="card-header">
                                <h3 class="white--text ml-5">
                                    <v-icon class="mr-1" color="white">{{ item.icon }}</v-icon> {{ item.name }}
                                </h3>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col cols="12" class="card card-default shadow-sm ma-0 pa-0">
                                <v-col cols="12" class="card-body">
                                    <movil-patient-measure-chart
                                    :pid="patient.id"
                                    :type="item.code"
                                    :load="load[`${item.initials}`]"
                                    ></movil-patient-measure-chart>
                                </v-col>
                                </v-col>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-9" justify="center">
                <v-col cols="12" sm="4">
                    <v-btn @click="getReport('csv')" class="pl-5 pr-5 white--text" depressed color="green lighten-1"  block>Descargar CSV <v-icon right dark > mdi-file-excel</v-icon> </v-btn>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-btn @click="getReport('PDF')" class="pl-5 pr-5 white--text" depressed color="red accent-2" block> Descargar PDF <v-icon right dark > mdi-file-pdf-box</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AxiosServices from '@/services/AxiosServices';
import BasicData from '../views/BasicData.vue';
import movilPatientMeasureChart from "@/components/charts/movilPatientMeasureChart.vue";
import patientAdherence from "@/components/charts/patientAdherence.vue";



export default {
    name: 'PortalPatient',

    created(){
        this.getPatient()
    },

    mounted() {
        this.$store.commit('SET_LAYOUT', 'ClearLayout')
        this.$store.commit('SET_PATIENT', localStorage.getItem('token_glya'), localStorage.getItem('patient_id'))
        
        // this.getPatient()
    },

    data() {
        return {
            patient:{id:localStorage.getItem('patient_id')},
            listCharts: [
                { name: "Frecuencia Cardiaca", initials: "fc", icon: "mdi-heart-pulse", code: 1 },
                { name: "Oximetría", initials: "spo2", icon: "mdi-lungs", code: 2 },
                { name: "Presión Arterial", initials: "presion", icon: "mdi-pulse", code: 3 },
                { name: "Glucosa", initials: "glucometria", icon: "mdi-water", code: 6 },
                { name: "Temperatura", initials: "temp", icon: "mdi-thermometer", code: 7 },
                { name: "Peso", initials: "peso", icon: "mdi-scale-bathroom", code: 5 },
            ]  ,
            load: {
                fc: false,
                spo2: false,
                presion: false,
                glucometria: false,
                temp: false,
                peso: false,
            },
            url_pdf:'',
            url_csv:''
        };
    },
    methods: {
        getPatient: function () {         
            if (this.patient.id.length > 0 ) {
                AxiosServices.patientDetail(localStorage.getItem('patient_id')).then(res => {
                    console.log('hice el get');
                    this.patient = res.data.data
                }).catch((error) => {
                    if (error.code !='ECONNABORTED') {
                        this.$store.dispatch("addNotification", error.response.data);
                        this.$store.dispatch("render404");          
                    }                    
                })
            }       
        },
        changeload: function (item) {
            this.load[`${item.initials}`] = !this.load[`${item.initials}`];
        },
        logOut: function(){
            console.log('saldré')
            this.$store.dispatch("logOutPatient")
        },
        getReport: function(type){
            if (type == 'PDF') {
                this.getPDF()
            } else {
                this.getCSV()
            }
        },
        getPDF: function(){
            AxiosServices.patientExportPDF(this.patient.id).then( res => {
                console.log('trae:',res.data);
                this.url_pdf = res.data.data.url
                window.open(this.url_pdf, '_blank');
            }).catch(error => {
                console.log('el error en traer pdf es:',error);
            })
        },
        getCSV: function(){
            AxiosServices.patientExportCSV(this.patient.id).then( res => {
                console.log('trae:',res.data);
                this.url_csv = res.data.data.url
                window.open(this.url_csv, '_blank');
            }).catch(error => {
                console.log('el error en traer csv es:',error);
            })
        }

        
    },
    components: {
        BasicData, movilPatientMeasureChart, patientAdherence 
    }
};
</script>
<style scoped>
    .container-portal{
        background-color: white;
        width: 100%;
        max-width: 1200px;
    }
</style>