<template>
    <div>
        <h2>Integraciones</h2>
        <v-container grid-list-xs>
            <div v-for="i in integrations" :key="i.index">
                <v-card elevation-2>
                    <v-card-title>
                        <v-row justify="space-between" style="width: 100%">
                            <v-col class="text-left">{{i.title}} </v-col>
                            <v-col class="text-right" cols="auto"><v-img :src="integration_logo_url(i)" alt="" max-width="120"></v-img></v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                        {{ i.desc }}
                    </v-card-subtitle>
                   <v-card-text>
                    <v-switch :label="switchlabel(client[i.backend_var])" v-model="client[i.backend_var]" @change="update_client(i.backend_var)"></v-switch>
                    <template v-if="client[i.backend_var]">
                        <div class="attention body-1" v-html="i.desc2"></div>
                        <v-row class="mt-2">
                            <v-col v-for="f in i.fields" :key="f.key" :cols="f.size">
                                <div>
                                    <v-text-field :label="f.label" @change="update_client(f.model)" :placeholder="f.placeholder" v-model="client[f.model]"></v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                  </v-card-text>
                </v-card>
            </div>
        </v-container>
    </div>
</template>
<script>
    import AxiosServices from '@/services/AxiosServices';
    export default {
        name:"showIntegrations",
        data(){
            return {
                integrations:
                    [
                        {
                            index:1,
                            title:"Resiplus",
                            logo:"resiplus.jpg",
                            backend_var:"resiplus",
                            website:"https://addinformatica.com/",
                            desc:"Resiplus es un software de gestion de residencias de adultos mayores",
                            desc2: "La integración permite la transmision directa de signos vitales a la plataforma resiplus y recuperación de información básica de residente.</br> Necesitará una URL donde esta instalado el software y credenciales de ingreso. ",
                            fields:[
                                {label:"URL Resiplus",model:"resiplus_url",size:5, placeholder:"https://www.resiplusserver.com/"},
                                {label:"Usuario API/SOAP",model:"resiplus_user",size:3},
                                {label:"Contraseña API/SOAP",model:"resiplus_password",size:3,password:true},
                            ]
                        }
                    ],
                client:{},
                params:{}
            }
        },
        mounted(){
            this.getClient()
        },
        methods:{
            getClient:function(){
                AxiosServices.clientDetail(this.$store.state.user.client_id)
                .then(res => {
                    this.client=res.data.data
                }).catch(error => {
                    console.log('el error es',error);
                })
            },
            integration_logo_url:function(integration){
                return require("../../assets/integrations/"+integration.logo)
            },
            switchlabel:function(result){
                if (result) {
                    return "Hablitado"
                } else {
                    return "Desabilitado"
                }
            },
            update_client:function(i){
                let hash={}
                hash[i]=this.client[i]
                console.log("HASH",hash);
                AxiosServices.clientUpdate(this.$store.state.user.client_id,hash).then(res=>{
                    console.log("updated",res.data);
                }).error(err=>{
                    console.log("error",err);
                    
                })
            }
        }
    }
</script>

<style>


</style>