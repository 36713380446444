import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import { helpers, required, minLength, email, numeric, maxLength, sameAs} from 'vuelidate/lib/validators'
import AxiosServices from '@/services/AxiosServices'

Vue.use(Vuex)

var alpha2 = helpers.regex('alpha2', /^([0-9]{4,8}$)|^([0-9]{10}$)/)

export default new Vuex.Store({
  
  state: {
    layout: 'MainLayout',  
    url: process.env.VUE_APP_DOMAINURL,    
    colombia: 0,
    prueba: alpha2,
    nofound404:false,
    genderList:[{key:'Masculino',value:1},{key:'Femenino',value:0}],
    regex: helpers.regex('alpha2', /^([0-9]{4,8}$)|^([0-9]{10}$)/),
    idTypeList: {},
    time_units: {0:'Horas',1:'Días',2:'Semanas'},
    medication_unit: { 0:"cc (Centímetros cúbicos)",1:"mL (Mililitros)",2:"UI (Unidad Internacional)",3:"tableta(s)",4:"gragea(s)",5:"cápsula(s)",6:"gota(s)"},
    idTypeListRegex: {},
    user: { token: "" },
    timezone: null,  
    patient_id_login:"90",
    errorlogin: "",
    notifications:[],    
    form:{
      error:{seed:0},
      reserror:false
    },
    show_required:false,
    temporal_url:undefined,
    validations2:{     
      'namesSurnames': {
        minLength: minLength(3),
        namesVal: helpers.regex('namesVal', /^[a-zA-ZÁÉÍÓÚÑáéíóúñ ]{1,50}$/) 
      },
      'namesSurnamesRequired': {
        required,
        minLength: minLength(3),
        namesVal: helpers.regex('namesVal', /^[a-zA-ZÁÉÍÓÚÑáéíóúñ ]{1,50}$/) 
      },
      'password':{
        required,
        password: helpers.regex('password', /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      },
      'nid':{
        required,
        nidVal: helpers.regex('nidVal', /^([0-9]{4,8}$)|^([0-9]{10}$)/)    
      },
      'testSameAs':{
        password_confirmation: sameAs('5')
      },
      'password_confirmation':{
        required,       
        password_confirmation: helpers.withParams(     
          {type:'sameAs', eq:'hola'},
          function(value){
            return value == 'hola'
          }
        )
      },
      'email': {
        minLength: minLength(3),
        email,
      },
      'emailRequired': {
        required,
        email,
      },
      'phone': {
        phoneVal: helpers.regex('phoneVal', /^([0-9]{7,11}$)/) 
      },
      'phoneRequired': {
        required,
        phone2Val: helpers.regex('phone2Val', /^([0-9]{7,11}$)/) 
      },
      'quantity': {
        numeric,
        maxLength: maxLength(8)
      },
      'quantityRequired': {
        required,
        quantityVal: helpers.regex('quantityVal', /^([0-9]{1,10}$)/) 
      },
      'codRequired': {
        required,
        minLength6: minLength(6)
      },
      'textRequired': {
        required,
        minLength: minLength(3)
      },
      'textRequired_XS': {
        required,
        maxLength_s: maxLength(200),
        minLength: minLength(3)
      },
      'textRequired_M': {
        required,
        maxLength_m: maxLength(400),
        minLength: minLength(3)
      },
      'textRequired_L': {
        required,
        maxLength_l: maxLength(600),
        minLength: minLength(3)
      },
      'required': {
        required
      },
      'numericVitalRequired':{
        required,
        numeric,
        maxLength_numeric: maxLength(3)
      },
      'numericVital':{
        numeric,
        maxLength_numeric: maxLength(3)
      },
      'serialRequired': {
        required,
        serialVal: helpers.regex('namesVal', /^[a-zA-Z0-9]{16}$/) 
      },
      'urlRequired': {
        required,
        urlVal: helpers.regex('urlVal', /^https:\/\/.+$/) 
      },
      'none':{

      }


    } 
  },

  
  getters: {    
    token: function (state) {
      return state.user.token
    },
    getIdTypeList: function (state) {
      let list={}
      // if (state.idTypeList.length==0) {
      //   AxiosServices.listingsSimple('id_types').then(res =>{
      //     state.idTypeList = res.data.data
      //   })                 
      // }
      Object.keys(state.idTypeList).forEach(item =>{
        list[state.idTypeList[item]]=item
      })
      return list      
    },
    getFormError:function(state){
      let a=0
      for (const clave in state.form.error){
          a=a+state.form.error[clave]
      } 
      return a
    }
  },
  mutations: {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    },
    SET_USER_IS_AUTHENTICATED(state, replacement) {
      state.userIsAuthorized = replacement;
    },
    SET_URL_TEMPORAL (state, newurl) {
      state.temporal_url = newurl
    },
    SET_FORM_ERROR(state,err){
      state.form.error[Object.keys(err)[0]]=Object.values(err)[0]
      let a=0
      for (const clave in state.form.error){
        a=a+state.form.error[clave]
      } 
      state.form.reserror=(a>0 ? true : false)
    },
    RESET_FORM_ERROR(state){
      state.form.error={seed2:0}
      state.form.reserror=false
    },
    SET_VALIDATIONS(state,validations){      
      state.validations=validations
    },
    SET_LIST_NID_REGEX(state, idTypeList) {
      state.idTypeListRegex = idTypeList
    },
    SET_NID_VALIDATION(state,regular){
      state.validations2.nid.nidVal = helpers.regex('nidVal', eval(regular))
    },
    SET_PASSWORD_VALIDATION(state,password){
      state.validations2.testSameAs.password_confirmation = sameAs('test')
      state.validations2.password_confirmation.password_confirmation = helpers.withParams({type:'sameAs',eq:password},function(value){return value == password})
    },
    SET_TEST(state,info){
      state.test= info
    },
    SET_ERROR(state, error) {
      state.errorlogin = error
    },
    CLEAN_TOKEN(state) {
      state.user = { token: "" }
      state.errorlogin = ""
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_TIMEZONE(state, timezone) {
      state.timezone = timezone
    },
    SET_PATIENT(state, token, user_id) {
      state.user = {'token': token}
      state.patient_id_login = user_id
    },
    CLEAN_PATIENT(state) {
      state.user = { token: "" }
      state.patient_id_login = ""
    },
    SET_SHOW_REQUIRED(state,required){
      state.show_required = required
    },
    SET_NOTIFICATION(state,notification){
      state.notifications.push(notification)
    },
    DEL_NOTIFICATION(state,index){
      state.notifications.splice(index,1)
    },
    RELOAD(state,reload){
      state.nofound404 = reload
    },
    PUT_COLOMBIA_DEFAULT(state,colombia){
      state.colombia = colombia
    }
  },
  actions: {
    render404(context){
      if (router.currentRoute.name != '404nofound') {
        router.push({ name: '404notfound', params: { reload: true } }).catch(() => { })
      }
      context.commit('RELOAD',true)
    },
    putPassword(context,password){
      context.commit('SET_PASSWORD_VALIDATION',password)
    },
    addNotification(context,response){
      const notification={
        type:response.success ? 'success' : 'error',
        message:response.message,
        show:true
      }
      context.commit('SET_NOTIFICATION',notification)
    },
    removeNotification(context,index){
      context.commit('DEL_NOTIFICATION',index)
    },
    puterror(context,err){
      context.commit("SET_FORM_ERROR",err)
    },
    reseterror(context){
      context.commit("RESET_FORM_ERROR")
      context.commit("SET_SHOW_REQUIRED", true)
    },
    show_required(context) {
      context.commit("SET_SHOW_REQUIRED", true)
    },
    putValidationNid(context, regular) {
      context.commit('SET_NID_VALIDATION', regular)
      context.commit('SET_TEST', regular)
    },
    // putVaccines(context){
      //   axios.get(`${this.state.url}parameters/vaccines_json`).then(res=>{
        //     context.commit('SET_VACCINES',res.data)
        //   })
        // },
        auth0Login(context) {
          console.log("in a store action", context)
          context.state.auth0.authorize()
        },
        auth0HandleAuthentication(context) {
          context.state.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
              let expiresAt = JSON.stringify(
                authResult.expiresIn * 1000 + new Date().getTime()
                )
                // save the tokens locally
                console.log("Auth0", authResult)
                localStorage.setItem('access_token', authResult.accessToken);
                localStorage.setItem('id_token', authResult.idToken);
                localStorage.setItem('expires_at', expiresAt);
                localStorage.setItem('user_id', authResult.idTokenPayload.sub);
                console.log("Aqui vamos bien")
                
                router.replace('/dashboard');
              }
              else if (err) {
                alert('login failed. Error #KJN838');
                router.replace('/login');
                console.log(err);
              }
            })
          },
          auth0Logout(context) {
            console.log("in a store action", context)
            // No need to update the bearer in global axiosConfig to null because we are redirecting out of the application
            // Clear Access Token and ID Token from local storage
            localStorage.removeItem('access_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('expires_at');
            
            // redirect to auth0 logout to completely log the user out
            window.location.href = process.env.VUE_APP_AUTH0_CONFIG_DOMAINURL + "/v2/logout?returnTo=" + process.env.VUE_APP_LOG_DOMAINURL + "&client_id=" + process.env.VUE_APP_AUTH0_CONFIG_CLIENTID;
          },
          getTimeZone(context, id){
            AxiosServices.patientDetail(id).then(res => {
              context.commit('SET_TIMEZONE', res.data.data.time_zone)
            }).catch(error => {
              console.log('el error es', error);
            })
          },
          loginBack(context, user) {
            context.commit('CLEAN_TOKEN')
            AxiosServices.authLogin(user).then(res => {
              localStorage.setItem('token_glya', res.data.data.token);
              localStorage.setItem('user_id', res.data.data.user_id);
              localStorage.setItem('user_country_id', res.data.data.country_id);
              localStorage.setItem('user_country_name', res.data.data.country_id_name);
              localStorage.setItem('user_prefix', res.data.data.prefix);
              localStorage.setItem('client_with_multipatient_glady', res.data.data.client_with_multipatient_glady);
              localStorage.setItem('user_full', JSON.stringify(res.data.data));              
              context.commit('SET_USER', res.data.data)
              context.dispatch('addNotification', res.data)
              console.log('state',context.state.temporal_url);
              context.commit('PUT_COLOMBIA_DEFAULT',res.data.data.country_id)
              if (context.state.temporal_url != undefined) {
                router.push({ path: context.state.temporal_url, params: { reload: true } }).catch(() => { })
                context.commit('SET_LAYOUT', 'MainLayout')
                window.location.reload();
              }
            }).catch(error => {
              console.log('el error es ', error);
              context.commit('SET_ERROR', error.response.data.message)
              context.dispatch('addNotification', error.response.data)
            })
          },
    logOut(context, route) {
      context.commit('CLEAN_TOKEN')
      localStorage.removeItem('token_glya')
      localStorage.removeItem('user_id')
      if (route !== "home") {
        context.commit('SET_LAYOUT', 'ClearLayout')
        router.replace('/');
      }
    } ,
    loginPatient(context, token_and_idtoken) {
      context.commit('CLEAN_TOKEN')
      context.commit('CLEAN_PATIENT')
      localStorage.setItem('token_glya', token_and_idtoken[0]);
      localStorage.setItem('patient_id', token_and_idtoken[1]);
      context.commit('SET_PATIENT', token_and_idtoken[0], token_and_idtoken[1])
      router.replace('/portal_patient')
    },
    logOutPatient(context){
      context.commit('CLEAN_TOKEN')
      context.commit('CLEAN_PATIENT')
      localStorage.removeItem('token_glya')
      localStorage.removeItem('patient_id')
      context.commit('SET_LAYOUT', 'ClearLayout')
      router.replace('/login_patient')
    },
    clearCachePatient(context){
      context.commit('CLEAN_TOKEN')
      context.commit('CLEAN_PATIENT')
      localStorage.removeItem('token_glya')
      localStorage.removeItem('patient_id')
      context.commit('SET_LAYOUT', 'ClearLayout')
    }

  },


  modules: {
  }
})
