<template>
  <div>
    <v-col>
      <v-row class="d-flex justify-space-between">
        <v-col>
          <v-row>
            <v-btn @click="reload" class="ml-5 mr-5 mt-5 pa-3 text-right" color="indigo lighten-2 white--text" x-small outlined>
              <v-icon>mdi-refresh</v-icon> {{type==4 ? '' : 'Recargar' }}
            </v-btn>
            <v-checkbox v-model="onlyalerts" label="Solo Alertas" color="blue" @change="rebuid"></v-checkbox>
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" class="pl-5 mt-1" v-if="type==4">
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 col-form-icon filter-chart">
              <BaseDate  v-model="filter.date1" label="Fecha inicial"  :outlined="true" filter="filter"></BaseDate>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 col-form-icon filter-chart">
              <BaseDate  v-model="filter.date2" label="Fecha final" :outlined="true" filter="filter"></BaseDate>
            </v-col>
            <v-btn cols="12" sm="4"  @click="reload" class="ml-2 mr-2 mt-3 pa-3 text-right" elevation="0" color="indigo lighten-2 white--text" small >
              Filtrar
            </v-btn>
            <!-- <v-col cols="12" sm="8" class="pb-0 col-form-icon filter-chart">
              <SelectSearch 
                class="serach-tag"
                :valuex="tag_id" 
                element="element" 
                icon="mdi-magnify" outlined 
                label="Etiqueta"
                :multiple="true" 
                model="tags">
              </SelectSearch>            
            </v-col>
            <v-col cols="12" sm="4" class="pt-0">
              <v-btn @click="reload" color="indigo white--text" small class="mt-3" block>
                Filtrar
              </v-btn>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      </v-col>
      <v-col v-if="spinner.chart" class="d-flex flex-center text-center mt-5">
        <v-col>
          <span class="loader ml-4"></span>
        </v-col>
      </v-col>
      <div v-else ref="chartdiv" class="graph1"></div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import BaseDate from '@/components/forms_items/BaseDate.vue'
import AxiosServices from "@/services/AxiosServices";
// import SelectSearch from "../forms_items/SelectSearch.vue";
export default {
  name: "riskChart",
  mounted() {
    this.rebuid()
  },
  components:{
    BaseDate,
    // SelectSearch
  },
  props: ["client_id", "type", "tags","start_date","end_date"],
  data() {
    return {
      clinical_elements:{
        fc:{cod:1,nombre:"F. Cardiaca"},
        oximetria:{cod:2,nombre:"Oximetria"},
        psistolica:{cod:3,nombre:"P. Sistolica"},
        pdiastolica:{cod:4,nombre:"P. Diastolica"},
        glicemia:{cod:6,nombre:"Glicemia"}
      },
      tag_id:'none',
      data: [],
      spinner:{chart:false},
      onlyalerts:false,
      filter:{date1:(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]), date2:(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0])}

    };
  },
  computed: {

  },
  methods: {
    rebuid: function () {
        if (this.root){
          this.root.dispose()
        }
        this.fetchdata().then(res=>{
        res
        this.buildchart();
      });
    },
    reload: function () {
        if (this.root){
          this.root.dispose()
        }
        this.fetchdata().then(res=>{
        res
        this.buildchart();
      });
    },

    buildchart: function () {
      let root = am5.Root.new(this.$refs.chartdiv);
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          pinchZoomX: false,
          pinchZoomY: false,
          panX: false,
          wheelX: false,
          wheelY: false,
          maxTooltipDistance: 0,
          layout: root.verticalLayout,
        })
      );
      var xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });
      xRenderer.grid.template.setAll({
        location: 1,
        strokeOpacity: 0.0,
      });
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "clinical_element",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      let yRendered = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      });
      yRendered.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: 100,
          numberFormat: "#'%'",
          strictMinMax: true,
          calculateTotals: true,
          renderer: yRendered,
        })
      );
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );

      this.xAxis = xAxis;
      this.yAxis = yAxis;
      //this.series = series;
      this.root = root;
      this.chart = chart;
      this.legend = legend;
      this.makeseries();
    },

    getStartDate() {
      let date1 = new Date(this.filter.date1);
      let dateToday = new Date();

      switch (this.type) {
        case 1:
          return new Date().toISOString().split('T')[0];
        case 2:
          dateToday.setDate(dateToday.getDate() - 7);
          return dateToday.toISOString().split('T')[0];
        case 3:
          dateToday.setMonth(dateToday.getMonth() - 1);
          return dateToday.toISOString().split('T')[0];
        case 4:
          return date1.toISOString().split('T')[0];
        default:
          return null;
      }
    },
    getEndDate() {
      let date2=new Date(this.filter.date2);
      switch (this.type) {
        case 1:
        case 2:
        case 3:
          return new Date().toISOString().split('T')[0];
        case 4:
          return date2.toISOString().split('T')[0];
        default:
          return null;
      }
    },
    fetchdata: async function () {
      this.spinner.chart=true
      let data=[]
      for (let ce in this.clinical_elements){
          await AxiosServices.reportsClientAlerts(this.clinical_elements[ce].cod,this.getStartDate(),this.getEndDate() ).then(res=>{
          let dataPoint=res.data.data
          dataPoint.clinical_element=this.clinical_elements[ce].nombre
          data.push(dataPoint)
        })
      }
      this.spinner.chart=false
      this.data5=data    
    },
    makeseries: function () {
      //trae data de axiosservices
      var root=this.root
      let data=this.data5
      let seriesdef=[]
      if (this.onlyalerts){
        seriesdef = [
          { nombre: "Bajo", cod: "low_priority" },
          { nombre: "Medio", cod: "medium_priority" },
          { nombre: "Alto", cod: "high_priority" }
        ];
      } else {
        seriesdef = [
          { nombre: "En Rango", cod: "without_alert" },
          { nombre: "Bajo", cod: "low_priority" },
          { nombre: "Medio", cod: "medium_priority" },
          { nombre: "Alto", cod: "high_priority" }
        ];
      }

      let bar_color = {
        without_alert: 0x23b823,
        low_priority: 0x1976d2,
        medium_priority: 0xf9a825,
        high_priority: 0xff5252
      };

      for (let element of seriesdef) {
        var series = this.chart.series.push(
          am5xy.ColumnSeries.new(this.root, {
            name: element.nombre,
            stacked: true,
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            clickable:true,
            valueYField: element.cod,
            valueYShow: "valueYTotalPercent",
            categoryXField: "clinical_element",
            fill: am5.color(bar_color[element.cod]),

          })
        );
        series.columns.template.setAll({
          fillOpacity: 0.7,
          strokeWidth: 0,
        });
        
/*
        series.columns.template.events.on("click", function(ev) {
          console.log("Clicked on a column", ev.target);
        });
  */      
        series.bullets.push(function (root2,series2,dataitem2) {
          let visible=dataitem2.get("valueYTotalPercent")>7;
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueYTotalPercent.formatNumber('#.#')}%",
              //text: series.height(),
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              fontSize: 13,
              populateText: true,
              visible: visible,
              masa:(root2,series2) //JUAQUIN
            }),
          });
        });

        series.data.setAll(data);
        series.columns.template.setAll({
          tooltipText:
            "{name}: {valueY} ptes ({valueYTotalPercent.formatNumber('#.0')}%) ",
        });

        this.legend.data.push(series);
      }
      this.xAxis.data.setAll(data);
      this.chart.appear(1000, 100);
    },
  },
};
</script>
<style>
    .graph1 {
      height: 300px;
    }

    .loader {
      width: 60px;
      height: 60px;
      border: 5px solid rgb(186, 186, 186);
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    .serach-tag .v-text-field--outlined .v-input__prepend-outer {
      margin-top: 5px;
    }
    .serach-tag .v-text-field--outlined .v-label--active {
      transform: translateY(-14px) translateX(-15px) scale(.75);
    }
    .filter-chart .inputglya{
      height: 40px;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

</style>
