<template>
    <div>
        <h2 class="mb-3">{{title}}</h2>
    </div>

</template>

<script>
    export default {
        name: 'ViewTitle',
        props: { 
            title:{
            type:String,
            default:'TITULO'
            },
        },
        components: {
     
        }
    }

</script>