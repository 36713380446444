<template>
    <div>
        <div class="container-login-patient">
            <v-container class="contain-patient mx-auto" >
                <v-row justify="center">
                    <v-col cols="auto" justify="center">
                        <img src="../../assets/glyapxm-01.svg" alt="gdi" width="230">
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" sm="6" class=" _order-1" justify="center">
                        <h1 class="white--text text-center">Bienvenido al portal de paciente</h1>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="center">  
                                    <div v-if="!confirmation">
                                        <h3 class="text-center">Por favor ingrese el número de documento del paciente</h3>
                                        <v-col cols="12" class="mt-5">
                                            <BaseInput v-model="nid" type="text" label="Número de documento" validador="none" outlined="outlined" login="login"></BaseInput>
                                        </v-col>
                                        <h5 v-show="errorlogin.show && nid.length > 0" class="red--text text-center mb-5">{{ errorlogin.message }}</h5>
                                        <v-btn v-if="verror || nid.length == 0" class="boton-iniciosesion" block disabled  color="indigo" >
                                            <b class="white--text">Continuar</b>
                                        </v-btn>
                                        <v-btn v-else class="boton-iniciosesion" block @click="sendNid" color="indigo" >
                                            <b class="white--text">Continuar</b>
                                        </v-btn>
                                    </div>
                                    <div v-else>
                                        <h3 class="text-center">Te llegará un SMS al teléfono movil registrado</h3>
                                        <v-col cols="12" class="mt-5">
                                            <v-text-field                       
                                                :rules="[rules.required, rules.min]"
                                                v-model="code"
                                                name="input-10-2"
                                                label="Código de confirmación"
                                                value=""
                                                outlined
                                                placeholder="Código de confirmación"
                                                class="input-group--focused login logintext"
                                                v-on:keyup.13="$store.dispatch('loginBack', user)"
                                            ></v-text-field>   
                                        </v-col>
                                        <h5 v-show="errorlogin.show" class="red--text text-center mb-5">{{ errorlogin.message }}</h5>
                                        <v-btn :disabled="code.length == 0" @click="logInPatient" class="boton-iniciosesion" block color="indigo">
                                            <b class="white--text">Iniciar sesión</b>
                                        </v-btn>
                                        <p class="text-center mt-5">No recibi el código <a href="http://">Volver a énviar</a></p>
                                        <v-btn x-small @click="confirmation=false" :disabled="verror" color="grey" dark>
                                            <v-icon small>mdi-arrow-u-left-bottom</v-icon>regresar
                                        </v-btn>
                                    </div>
                                    <div class="ghost"></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>      
        </div>
    </div>
</template>
<script>
import BaseInput from '@/components/forms_items/BaseInput.vue'
import AxiosServices from '@/services/AxiosServices';

export default {
    name: 'LoginPatient',

    mounted() {
        this.$store.commit('SET_LAYOUT', 'ClearLayout')
        this.$store.dispatch("clearCachePatient")
    },
    data() {
        return {
            rules: {
                required: value => !!value || 'Código requerido',
                min: value => value.length <= 4 || 'El código debe tener 4 carácteres',
            },
            nid:'',
            code:'',
            id:90,
            token:this.$store.getters.token,
            confirmation:false,
            errorlogin:{show:false, message:''}
        };
    },

    methods: {
        logInPatient:function(){
            let login = {'nid' : this.nid, 'otp_code' : this.code}
            AxiosServices.verify_otp(login).then(res => {
                // console.log('llegó',res.data);
                this.$store.dispatch("loginPatient",[res.data.data.token,res.data.data.id])
            }).catch(error => {
                if (this.errorlogin.message.length == 0){
                    this.errorlogin.message = 'número de documento o codigo erróneos'
                }
                this.errorlogin.show = true
                console.log('el error de verificar es',error);
            })
        },
        sendNid:function () {
            AxiosServices.request_otp({'nid':this.nid}).then(res => {
                // console.log(res.data.message);
                if (res.data.message.length == 41 || res.data.message.length == 43){                    
                    this.errorlogin.message = 'Número de documento o codigo erróneos'
                }
                // console.log('se envió el mensaje');
            }).catch(error => {
                console.log('el error es',error);
            })
            this.confirmation = true
        }
        
    },
    computed: {
        verror: function () {
        var show = false;
        if (this.$store.state.form.reserror) {
            show = true;
        }
        return show;
    },
    },
    components: {
        BaseInput
    }
    
};
</script>
<style scoped>
    .contain-patient{
        max-width: 1000px;
        width: 80%;
    }
    body{
    background: rgb(49, 1, 62);
  }
    .container-login-patient{
    background-image:linear-gradient(360deg, rgba(2,0,36,0.7) 0%, rgba(9,9,121,0.5) 35%, rgba(0,212,255,0.7) 100%),  url(../../assets/bg-glya.png)!important;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(38, 1, 62) ;
    background-position: left;
    height: auto;
    min-height: 100vh;
    width: 100%;
    /* overflow-x:scroll;
    overflow-y:hidden; */
}
</style>