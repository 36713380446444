<template>
    <div>
        <v-row>
            <v-col class="pa-2" cols="12">
                <BasicDataPatient :editform="false"></BasicDataPatient> 
            </v-col>
            <v-col cold="12">
                <div>
                    <component v-bind:is="order.form" :encounter="encounter.id" :save_data="save_data"> </component>
                </div>
                <!-- <div v-show="order.form == 'VacunationForm'">
                    <GenericForm :encounter="encounter.id"></GenericForm>
                </div>
                <div v-show="order.form == 'GenericForm'">
                    <VacunationForm :encounter="encounter.id"></VacunationForm>                    
                </div> -->
            </v-col>      
            <v-col cols="12">
                <v-card class="mx-auto">
                    <v-card-text class="pa-0">
                        <v-col class="d-flex justify-space-around mx-auto" cols="8">                     
                            <v-btn rounded color="indigo lighten-2" small class="pl-5 pr-5 white--text" @click="validateEncounter">
                                <v-icon small class="pr-2">mdi-check-all</v-icon> Validar {{verror}}
                            </v-btn>
                            <v-btn rounded color="success" small class="pl-5 pr-5 white--text" @click="saveEncounter">
                                <v-icon small class="pr-2"> mdi-content-save-check-outline</v-icon> Guardar
                            </v-btn>                           
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>         
        </v-row>
    </div>
</template>
<script>
import axios from 'axios'
// import TitleView from '@/components/views/TitleView.vue'
import BasicDataPatient from '@/components/views/BasicData.vue'
import VacunationForm from '@/components/formsEncounter/VacunationForm.vue'
import GenericForm from '@/components/formsEncounter/GenericForm.vue'
export default {

    mounted() {
        this.getEncounter()        
    },

    data() {
        return {
            encounter: {id: this.$route.params.id },
            order:{},
            patient:{},
            type:"encounters",
            save_data:false
        }
    },
    methods: {
        getEncounter: function () {
            this.$store.dispatch("reseterror")
            axios.get(this.$URL + "encounters/" + this.$route.params.id + ".json").then(res => {
                this.encounter = res.data.encounter
                this.order = res.data.order
                this.patient = res.data.patient
                this.type = res.data.type
                // this.$store.dispatch("getpatient", res.data.patient.id)
            })
        },
        validateEncounter: function () {
            this.$store.dispatch("show_required")
            console.log("Validando------------");
        },
        saveEncounter: function () {
            this.$store.dispatch("show_required")
            if (!this.verror) {
                this.encounter.status = 'Realizado'
                this.encounter.checkout = new Date().toLocaleString()
                this.encounter.responsible = this.encounter.responsible.id
                this.save_data = true
                axios.patch(this.$URL + this.type + "/" + this.$route.params.id + ".json", this.encounter).then(res => {
                    this.getEncounter()
                    this.encounter = res.data
                })    
            } else {
                this.$store.dispatch("show_required")
            }            
            
        }
    },
    components:{
        BasicDataPatient,
        VacunationForm,
        GenericForm
    },
    computed:{
        verror: function () {
            return this.$store.state.form.reserror
        },
        
    }

}
</script>
