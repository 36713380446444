import VueRouter from 'vue-router'
import Vue from 'vue'
// import layout from "@/components/layout/Main.vue";
import patientsshow from "../components/primaries/ShowPatient.vue";
import stockshow from "../components/primaries/ShowInventory.vue";
import patientsindex from "../components/primaries/IndexPatient.vue";
import dashboardindex from "../components/primaries/IndexDashboard.vue"
import reports from "../components/primaries/IndexReports.vue";
import activities from "../components/primaries/IndexActivities.vue";
import Inventory from "../components/primaries/IndexInventory.vue";
import Parameters from "../components/primaries/IndexParameters.vue";
import indexUsers from "../components/primaries/IndexUsers.vue";
import indexOrders from "../components/primaries/IndexOrders.vue";
import indexsupplies from "../components/primaries/IndexSupplies.vue";
import indexmoves from "../components/primaries/IndexMoves.vue";
import formEncounter from "../components/forms/FormEncounter.vue";
import showOrder from "../components/primaries/ShowOrder.vue";
import showAlert from "../components/primaries/ShowAlert.vue";
import indexTags from "../components/primaries/indexTags.vue"
import indexAlarms from "../components/primaries/indexAlarms.vue"
import indexAlerts from "../components/primaries/IndexAlerts.vue"
import login from "../components/auth/UserLogin.vue";
import homePage from "../components/layout/homePage.vue";
import login_patient from "../components/layout/LoginPatient.vue";
import notFound from "../components/layout/404notFound.vue";
import store from "../store/index";
import Auth0Callback from "../components/auth/Auth0Callback.vue";
import PortalPatient from "../components/primaries/PortalPatient.vue";
import webhooks from "../components/primaries/WebHooks.vue";
import integrations from "../components/primaries/ShowIntegrations.vue";


Vue.use(VueRouter)

const routes = [
  // {
  //   path: "/main",
  //   name: "home",
  //   component: layout,
  // }, 
  {
    path: "/",
    name: "home",
    component: homePage,
  },
  {
    path: "/login_patient",
    name: "login_patient",
    component: login_patient,
  },
  {
    path: "/auth0callback",
    name: "auth0callback",
    component: Auth0Callback,
  }, 
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboardindex,
    //meta: {requiresAuth: true} //requires auth to access this route
  },
  {
    path: "/reports",
    name: "reports",
    component: reports,
    //meta: { requiresAuth: true }
  },
  {
    path: "/activities",
    name: "activities",
    component: activities,
  },
  {
    path: "/inventory",
    name: "inventory",
    component: Inventory,
  },
  {
    path: "/parameters",
    name: "parameters",
    component: Parameters,
  }, 
  {
    path: "/moves",
    name: "moves",
    component: indexmoves,
  },
  {
    path: "/supplies",
    name: "supplies",
    component: indexsupplies,
  },
  {
    path: "/patients",
    name: "patients",
    component: patientsindex,
    props: true
  },
  {
    path: "/users",
    name: "users",
    component: indexUsers
  },
  {
    path: "/orders",
    name: "order",
    component: indexOrders
  },
  {
    path: "/portal_patient",
    name: "portal_patient",
    component: PortalPatient
  },
  {
    path: "/integrations",
    name: "integrations",
    component: integrations
  },
  {
    path: "/webhooks",
    name: "webhooks",
    component: webhooks
  },
  {
    path: "/patients/:id",
    name: "patient",
    component: patientsshow,
  },
  {
    path: "/encounters/:id",
    name: "encounters",
    component: formEncounter,
  },
  {
    path: "/patients/:patient_id/orders/:order_id",
    name: "orders",
    component: showOrder,
  },
  {
    path: "/patients/:patient_id/alerts/:alert_id",
    name: "alert",
    component: showAlert,
  },
  {
    path: "/stocks/:id",
    name: "stocks",
    component: stockshow,
  },
  {
    path: "/tags",
    name: "tags",
    component: indexTags
  }, 
  {
    path: "/alerts",
    name: "alerts",
    component: indexAlarms
  }, 
  {
    path: "/alerts_management",
    name: "alerts_management",
    component: indexAlerts
  }, 
  {
    path: "/login",
    name: "login",
    component: login
  },
  {
    path: "/404notfound",
    name: "404notfound",
    component: notFound
  }
];
const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token_glya') == null) {
    if (to.path == '/login_patient' && from.path == '/'){
      next()
    } 
    if (to.path == '/login_patient' && from.path == '/portal_patient'){
      next()
    } 
    else if (to.path == '/portal_patient' && from.path == '/'){
      next('/login_patient')
    }
    else if (to.path == '/' && from.path != '/login_patient'){
      next()
    }
    else {
      store.commit('SET_URL_TEMPORAL',to.fullPath)
      if (from.path == '/login_patient' && to.path == '/' ) {
        console.log('no debe hacer nada');
      } else {
        next('/')
      }
    }   
  } else {
    next()
  }
})
// if (localStorage.getItem('token_glya') == null) {
//   console.log('ccccentreeeeee');
//   // El usuario no está autenticado, redirige al login
//   next('/')
// } else {
//   // El usuario está autenticado o la ruta no requiere autenticación
//   next()
// }

// router.beforeEach( (to, from, next) => {
//   if (to.matched.some(record => record.path == "/auth0callback")) {
//     console.log("router.beforeEach found /auth0callback url");
//     Store.dispatch('auth0HandleAuthentication');
//     next(false);
//   }

//   // check if user is logged in (start assuming the user is not logged in = false)
//   let routerAuthCheck = false;
//   // Verify all the proper access variables are present f:or proper authorization
//   if (localStorage.getItem('access_token') && localStorage.getItem('id_token') && localStorage.getItem('expires_at')) {
//     console.log('found local storage tokens');
//     // Check whether the current time is past the Access Token's expiry time
//     console.log ("esta realizando la autenticacion");
//     let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
//     // set localAuthTokenCheck true if unexpired / false if expired
//     routerAuthCheck = new Date().getTime() < expiresAt;
//   }

//   // Set global ui understanding authentication status
//   Store.commit('SET_USER_IS_AUTHENTICATED', routerAuthCheck)
  
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     //Check if user is authenticated
//     if (routerAuthCheck) {
//       // User is authenticated
//       next();
//     }
//     else {
//       // User is not authenticated, redirect to login page
//       router.replace('/login');
//     }
//   }
//   // Allow page to be accessed
//   else{
//     next();
//   }
// });

export default router;