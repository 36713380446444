<template>
    <div>
        <v-dialog transition="dialog-bottom-transition" v-model="dialog_order" max-width="600" persistent>
            <template v-slot:activator="{ on, attrs }">
                <div v-if="!permissions.create_order">
                    <v-btn v-if="showbutton" color="deep-purple darken-1" class="white--text mt-2 pl-5 pr-5" disabled>
                        <v-icon left> mdi-text-box </v-icon>  Crear nueva orden
                    </v-btn>
                    <v-btn small v-if="showicon" color="deep-purple darken-1" class="white--text" disabled>
                        <v-icon small class="pr-2">mdi-pencil</v-icon><span class="text-btn">Editar</span>
                    </v-btn>
                </div>
                <div v-else>
                    <v-btn v-if="showbutton" color="deep-purple darken-1" class="white--text mt-2 pl-5 pr-5" v-bind="attrs" v-on="on">
                        <v-icon left> mdi-text-box </v-icon>  Crear nueva orden
                    </v-btn>
                    <v-btn small v-if="showicon" color="deep-purple darken-1" class="white--text" v-bind="attrs" v-on="on">
                        <v-icon small class="pr-2">mdi-pencil</v-icon><span class="text-btn">Editar</span>
                    </v-btn>
                </div>
            </template>  
            <template v-slot:default>
                <v-card>
                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{createform == true ? 'Crear orden' : 'Editar orden'}}</v-toolbar>
                    <v-card-text>                  
                        <v-col cols="12" class="mt-2" v-if="!showForm && createform">
                            <p>Seleccione el tipo de Orden que desea realizar</p>
                            <v-row class="mt-1" >
                                <v-col v-for="ordertype in orderTypes" :key="ordertype.name" cols="12" md="6" class="pt-0 pb-0">
                                    <v-btn dark block small class="mb-2 pt-4 pb-5" @click="selectType(ordertype.value, ordertype.name)" :color="ordertype.name == typeOrder  ? 'deep-purple lighten-1' : 'blue-grey lighten-2'">
                                        <v-card class="text-caption wrap-text mt-2" color="transparent" elevation="0">
                                            <v-icon left>mdi-{{ ordertype.icon}}</v-icon>{{ ordertype.name}}
                                        </v-card>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-if="showForm">
                            <v-row class="pt-3">
                                <v-tooltip bottom v-if="createform == true" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="pink"  v-bind="attrs" v-on="on" @click="showForm =false">
                                            <v-icon>mdi-arrow-left-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Volver a tipo de orden</span>
                                </v-tooltip>
                                <div v-else><v-icon class="ma-2" color="indigo">mdi-text-box</v-icon></div>
                                <h3 class="pt-2"> Orden: {{typeOrder}}</h3>
                            </v-row>
                            <!-- Formulario medicamentos -->
                            <v-row v-if="order.order_type == 1" class="mt-2">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <SelectSearch                                    
                                    label="Tipo de Medicamento" 
                                    model="medicines" 
                                    v-model="order.medicine_id" 
                                    validador="required" 
                                    :valuex="order.medicine_id == '' ? 1 : { id: order.medicine_id, text: order.medicine_name }"></SelectSearch>
                                </v-col>                                
                                <v-col cols="12" sm="6" class="pt-2 pb-0 mb-0">
                                    <label >Frecuencia(cada)</label>
                                    <v-row>
                                        <v-col cols="6" class="timefield pr-0">
                                            <BaseInput class="timefield label_none" v-model="order.frecuency" type="number" validador="required"></BaseInput>
                                        </v-col>
                                        <v-col cols="6" class="timefield pr-0 f-unidad">
                                            <BaseSelectMult v-model="order.time_unit" type="text" label="" validador="required" list_type="time_units"></BaseSelectMult>
                                        </v-col>                                        
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-2 pb-0 mb-0">
                                    <BaseInput v-model="order.during" label="Duración tratamiento en días" type="number" validador="required"></BaseInput>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-2 pb-0 mb-0">
                                    <label >Dosis</label>
                                    <v-row>
                                        <v-col cols="6" class="timefield pr-0 f-unidad">
                                            <BaseSelectMult class="timefield label_none" v-model="order.amount" type="text" label="" validador="required" list_type="amount_unit" :multiple="false"></BaseSelectMult>
                                        </v-col>
                                        <v-col cols="6" class="timefield pr-0 f-unidad">
                                            <BaseSelectMult class="timefield label_none" v-model="order.medication_unit" type="text" label="" validador="required" list_type="medication_unit" :multiple="false"></BaseSelectMult>
                                        </v-col>                                       
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                                    <v-col class="pa-0 pb-2">
                                        <label>Notificar recordatorios por WhatsApp</label>
                                    </v-col>
                                    <BaseCheckbox v-model="order.notify" color="indigo" positive="Si" negative="No" ></BaseCheckbox>
                                </v-col> 
                                <v-col cols="12" class="pt-0 pb-0 mb-0" v-if="!verror">
                                    <v-card class="pa-3" elevation="0" min-height="80" outlined>                                        
                                        <p class="pb-0 mb-0">Administrar {{order.amount}} {{complemento(order.amount)}} {{$store.state.medication_unit[order.medication_unit]}} de {{nameMedicine}} cada {{ order.frecuency }} {{order.frecuency==1 ? $store.state.time_units[order.time_unit].slice(0, -1) : $store.state.time_units[order.time_unit]}} durante  {{order.during}} {{order.during == 1 ? 'día' : 'días'}}</p>
                                        <p v-if="order.unit_time=='Horas'"><b>Total a dispensar: {{unit}} dosis</b></p>                                        
                                    </v-card>
                                </v-col>
                            </v-row>
                            <!-- inicio Formulario signos vitales -->
                            <v-row v-if="order.order_type == 0" class="mt-2">
                                <v-col cols="12" class="pt-0 pb-0 mb-0">
                                    <BaseSelect v-model='order.clinical_element' type="text" label="Tipo de medición" list="clinical_elements" validador="required"></BaseSelect>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                                    <BaseSelectMult v-model="order.frequency_type" type="text" label="Frecuencia" validador="required" list_type="frecuencia"></BaseSelectMult>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                                    <BaseInput v-model="order.during" :label="order.frequency_type==0 ? 'Duración en días' : 'Duración en semanas'" type="number" validador="required"></BaseInput>
                                </v-col>
                                <span class="ghost">{{ requiredFrecuency }}</span>
                                <v-col cols="12" class="pt-0" v-show="order.frequency_type == '1'">
                                    <v-col cols="12" class="pa-0">
                                        <label for="">Seleccione los días de la medición</label>
                                    </v-col>
                                    <div id="app">
                                        <v-btn v-for="(day, index) in weekdays" :key="index" @click="toggleSelection(index)" class="ma-1 pt-0 pb-0 pl-2 pr-2" :color="`indigo ${isSelected(index) ? 'darken-1' : 'lighten-3'}`" :outlined="!isSelected(index)" dark x-small height="20px">
                                            {{ day }}
                                        </v-btn>
                                    </div>
                                </v-col>  
                                <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                                    <BaseSelectMult v-model="order.hours" multiple="multiple" chips="chips" type="text" label="Hora(s) de toma" validador="required" list_type="horas"></BaseSelectMult>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0 mb-0">
                                    <v-col class="pa-0 pb-2">
                                        <label>Notificar recordatorios por WhatsApp</label>
                                    </v-col>
                                    <BaseCheckbox v-model="order.notify" color="indigo" positive="Si" negative="No" ></BaseCheckbox>
                                </v-col>                       
                            </v-row>
                            <!-- fin Formulario signos vitales -->
                        </v-col>                 
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="close" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                            <v-icon right dark> mdi-close</v-icon>
                        </v-btn>   
                        <div v-if="showForm" class="pl-2">
                            <v-btn v-if="verror || disabledButton" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar 
                                <v-icon right dark> mdi-check</v-icon>
                            </v-btn>
                            <v-btn v-else @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar 
                                <v-icon right dark> mdi-check</v-icon>
                            </v-btn>
                        </div>
                    </v-card-actions>

                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
    import BaseSelect from '@/components/forms_items/BaseSelect.vue'
    import BaseSelectMult from '@/components/forms_items/SelectMultiple.vue'
    import BaseInput from '@/components/forms_items/BaseInput.vue'
    import SelectSearch from '@/components/forms_items/SelectSearch.vue';
    import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue';
    import AxiosServices from '@/services/AxiosServices';

    export default{
        mounted(){
            this.getOrder()
            this.$store.dispatch("reseterror")
            this.$store.dispatch("show_required")
            this.getPermission()
        },
        created() {
            this.$store.dispatch("reseterror")
        },
        data() {
            return{
                order:{ order_type:0,notify:true, only:true, time_unit:0, frecuency:1,dose:1,during:1,medicine_id:undefined,medicine_name:'',amount:'1',medication_unit:3,medication_unit_name:''},
                frecuency:'',
                order_date:undefined,
                order_time:'',
                dialog_order:false,
                test:[],
                typeOrder:'',
                medicineName:'',
                showForm:!this.$props.createform,
                orderTypes:[{name:'Medicamentos', icon:'pill', value:1}, {name:'Toma de signos vitales', icon:'pulse',value:0}],
                disabledButton:false,
                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
                selectedDays: [],
                permissions:{}             
            }
        },
        props: {
            patient_id: {},
            status: null,
            createform:{
                type: Boolean,
                default:true
            },
            order_id:{},
            showbutton:{
                type:Boolean,
                default: false
            },
            showicon: {
                type:Boolean,
                default: false
            },
            disabled: {
                type:Boolean,
                default: false
            },
        },
        methods:{
            toggleSelection(index) {
                const selectedIndex = this.selectedDays.indexOf(index);
                if (selectedIndex === -1) {
                    this.selectedDays.push(index);
                } else {
                    this.selectedDays.splice(selectedIndex, 1);
                }
            },
            isSelected(index) {
            return this.selectedDays.includes(index);
            },

            orderCreate(){               
                AxiosServices.patientOrdersCreate(this.$route.params.id,this.order).then(res => {                    
                    this.$store.dispatch("addNotification", res.data)
                    this.dialog_order=false
                    this.$emit('updateOrders')                    
                }).catch(error => {
                    console.log('error de creación',error);
                })
            },                  
            orderUpdate(patient_id,order_id,order){                
                AxiosServices.patientOrdersUpdate(patient_id,order_id,order).then(res => {
                    this.$store.dispatch("addNotification", res.data)
                    this.dialog_order = false
                    this.$emit('updateOrders')
                }).catch(error => {
                    console.log('el error es este',error);
                })
            },
            saveAction(){
                this.disabledButton = true
                if (this.order.frequency_type == 0) {
                    this.order.days = [0, 1, 2, 3, 4, 5, 6]
                }else{

                    this.order.days = this.selectedDays.sort((a, b) => a - b);
                }
                if (this.$props.createform){
                    this.orderCreate()
                }else{
                    this.orderUpdate(this.$route.params.patient_id,this.$route.params.order_id,this.order)
                }
                this.close()
            },          
            close(){
                this.disabledButton=false
                this.dialog_order = false
                this.$store.dispatch("reseterror")
                this.order_date = undefined
                this.order_time = ''
                this.typeOrder = ''
                this.medicineName=''
                if (this.createform) {                    
                    this.showForm = false
                    this.order = { order_type: 0, notify:true, only: true, time_unit: 0, frecuency: 1, dose: 1, during: 1, medicine_id: undefined, medicine_name: '',amount:'1',medication_unit:3,medication_unit_name:'' }
                } else {
                    this.getOrder
                }
            },             
            getOrder(){
                this.$store.dispatch("reseterror")
                this.disabledButton=false
                if(!this.$props.createform ){
                    AxiosServices.patientOrdersDetail(this.patient_id, this.$route.params.order_id).then(res => {
                        this.order = res.data.data
                        if (this.order.order_type == 0 ){
                            this.order.clinical_element = res.data.data.clinical_element_id
                            this.selectedDays = this.order.days
                        }
                    }).catch((error) => {
                        this.$store.dispatch("addNotification", error.response.data);
                        this.$store.dispatch("render404");
                    })                    
                }
            },
            selectType(type, name){
                this.$store.dispatch("reseterror")
                this.order = { order_type: 0, notify:true, time_unit: 0, frecuency: 1, dose: 1, during: 1 }
                this.order.order_type=type
                this.typeOrder=name
                this.showForm = true
                if(type==1){
                    this.order['medicine_name']=''
                }
            },
            getMedicine(id){
                if(id!=undefined){
                    AxiosServices.medicinesDetail(id)
                    .then(res => {
                        this.medicineName=res.data.data.name
                    })
                    .catch(error => {
                        console.log('el error es',error);
                    })
                }
            },
            complemento(cantidad){
                var complemento = ''
                if (cantidad == '1/4') {
                    complemento = 'de'
                }
                return complemento
            },
            getPermission(){
                AxiosServices.patientDetail(this.patient_id).then(res => {
                    this.permissions=res.data.data.permissions
                }).catch(error => {
                    console.log('el error es', error);
                })
            },
        },   
        watch: {
            'order.medicine_id'(newValue, oldValue) {
                if (newValue != oldValue && newValue != undefined){
                    this.getMedicine(newValue)
                }
            }
        },     
        computed: {
            unit: function () {
                var unidad = this.order.dose
                if (this.order.order_type == 'Medicamentos') {
                    if (this.order.unit_time=='Horas') {
                        if (this.order.frecuency.length>0 && this.order.during>0 && this.order.dose>0) {
                            unidad=parseInt(((24 / this.order.frecuency) * this.order.during)*this.order.dose)
                        }
                    }
                }
                return unidad
            },          
            nameMedicine:function(){
                let value = this.medicineName
                if (this.order.type == 1 && this.order.medicine_name.length > 0){
                    value = this.order.medicine_name
                }
                return value
            },
            verror: function () {
                return this.$store.state.form.reserror
            },
            requiredFrecuency:function(){
                let value = 'required' // 'required'
                if (this.order.frequency_type != 1){
                    value = 'none'
                }
                return value
            },
            act_unica: function () {
                var unic = "none"
                if (this.order.order_type == 'Terapias' && (this.order.only == false || this.order.only == null)) {
                    unic='quantityRequired'
                }
                return unic
            }
        },
        components: {
            BaseInput,
            SelectSearch,
            BaseSelectMult,
            BaseSelect,
            BaseCheckbox,
        }
    }
</script>
<style >

.theme--light.btn-type-order.v-btn--has-bg {
    background-color:rgb(147, 165, 174);
    color: rgb(59, 59, 59);
}
.theme--light.btn-type-order:hover.v-btn--has-bg, .theme--light.btn-type-order:active.v-btn--has-bg {
    background-color:rgb(56, 130, 167);
    color: rgb(243, 243, 243);
}
.timefield{
    padding-top: 0px;
    margin-top: -5px;
}
.f-unidad .v-input{
    margin-top: -1px;
}
.day-box2 {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 3px;
    text-align: center;
    line-height: 15px;
    font-size: 10px;
    font-weight: bold;
    color: #a5c2d5;
    border-radius: 2px;
    border: 1px solid #a5c2d5;
}
.blue2 { 
    background-color: #3498db; 
    color: white;
}
</style>
