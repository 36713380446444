    <template>
        <div> 
            <v-row>
                <v-col>
                    <TitleView title="Etiquetas"></TitleView>
                </v-col>
                <v-col class="d-flex align-end flex-column">
                    <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="600" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="indigo" v-bind="attrs" v-on="on"  @click="getdispatch" dark>
                                Crear etiqueta &nbsp; <v-icon>mdi-plus-circle-outline</v-icon> 
                            </v-btn>
                        </template>
                        <template v-slot:default>
                            <v-card>
                                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Nueva etiqueta</v-toolbar>
                                <v-card-text>
                                    <v-col cols="12" class="mt-5">
                                        <v-row>
                                            <v-col cols="12" sm="6" class="pt-0 pb-0">
                                                <BaseInput v-model="tag.name" type="text" label="Etiqueta" :outlined="true" validador="textRequired" element="element" ></BaseInput>
                                            </v-col>                                       
                                            <v-col cols="12" class=" pt-0 pb-0">
                                                <label class="text-body-2 ml-1 pb-2 pa-0 color-text-glya">Seleccione un color para su nueva etiqueta</label>
                                                <v-row class="d-flex justify-space-around">
                                                    <div class="mt-3 " v-for="color in temporales.colores" :key="color" validador="required">
                                                        <v-col class="pa-1">
                                                            <v-btn validador="required" fab x-small type="button" name="button" elevation="0" @click="click_color(color)" :color="tag.color==color || tag.color=='' ?  color : 'grey lighten-2'" ></v-btn>
                                                        </v-col>
                                                    </div>
                                                </v-row>
                                            </v-col> 
                                            <v-col cols="12" v-if="show_alert == true" class="v-messages theme--light error--text" role="alert">
                                                <div class="v-messages__wrapper">
                                                    <div class="v-messages__message ml-3">¡El campo es requerido!</div>
                                                </div>
                                            </v-col> 
                                        </v-row>                                            
                                    </v-col>                                         
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="justify-end mt-3">
                                    <v-btn @click="dialog=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                    <v-btn v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    <v-btn v-else @click="create_tag" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
            </v-row>    

            <v-col>           
                <v-data-table :headers="headers" :items="tags" :server-items-length="totalItems" class="elevation-1 GdiTable" item-key="id"
                :footer-props="{'items-per-page-options': [5, 10, 20],'items-per-page-text': 'Alertas por página'}" @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                    <template v-slot:[`item.name`]="{item}">
                        <v-chip class="pl-3 pr-3" dark :color="item.color"> {{item.name}}</v-chip>
                    </template>                    
                </v-data-table>
            </v-col>

        </div> 
    </template>

    <script>
        import BaseInput from '@/components/forms_items/BaseInput.vue'
        import TitleView from '@/components/views/TitleView.vue'
        // import DialogShowTag from '@/components/views/DialogShowTag.vue'
        import AxiosServices from '@/services/AxiosServices'
        export default {
        
            mounted(){
                this.get_tags()
            },
            
            data(){
                return{  
                    headers: [
                        {text: 'Nombre', value: 'name', active: true, sortable: false}
                    ],
                    dialog:false,
                    temporales:{
                        colores:['black','red','pink','purple','indigo', 'blue', 'light-green', 'amber', 'orange', 'blue-grey'],        
                    },
                    tag: {color:''},
                    tags: [],
                    on:false,
                    show_alert:false,
                    totalItems: 10,
                    per_page:10,
                    page:1,
                }
    
            },
            methods:{ 
                updatePage(page){
                    this.page = page
                    this.get_tags()
                },
                updateItemsPerPage(per_page){
                    this.per_page = per_page
                    this.page = 1
                    this.get_tags()
                },
                click_color: function(color){
                    this.tag.color=color
                    this.show_alert=false
                } ,
                create_tag: function(){
                    this.$store.dispatch("show_required")
                    if (!this.verror && this.tag.color != '') {
                        AxiosServices.clientTagsCreate(this.tag).then(res => {                            
                            this.$store.dispatch("addNotification", res.data)
                            this.dialog = false
                            this.clearForm()
                        }).catch(error =>{
                            console.log('el error es ',error);
                            this.$store.dispatch("addNotification", error.response.data)
                            this.dialog = false
                        })                        
                    } else {
                        this.$store.dispatch("show_required")
                        this.show_alert=true
                    }
                    // this.$router.push({ name: 'patient', params: { id: this.$route.params.id, reload: true } }).catch(() => { }) 
                },
                getdispatch:function(){
                    this.$store.dispatch("reseterror")
                    // this.$store.dispatch("putValidations")
                },
                get_tags: function () {
                    AxiosServices.clientTagsList({page: this.page, per_page: this.per_page}).then(res => {
                        this.tags=res.data.data.data
                        this.totalItems = res.data.data.total
                        this.page = res.data.data.page
                    }).catch(error => {
                        console.log('el error trae', error);
                    })
                },
                clearForm:function(){
                    this.tag.name = ''
                    this.tag.color = ''
                    this.get_tags()
                },
            },
            computed: {
                verror:function(){
                    return this.$store.state.form.reserror
                }
            },
            components:{
                TitleView,
                BaseInput
            }
        }
</script>
    
