<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-btn color="indigo lighten-2 white--text" small @click="fetchdata">
          Recargar </v-btn
        >
      </v-col>
      <!-- <v-col class="ml-5 mr-5 mt-1">
        <v-row>
          <v-col v-for="state in status" :key="state.value" cols="12" sm="auto">
            <p class="text-body-2">
              <v-icon class="pr-2" :color="state.color">mdi-circle</v-icon
              >{{ state.name }}
            </p>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
    <div v-if="empty">
      <v-col cols="12" class="d-flex align-center flex-column message-no-data pa-0">
        <v-icon size="50">mdi-chart-timeline-variant</v-icon>
        <h3>No existen datos que mostrar</h3>
      </v-col>
    </div>
    <v-row :class="empty ? 'nodisplay' : ''">
      <v-col cols="12" md="6" lg="8">
        <div ref="chartdiv" class="graph1"></div>
      </v-col>
      <v-col cols="12" md="6" lg="4" >
          <v-card class="mt-5" elevation="1">
          <v-card-title class="pb-0">  
              <div>
                  <h4>Mediciones posteriores ({{ measurementsAfterAlert.length }})</h4>
              </div>             
          </v-card-title>
          <v-card class="pa-5 scrollable2" elevation="0" >                   
              <v-col class="pa-0 ma-0">
                  <div cols="12" class="pa-0 text-body-2" v-for="(val, index) in measurementsAfterAlert" :key="index">
                      <span> <b class="color-text-glya mr-2">{{ index + 1 }}.</b>
                        <v-icon v-if="val.id == measurement_id" color="error">mdi-flag</v-icon>
                      <v-chip outlined color="primary" small class="pa-0 pl-3 pr-3"> 
                        <!-- ***{{ val.id }}  | {{ measurement_id }} ***** -->
                          <span v-if="presion">{{ val.value }}/{{ chartdata2[index].value }} {{ val.unit }}</span>
                          <span v-else>{{ val.value }} {{ val.unit }}</span>
                      </v-chip>
                      </span>
                      <br>
                      <span><v-icon class="mr-1" small>mdi-calendar-clock-outline</v-icon> {{ new Date(val.date ) | formatDate }}</span>
                      <v-divider class="mt-1 mb-2"></v-divider>
                  </div>                   
              </v-col>                  
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AxiosServices from "@/services/AxiosServices";

export default {
  name: "patientMeasures",
  data() {
    return {
      empty: true,
      alldata: [],
      alldata2: [],
      measurementsAfterAlert: [],
      measurementsAfterAlert2: [],
      last10measurements: [],
      last10measurements2: [],
      chartdata: [],
      chartdata2: [],
      status: [
        { color: "primary", name: "Prioridad Baja" },
        { color: "yellow darken-3", name: "Prioridad Media" },
        { color: "error", name: "Prioridad Alta" },
      ],
      presion: this.type == "3" || this.type == "4",
      errorcompuesto: false,
    };
  },
  mounted() {   
    setTimeout(() => {
      this.fetchdata();
    }, 500); 
    this.graph2();
  },
  props: {
    pid: {},
    type: {},
    type_tab: { default: 1 },
    load: {},
    date: {},
    alert: { default: false },
    measurement_id: {},
  },
  methods: {
    graph2: function () {
      let root = am5.Root.new(this.$refs.chartdiv);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          pinchZoomX: true,
          pinchZoomY: false,
          panX: true,
          wheelY: "zoomX",
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
        })
      );
      chart.leftAxesContainer.set("layout", root.verticalLayout);

      //COLORES
      let gray1 = am5.color("#888");

      // Creo X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "minute", count: 1 },
          start:-1,
          end:1,
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      xAxis.set(
        "tooltip",
        am5.Tooltip.new(root, {
          themeTags: ["axis"],
        })
      );

      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.8em",
      });
      //xAxis.data.setAll(data);
      xRenderer.grid.template.set("location", 0.5);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            behavior: "selectX",
          }),
          x: am5.p100,
          centerX: am5.p100,
          marginTop: 0, // this makes gap between axes
        })
      );
      let yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.8em",
      });

      // Creo series
      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          fill: am5.color(0x2f95ba),
          stroke: am5.color(0x2f95ba),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueX.formatDate()}: {valueY}",
          }),
        })
      );
      series1.strokes.template.setAll({
        strokeWidth: 1,
        strokeDasharray: [3, 1],
      });

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          snapToSeries: [series1],
          snapToSeriesBy: "x",

          //behavior: "selectX",
        })
      );

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: gray1,
          }),
        });
      });

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: gray1,
            fontSize: "0.7em",
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            dx: 12,
            dy: 12,
            populateText: true,
          }),
        });
      });

      series1.data.processor = am5.DataProcessor.new(root, {
        numericFields: ["value"],
        dateFields: ["date"],
        dateFormat: "yyyy-MM-dd HH:mm:ss",
      });

      if (this.presion) {
        var yAxis2 = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
              behavior: "selectX",
            }),
            x: am5.p100,
            centerX: am5.p100,
            marginTop: 40,
          })
        );

        let yRenderer2 = yAxis2.get("renderer");
        yRenderer2.labels.template.setAll({
          fill: am5.color("#777"),
          fontSize: "0.8em",
        });
        let series2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Series2",
            xAxis: xAxis,
            yAxis: yAxis2,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueX.formatDate()}: {valueY}",
            }),
          })
        );

        series2.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: gray1,
            }),
          });
        });

        series2.data.processor = am5.DataProcessor.new(root, {
          numericFields: ["value"],
          dateFields: ["date"],
          dateFormat: "yyyy-MM-dd HH:mm:ss",
        });
        //xAxis.set("layer", 50);
        series2.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: gray1,
              fontSize: "0.7em",
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              dx: 12,
              dy: 12,
              populateText: true,
            }),
          });
        });
        this.series2 = series2;
        this.yAxis2 = yAxis2;
      }
      //series1.data.setAll(data);

      //chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

      // Add cursor
      //chart.set("cursor", am5xy.XYCursor.new(root, {}));

      this.root = root;
      this.series1 = series1;
      this.xAxis = xAxis;
      this.yAxis = yAxis;
    },
    changeToMeasurementsAfterAlert: function () {
      // this.chartdata=this.measurementsAfterAlert
      // this.chartdata2=this.measurementsAfterAlert2
      this.fetchdata("afterAlert");
    },
    changeTolast10Measurements: function () {
      // this.chartdata=this.filterLast10Measuerements(this.alldata)
      // this.chartdata2=this.filterLast10Measuerements(this.alldata2)
      this.fetchdata("last10");
    },
    changeAllMeasurements: function () {
      // this.chartdata=this.alldata
      // this.chartdata2=this.alldata2
      this.fetchdata("all");
    },
    setMeasurementsAfterAlert: function (array) {
      const alertDate = new Date(this.$props.date).getTime();
      const filterMeasuements = array.filter((measurement) => {
        const measurementWhen = new Date(measurement.date).getTime();
        return measurementWhen >= alertDate;
      });
      filterMeasuements.sort((a, b) => new Date(a.date) - new Date(b.date));
      return filterMeasuements;
    },
    filterLast10Measuerements: function (array) {
      return array.slice(0, 10);
    },
    fetchdata: async function (type = "all") {
      if (this.presion) {
        AxiosServices.patientMeasurementsList(this.pid, 3).then((res) => {
          let data3 = res.data.data;
          AxiosServices.patientMeasurementsList(this.pid, 4).then((res) => {
            let data4 = res.data.data;
            if (data4.length == data3.length) {
              this.empty = false;
              let rp1 = [];
              let rp2 = [];
              for (var c = 0; c < data4.length; c++) {
                rp1.push({
                  date: data3[c].when,
                  value: Number(data3[c].value),
                  unit: data3[c].clinical_element_unit,
                  id: data3[c].id,
                });
                rp2.push({
                  date: data4[c].when,
                  value: Number(data4[c].value),
                  unit: data4[c].clinical_element_unit,
                  id: data4[c].id,
                });
              }
              this.alldata = rp1;
              this.alldata2 = rp2;
              this.measurementsAfterAlert = this.setMeasurementsAfterAlert(this.alldata);
              this.measurementsAfterAlert2 = this.setMeasurementsAfterAlert(
                this.alldata2
              );
              this.last10measurements = this.filterLast10Measuerements(this.alldata);
              this.last10measurements2 = this.filterLast10Measuerements(this.alldata2);
              if (type == "last10") {
                this.chartdata = this.filterLast10Measuerements(this.alldata);
                this.chartdata2 = this.filterLast10Measuerements(this.alldata2);
              } else if (type == "afterAlert") {
                this.chartdata = this.measurementsAfterAlert;
                this.chartdata2 = this.measurementsAfterAlert2;
              } else {
                this.chartdata = this.alldata;
                this.chartdata2 = this.alldata2;
              }
              this.yAxis.children.push(
                am5.Label.new(this.root, {
                  text: "Sistólica \n\r mmHg",
                  rotation: -90,
                  fontSize: "0.7em",
                  y: am5.p50,
                  centerX: am5.p50,
                  x: -30,
                })
              );
              this.yAxis2.children.push(
                am5.Label.new(this.root, {
                  text: "Diastólica \n\r mmHg",
                  rotation: -90,
                  fontSize: "0.7em",
                  y: am5.p50,
                  centerX: am5.p50,
                  x: -30,
                })
              );
              this.series1.data.setAll(this.chartdata);
              this.series2.data.setAll(this.chartdata2);
              console.log("entro ciclo alertas presion");
              for (let n in [3, 4]) {
                AxiosServices.patientAlarmsList(this.pid, this.type).then((res) => {
                  let alarms = res.data.data;
                  n == 3 ? this.yAxis.axisRanges.clear() : this.yAxis2.axisRanges.clear();
                  if (alarms.length > 0) {
                    for (let alarm of alarms) {
                      if (alarm.clinical_element_id == this.type)
                        this.createGraphAlarm(alarm, n == 3 ? this.yAxis : this.yAxis2);
                    }
                  }
                });
              }
            } else {
              this.errorcompuesto = true;
            }
          });
        });
      } else {
        AxiosServices.patientMeasurementsList(this.pid, this.type).then((res) => {
          let data2 = res.data.data;
          if (data2.length > 0) {
            this.empty = false;
            let rp = [];
            for (let item of data2) {
              rp.push({
                date: item.when,
                value: Number(item.value),
                unit: item.clinical_element_unit,
                id: item.id,
              });
            }
            this.alldata = rp;
            this.measurementsAfterAlert = this.setMeasurementsAfterAlert(this.alldata);
            this.last10measurements = this.filterLast10Measuerements(this.alldata);
            if (type == "last10") {
              this.chartdata = this.filterLast10Measuerements(this.alldata);
            } else if (type == "afterAlert") {
              this.chartdata = this.measurementsAfterAlert;
            } else {
              this.chartdata = this.alldata;
            }
            this.series1.data.setAll(this.chartdata);
            this.yAxis.children.push(
              am5.Label.new(this.root, {
                text: data2[0].clinical_element_unit,
                rotation: -90,
                fontSize: "0.7em",
                y: am5.p50,
                centerX: am5.p50,
                x: -30,
              })
            );
          } else {
            this.empty = true;
          }
        });
        AxiosServices.patientAlarmsList(this.pid, this.type).then((res) => {
          let alarms = res.data.data;
          this.yAxis.axisRanges.clear();
          if (alarms.length > 0) {
            for (let alarm of alarms) {
              if (alarm.clinical_element_id == this.type)
                this.createGraphAlarm(alarm, this.yAxis);
            }
          }
        });
      }
    },
    createGraphAlarm: function (alarm, axis) {
      let colors = {
        1: am5.color(0xeb4034),
        2: am5.color(0xeba134),
        3: am5.color(0xf2e550),
      };
      let rangeDataItem = axis.makeDataItem({
        value: alarm.minimum_condition,
        endValue: alarm.maximum_condition,
      });
      let rangeDataend = axis.makeDataItem({
        value: alarm.maximum_condition,
      });

      let range = axis.createAxisRange(rangeDataItem);
      let rangeend = axis.createAxisRange(rangeDataend);

      range.get("grid").setAll({
        stroke: colors[alarm.priority],
        strokeOpacity: 1,
        strokeWidth: 2,
      });
      rangeend.get("grid").setAll({
        stroke: colors[alarm.priority],
        strokeOpacity: 1,
        strokeWidth: 2,
      });

      range.get("axisFill").setAll({
        fill: colors[alarm.priority],
        fillOpacity: 0.15,
        visible: true,
      });
      range.get("label").setAll({
        fill: am5.color(0x00000),
        fillOpacity: 0.25,
        //background:am5.Rectangle.new(this.root, {fill:colors[alarm.priority],fillOpacity: 1}),
        text: alarm.name_only,
        visible: true,
        location: 0.1,
        fontSize: 20,
        fontWeight: "bold",
        inside: true,
        centerX: am5.p0,
      });
      rangeend.get("label").setAll({
        fill: am5.color(0x00000),
        fillOpacity: 0.25,
        //background:am5.Rectangle.new(this.root, {fill:colors[alarm.priority],fillOpacity: 1}),
        text: alarm.name_only,
        visible: true,
        location: 0.5,
        fontSize: 20,
        fontWeight: "bold",
        inside: true,
        centerX: am5.p0,
        //centerY:am5.p90
      });
    },
    /*
      range.get("label").setAll({
        fill: am5.color(0xffffff),
        fillOpacity:0.8,
        background:am5.Rectangle.new(this.root, {fill:colors[alarm.priority],fillOpacity: 1}),
        text: alarm.name_only,
        visible:true,
        location: 0.5,
        fontSize: 11,
        fontWeight:"bold",
        inside: true,
        centerX: am5.p0,
        centerY: am5.p100
      });
    }*/
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.graph1 {
  min-height: 150px;
  height: 500px;
}

.nodisplay {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  /* Define el inicio de la rotación */
  100% {
    transform: rotate(360deg);
  }

  /* Define el final de la rotación */
}
</style>
