<template>
    <section>
        <TitleView title="Reportes"></TitleView>

        <v-col cols="12">
            <div>
                <v-card class="mx-auto pa-5">
                    <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                        <span>
                            Descarga de todas las mediciones CSV
                        </span>
                    </v-card-title>
                    <v-row class="mt-3">
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-0" >
                            <BaseSelectMult v-model="hours" type="text" label="Núimero de Horas anteriores " list_type="number_of_hours"></BaseSelectMult>
                        </v-col> 
                        <v-col cols="auto" class="pt-0 pb-0">
                            <v-btn @click="getReport"  class="pl-5 pr-5 white--text" depressed color="green lighten-1" small >Descargar CSV <v-icon right dark > mdi-file-excel</v-icon> </v-btn>
                        </v-col>
                    </v-row>   
                </v-card>
            </div>     
        </v-col>


    </section>
</template>

<script>
    import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";
    import TitleView from "@/components/views/TitleView.vue";
    import AxiosServices from "@/services/AxiosServices";
    
    export default {
        name: 'IndexReports',
        mounted(){
        },
        data:function() {
            return {    
                hours:1,
                url_csv:""
            }
        },
        methods: {
            getReport: function () {
                AxiosServices.clientExportCSV(this.$store.state.user.client_id, {hours: this.hours}).then( res => {
                    console.log('trae:',res.data);
                    this.url_csv = res.data.data.url
                    window.open(this.url_csv, '_blank');
                }).catch(error => {
                    console.log('el error en traer csv es:',error);
                })
            }
        },
        components: {
            BaseSelectMult,
        TitleView
        },
    }
</script>