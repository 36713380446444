<template>
    <div>
        <v-alert outlined class="pt-0 pb-1 mb-0 alert_generate" :color="colorPriority(alert.alarm.priority)" @click="goAlert(alert,patient_id)">
            <v-col class="pt-0">
                <v-row class="mt-0">
                    <v-col class="color-text-glya pa-0" cols="12">
                        <div v-if="source != 'patient'">
                            <h5 :class="colorPriority(alert.alarm.priority)+'--text'" class="capitalize"><v-icon :color="colorPriority(alert.alarm.priority)" small>mdi-account</v-icon><span>{{ alert.patient.full_name }}</span></h5>
                        </div>
                        <h5 :class="source == 'patient' ? colorPriority(alert.alarm.priority)+'--text': ''">{{ alert.alarm.name_only }}</h5>
                    </v-col> 
                    <v-col class="color-text-glya pa-0 separator-line" cols="12" sm="8">
                        <table class="table-info-alert">
                            <tr>
                                <th><v-icon small :color="colorPriority(alert.alarm.priority)">{{iconalerta(alert.measurement.clinical_element)}}</v-icon></th>
                                <th class="text-body-2"><span class="text-caption">{{alert.measurement.clinical_element_name}}</span></th>
                            </tr>
                            <tr>
                                <th><v-icon small>mdi-calendar</v-icon></th>
                                <th class="text-body-2"><span class="text-caption">{{ alert.measurement.when | formatDateSimple }} 
                                    <v-tooltip bottom v-if="alert.time_zone_patient != user.time_zone">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="indigo" small dark v-bind="attrs" v-on="on">
                                            mdi-web
                                            </v-icon>
                                        </template>
                                        <span>{{alert.time_zone_patient}} </span>
                                    </v-tooltip>
                                </span></th>
                            </tr>
                            <tr>
                                <th><v-icon small>mdi-doctor</v-icon></th>
                                <th class="text-body-2"><span class="text-caption"><b>{{alert.user_id !== null ? alert.user_name : 'Sin responsable'}}</b></span></th>   
                            </tr>
                        </table>
                        <!-- <div class="resp-glady">
                            <img src="../../assets/Glady-color.svg" alt="Glady" width="40">
                        </div> -->
                        <div class="resp-glady">
                            <div v-if="alert.user_id !== null">
                                <v-col class="user-bubble ma-0 pa-0" :class="colorPriority(alert.alarm.priority)">{{getInitials(alert.user_name)}}</v-col>
                            </div>
                            <div v-else>
                                <div v-if="source == 'patient'" class="no_user">
                                    <v-icon size="20" color="white">mdi-account-alert</v-icon>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="d-flex flex-column justify-center align-center val-alert pa-0">
                        <h3>{{ alert.measurement.value }}<span class="element-clnc pa-0 ma-0 black--text">{{ alert.measurement.clinical_element_unit }}</span></h3>
                        <p><v-chip :color="colorPriority(alert.alarm.priority)" small outlined >{{alert.alarm.minimum_condition  }} - {{ alert.alarm.maximum_condition }}</v-chip></p>
                    </v-col>
                </v-row>                               
            </v-col>
        </v-alert>
            <div>
                <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog" persistent>
                    <template v-slot:default>
                        <v-card>
                            <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Gestionar alerta</v-toolbar>
                            <v-card-text class="mt-3 pb-0">
                                <!-- {{userAssign}} -->
                                <v-col class=" text-center" v-if="user_id== null">
                                    <p>La alerta actual no tiene responsable, recuerda que se debe realizar el seguimiento de estas alertas, para tener un mejor monitoreo y gestión sobre la salud de tus pacientes.</p>
                                    <v-col cols="12">
                                        <SelectSearch label="Seleccionar un responsable" model="users" v-model="user_id" validador="required" :valuex="alert.user_id == null ? 1 : {id:alert.user,text:alert.user_name}"></SelectSearch>
                                    </v-col>
                                </v-col>
                                <v-col class="mt-9 pa-0" v-else>
                                    <v-btn @click="back" x-small class="up-button indigo--text ml-3" elevation="0" color="indigo lighten-4 pa-3">
                                        <v-icon small>mdi-arrow-u-left-top</v-icon>
                                        Volver</v-btn>
                                        <v-col>

                                            <v-alert outlined color="purple">
                                                <div class="text-center">
                                                    <p class="mb-0">La alerta será asignada a  <b class="capitalize"> {{userAssign.first_name}} {{userAssign.last_name}}</b></p>
                                                </div>
                                            </v-alert>
                                        </v-col>
                                </v-col>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-space-between">
                                <v-btn @click="close" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cancelar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                <v-btn disabled v-if="disabledButton" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Asignar alerta <v-icon right dark > mdi-check</v-icon></v-btn>
                                <v-btn v-else @click="assignAlert" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Asignar alerta <v-icon right dark > mdi-check</v-icon></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>                
            </div>
        <v-col class="pa-0 text-body-2 text-center time_alert" :class="colorPriority(alert.alarm.priority)">
            <div class="show_id">ID:{{alert.id}}</div>
            <span class="white--text"><v-icon color="white" small>mdi-clock</v-icon> <b>{{ getTimer(alert.measurement.when) }} </b></span>
        </v-col>
    </div>
</template>
<script>
import SelectSearch from '../forms_items/SelectSearch.vue';
import AxiosServices from '@/services/AxiosServices';
export default {
    name:"CardAlert",
    mounted(){
        this.setAlert()
    },
    data() {
        return {
            alert: this.$props.alert_prop,
            dialog:false,
            user_id:null,
            userAssign:{},
        };
    },
    props:{
        alert_prop:{},
        patient_id:{},
        source:{default:'patient'}
    },
    methods:{
        back:function(){
            this.user_id=null
            // this.selfAssign=''
        },
        close:function(){
            this.user_id=null,
            // this.selfAssign=''
            this.dialog=false
        },
        getAlert:function(){
            AxiosServices.patientAlertsDetail(this.$props.patient_id,this.alert.id)
            .then(res => {
                this.alert=res.data.data
                this.goAlert(this.alert,this.$props.patient_id)
            }).catch(error => {
                console.log('el error es ',error);
            })
        },
        assignAlert:function(){
            if(this.user_id!=null){
                let alert={}
                alert['user_id']=this.user_id
                this.updateAlert(alert)
            }else{
                this.close
            }
        },
        getNameUser:function(userId){
            
        AxiosServices.userDetail(userId).then(res => {
                    this.userAssign=res.data.data
        }) 
        },
        updateAlert:function(alert){
            AxiosServices.patientAlertsUpdate(this.$props.patient_id,this.alert.id,alert)
            .then(res => {
                this.$store.dispatch("addNotification", res.data)
                this.getAlert()                    
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
            })
        },
        setAlert:function(){
            this.alert=this.$props.alert_prop
        },
        goAlert:function(alert,patient_id){  
            setTimeout(() => {
                if (this.alert.user_id == null) {
                this.dialog=true
            } else {
                this.$router.push({name:"alert",params:{alert_id:alert.id,patient_id:patient_id,source:this.$props.source}})
            }   
            }, 500); 
        },
        iconalerta: function (color) {
            switch (color) {
                case "programada":
                    return "mdi-bell-ring";
                case 0:
                    return "mdi-heart-pulse";
                case 1:
                    return "mdi-heart-pulse";
                case 2:
                    return "mdi-lungs";
                case "fr":
                    return "mdi-lungs";
                case 3:
                    return "mdi-pulse";
                case 4:
                    return "mdi-pulse";
                case 6:
                    return "mdi-water";
                case 5:
                    return "mdi-thermometer";
            }
        },
        colorPriority: function (priority) {
            switch (priority) {
                case 1:
                    return "error";
                case 2:
                    return "orange";
                case 3:
                    return "primary";
                default:
                    return "black";
            }
        },
        reloadAlert:function(){
            this.$emit('reloadAlerts');
        },
        getTimer: function (dateTime) {
            var show = [];
            if (this.alert.time_zone_patient == null) {
                show = []
            }else{
                var currentTime = new Date(new Date().toLocaleString("en-US", { timeZone: this.alert.time_zone_patient }))
                var alertTime = new Date(dateTime);
                var difference = (currentTime - alertTime);
                var days = Math.floor(difference / (1000 * 60 * 60 * 24));
                var hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                if (days > 0) {
                    show.push(`${days} dias `);
                }
                if (hours > 0) {
                    show.push(`${hours} horas `);
                }
                if (minutes >= 0) {
                    show.push(`${minutes} minutos `);
                }
                if (show.length > 0) {
                    show = show.toString();
                }
            }
            return show;
        },
        getInitials: function (name){
            
        const iniciales = name.split(" ").slice(0, 2).map(nombre => nombre.charAt(0)).join("")
        return iniciales
        }
    },
    computed: {
            user:function(){
                return this.$store.state.user
            },
        disabledButton() {
            let disabled = true
            if(this.user_id != null){
                disabled=false
            }else{
                disabled=true
            }
            return disabled
        }
    },
    watch:{
        alert_prop(newValue,oldValue) {
            if (newValue !== oldValue) {
                this.setAlert(newValue)
            }
        },
        user_id(newUserId) {
            if (newUserId !== null) {
        // Realiza una llamada Axios cuando se selecciona un usuario
                this.getNameUser(newUserId);
            } else {
        // Puedes manejar lo que sucede cuando el usuario selecciona una opción vacía aquí
        // Por ejemplo, puedes restablecer this.userData a null
                this.user_id = null;
            }
        },     
    },    
    components:{
        SelectSearch
    }
}
</script>
<style>
    .element-clnc{
        font-size: 10px;
        font-weight: 400;
    }
    .separator-line{
        border-right: 1px solid rgba(108, 108, 108, 0.2);
    }
    .val-alert{
    min-width: 60px;
    }
    .table-info-alert tr th{
        text-align: initial;
    }
    .alert_generate{
        cursor:pointer;
    }
    .no_user {
        width: 28px;
        height: 28px;
        background: #ff6000;
        border-radius: 50%;
        padding:2px 0px 0px 4px;
        z-index: 2;
        animation: bounce 0.6s ease-in infinite;
    }
    @keyframes bounce {
        50% {
            transform: translateY(-3px);
        }
    }
    .show_id{
        position: absolute;
        top: -16px;
        font-size: 10px;
        background: #f2eded;
        right: 2px;
        color: #757474;
        height: 16px;
        border-radius: 3px;
        padding: 0px 5px;
    }
    
    
    .user-bubble {
        width: 28px !important;
        height: 28px;
        color: white;
        font-size: 14px;
        font-weight:400;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .capitalize{
        text-transform: capitalize;
    }
    .resp-glady{
        position: absolute;
        top: -10px;
        right: -10px;
    }
    .up-button{
        position: absolute !important;
        top: 60px;
    }
    @media (max-width: 600px) {
        .separator-line{
        border-right: none
    }
}
</style>