<template>
    <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!permissions.edit" icon :color="edit ? 'indigo' : 'white'" :disabled="!permissions.edit">
                <v-icon size="30px">mdi-{{edit ? 'pencil-circle' : 'plus-circle'}}</v-icon>
            </v-btn>
            <v-btn v-else icon v-bind="attrs" v-on="on" :color="edit ? 'indigo' : 'white'" @click="getdispatch">
                <v-icon size="30px">mdi-{{edit ? 'pencil-circle' : 'plus-circle'}}</v-icon>
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card>
            
                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{modal_name}}</v-toolbar>
                <v-card-text>                    

                        <v-col cols="12" class="mt-5">                          
                            <v-row>
                                <v-col cols="12" sm="3" class="pt-0 pb-0">
                                    <SelectSearch label="Pais Residencia" model="countries2" v-model="caregiver.country_id" validador="required" @label_search="get_searching"
                                        :valuex="caregiver.country_id == null ? {id: country_id_default,text:country_name_default} : {id:caregiver.country_id,text:caregiver.country_name} "></SelectSearch>
                                </v-col>
                                <v-col cols="12" sm="3" class="pt-0 pb-0">
                                    <BaseSelect v-model="caregiver.id_type" type="text" list="id_types" :country="caregiver.country_id == null ? country_id_default : caregiver.country_id" validador="required" label="Tipo ID" @update_validations="nid_validation_reloaded=nid_validation_reloaded + 1"></BaseSelect>
                                </v-col>
                                <v-col cols="12" sm="6"  class="pt-0 pb-0">
                                    <BaseInput  v-model="caregiver.nid" type="text" label="Número de identificación" validador="nid" element="element" transform="text-uppercase" :reload_validation="to_reload_validation"></BaseInput>
                                </v-col>
                                <!-- <v-col cols="12" sm="3"  class="pt-0 pb-0">
                                    <v-btn min-height="45" disabled v-bind="attrs" v-on="on" block class=" white--text" depressed color="blue darken-4" small > Validar cuidador</v-btn>
                                </v-col>  -->
                                <v-col cols="12" sm="6" class="pt-0 pb-0" >
                                    <BaseInput  v-model="caregiver.first_name" type="text" validador="namesSurnamesRequired" label="Nombres" element="element" transform="text-uppercase"></BaseInput>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0" >
                                    <BaseInput  v-model="caregiver.last_name" type="text" label="Apellidos" validador="namesSurnamesRequired" element="element" transform="text-uppercase"></BaseInput>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <BaseInput  v-model="caregiver.email" type="text" label="Correo Electronico" validador="emailRequired" element="element" ></BaseInput>
                                </v-col>
                                <v-col cols="12" sm="2" class="pt-0 pb-0">
                                    <SelectSearch label="Indicativo" model="prefixes" v-model="caregiver.prefix" :prefixes="true" validador="required"
                                    :valuex="caregiver.prefix == null ? {id:prefix_default,text:prefix_default} : {id:caregiver.prefix, text:caregiver.prefix}"></SelectSearch>                                                            
                                </v-col>
                                <v-col cols="12" sm="4" class="pt-0 pb-0">
                                    <BaseInput  v-model="caregiver.cellphone" type="text" label="Teléfono (WhatsApp)" validador="phoneRequired" element="element" ></BaseInput>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <v-checkbox
                                        v-model="caregiver.notification"
                                        label="Notificar alertas por WhatsApp"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>                        
                        </v-col>                        
                
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn @click="closeDialogCaregiver" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                        <v-icon right dark> mdi-close</v-icon>
                    </v-btn>
                    <v-btn v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar <v-icon right dark> mdi-check</v-icon>
                    </v-btn>
                    <v-btn v-else @click="saveAction()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark> mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>

import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import SelectSearch from '@/components/forms_items/SelectSearch.vue';
// import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
// import SelectSearch from '@/components/forms_items/SelectSearch.vue';
// import BaseDate from '@/components/forms_items/BaseDate.vue'
import AxiosServices from '@/services/AxiosServices'


export default {
    name: 'FormCaregiver',
    data() {
        return {
            caregiver: {id_type:'',nid:'',first_name:'',last_name:'',cellphone:'',email:'',notification:false},
            dialog: false,
            nid_validation_reloaded:0,
            permissions:{}
            // vaccine_type:"",
            // vaccines:{}
        }
    },
    mounted() {
        this.getPermission(),
        this.getCaregiver()
    },
    props: {
        createsForm: {
            type: Boolean,
        },
        stock_id:{
            type: String
        },
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        },
        color: {
            type: String,
            default: "primary"
        },
        modal_name: {
            type: String,
            default: ""
        },
        caregiver_in :{},
        edit: {
            type: Boolean,
            default: false
        },

    },  
    methods: {
        changeUppercase: function (objeto) {  
            for (const dato in objeto) {
                if (typeof objeto[dato] === 'string' && dato !== 'email') {
                    objeto[dato] = objeto[dato].toUpperCase();
                }
            }
            return objeto
        },
        createCaregiver:function(){
            console.log(this.caregiver);
            let caregiver ={}
            caregiver['caregiver']= this.changeUppercase(this.caregiver)  
            this.changeUppercase(caregiver)  
            AxiosServices.patientCaregiversCreate(this.$route.params.id,caregiver).then(res => {                 
                this.$store.dispatch("addNotification", res.data)
                this.$emit('updateCaregiver');
                this.dialog = false
            }).catch(error => {
                console.log('el error trae', error.response.data);
                this.$store.dispatch("addNotification", error.response.data)
                this.dialog = false
            })
        },
        updateCaregiver:function(){
            console.log(this.caregiver);
            let caregiver ={}
            caregiver['caregiver']= this.changeUppercase(this.caregiver)  
            AxiosServices.patientCaregiversUpdate(this.$route.params.id,caregiver.id,caregiver).then(res => {                 
                this.$store.dispatch("addNotification", res.data)                
                this.$emit('updateCaregiver');
                this.dialog = false
            }).catch(error => {
                console.log('el error trae', error.response.data);
                this.$store.dispatch("addNotification", error.response.data)
                this.dialog = false
            })
        },
        saveAction:function(){
            if (this.createsForm) {
                this.createCaregiver()
            } else {
                console.log('entré a actualizar');
                this.updateCaregiver()
            }

        },
        closeDialogCaregiver(){
            this.$emit('updateCaregiver');
            this.getCaregiver()
            this.$store.dispatch("reseterror")
            this.dialog=false
        },
        getCaregiver:function(){
            if (this.createsForm){
                this.caregiver = {id_type:'',nid:'',first_name:'',last_name:'',cellphone:'',email:'',notification:false, prefix:this.prefix_default, country_id:this.country_id_default, country_name:this.country_name_default}
            } else {
                this.caregiver = this.caregiver_in
            }            
        },        
        getdispatch:function(){
            this.$store.dispatch("reseterror")
            // this.$store.dispatch("putValidations")
        },
        get_searching: function(label){
            this.caregiver.country_name=label
        },
        getPermission: function (){
            AxiosServices.patientDetail(this.$route.params.id).then(res => {
                this.permissions=res.data.data.permissions
                console.log(this.permissions);
            }).catch(error => {
                console.log('el error es', error);
            })
        },
        
            
    },
    computed:{
        verror:function(){
                var show=false
                if (this.$store.state.form.reserror){
                    show=true
                }
                return show
            },
            country_id_default:function(){
                return  parseInt(localStorage.getItem('user_country_id'))
            },
            country_name_default:function(){
                return  localStorage.getItem('user_country_name')
            },
            prefix_default:function(){
                return  localStorage.getItem('user_prefix')
            },
            to_reload_validation:function () {
                return this.nid_validation_reloaded
            }
    },

    components: {
        BaseInput,
        BaseSelect,
        SelectSearch
        // BaseCheckbox,
        // SelectSearch,
        // BaseDate

    },
}

</script>

<style>
    .full-width{
        width: 100%;
    }
    .wrap-text{
        word-wrap: anywhere;
    }
</style>