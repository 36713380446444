

<template>
  <v-app id="inspire">
    <SidebarGlya></SidebarGlya>
    <ToolbarGlya></ToolbarGlya>
    <v-main class="pa-5">
      <!-- <h1>acá irá el dashboard</h1> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  
  import SidebarGlya from '@/components/layout/SidebarGlya.vue'
  import ToolbarGlya from '@/components/layout/ToolbarGlya.vue'
  // import AxiosServices from '@/services/AxiosServices';

  export default {
    name: 'MainLayout',
    
    components: {
    SidebarGlya,
    ToolbarGlya
    },
    mounted() {
      // this.clearData()
      // this.$store.dispatch("putValidations")
    },

    data: () => ({
      drawer: null,
      user:{
        client_id:"12345",
        cliente:"Glya",
        sede:"Bogotá",
        nombres:"Super",
        apellidos:"Admin",
        rol:"administrador",
        imagen:"https://vuejs.org/images/logo.png"
      },
    }),
    methods: {
    },
    }
</script>


<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

  #inspire{
    font-family: 'Roboto', sans-serif;
  }
  h1, h2, h3, h4, h5{
    color: #546E7A;
  }

  .logo {
    width: 70%;
  }
  .red-darken-4{
    background-color: #B71C1C;
  }


</style>