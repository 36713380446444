<template>
    
    <div>
        <div v-if="only_show" class="info-card rounded-0 pl-3 pr-3">
            <span class="text-body-2 color-text-glya text-capitalize">
                <b>{{element.abbreviation}}: </b>{{observation.value}}
            </span>
        </div>
        <div v-else>
            <div v-if="element.type=='textolargo'">
                <BaseTextArea type="text" v-model="observation.value" rows="3" :label="`${element.name}`" :outlined="true"
                    :validador="validator" @changeglya="saveaction()"></BaseTextArea>
            </div>
            <div v-if="element.type=='cuantitativo'">
                <BaseInput type="text" v-model="observation.value" :label="sigla ? element.abbreviation : element.name"
                    :outlined="true" :validador="validator" @changeglya="saveaction()" element="element"
                    :prefix="`${element.unity}`" :place="`${element.abbreviation}`"></BaseInput>
            </div>
            <div v-if="element.type=='select'">
                <BaseSelect :type="type" model="patient" list="BloodType" v-model="observation.value" :label="`${element.name}`"
                    :outlined="true" :validador="validator" element="element" @changeglya="saveaction()"></BaseSelect>
            </div>
            <div v-if="element.type=='boolean'">
                <BaseCheckbox :type="type" v-model="observation.value" color="indigo" positive="Si" negative="No"
                    margin="negative-margin-8" @changeglya="saveaction()" :label="`${element.name}`" element></BaseCheckbox>
            </div>
            <div v-if="element.type=='textocorto'">
                <BaseTextArea type="text" v-model="observation.value" rows="1" :label="`${element.name}`" :outlined="true"
                    @changeglya="saveaction()" :validador="validator" filter="filter" :auto_grow="false"></BaseTextArea>
            </div>
        </div>
        <div v-if="show_dialog"  class="d-flex justify-end">
            <v-dialog transition="dialog-bottom-transition" max-width="450" v-model="dialog" persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="btn-history" icon color="red lighten-3"  v-bind="attrs" v-on="on">
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </template>
                <template v-slot:default>
                    <v-card>
                        <v-toolbar color="indigo darken-4 text-h6" dark height="50">Historico de cambios</v-toolbar>
                            <v-card-text class="mt-5">
                                <div v-for="log in observation.logs_obs.slice().reverse()"  :key="log['date']">
                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="6" class="pa-0">
                                            <div class="pa-1 info-card rounded-0">
                                                <span class="text-body-2 color-text-glya pl-2"><b>Fecha: </b>
                                                    {{ log['datef']}}</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="3" class="pa-0">
                                            <div class="pa-1 info-card rounded-0">
                                                <span class="text-body-2 color-text-glya pl-2"><b>Antes:</b>
                                                    {{log['before']}}</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="3" class="pa-0">
                                            <div class="pa-1 info-card rounded-0">
                                                <span class="text-body-2 color-text-glya pl-2"><b>Despues: </b>
                                                    {{log['after']}}</span>
                                            </div>
                                        </v-col>                          
                                    </v-row>
                                </div>
                            </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-end">
                            <v-btn @click="dialog=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                                <v-icon right dark> mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </div>        
    </div>
</template>
<script>
    import axios from 'axios'
    import BaseInput from '@/components/forms_items/BaseInput.vue'
    import BaseTextArea from '@/components/forms_items/BaseTextArea.vue'
    import BaseSelect from '@/components/forms_items/BaseSelect.vue'
    import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
    export default {
        data() {
            return {
                element: {},
                dialog:false,
                headers: [
                        { text: 'Fecha de actualización', value: 'date', active: true, sortable: false},
                        { text: 'Valor anterior', value: 'before' },
                        { text: 'Valor nuevo', value: 'after'},
                    ],
                observation: { clinical_element: null, date: new Date().toLocaleDateString(),encounter_id:null,source:'Encounter',value:null}
            }
        },
        props: {
            type: {
                type: String,
                default: "none"
            },
            const:{
                type: String
            },
            validator:{
                type:String,
                default:'none'
            },
            label:{
                type:String
            },
            sigla: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: "mdi-pencil-circle"
            },
            encounter:{type:String},
            only_show:{
                type:Boolean,
                default:false
            }
        },
        mounted() {
            this.getElement()
        },

        methods: {
            getElement: function () {                
                axios.get(this.$URL + `encounters/encounter_id=${this.encounter_id}/get_element?type=${this.$props.type}&encounter_id=${this.encounter_id}`).then(res => {
                    this.element = res.data.element                    
                    this.observation.encounter_id = this.encounter_id                   

                    this.observation.clinical_element = this.element.id                    
                    if (Object.prototype.hasOwnProperty.call(res.data, 'observation')) {
                        this.observation = res.data.observation
                    }
                })
            },
            saveaction: function() {
                if (this.observation.value != null) {
                    if (Object.prototype.hasOwnProperty.call(this.observation, 'id')) {
                        axios.patch(this.$URL + `observations/${this.observation.id}.json`, { observation: { value: this.observation.value } }).then(res => {
                            this.observation = res.data
                            this.getElement()
                        })
                    } else {
                        this.observation.patient_id = this.element.patient_id
                        this.observation.encounter_id = this.encounter_id
                        axios.post(this.$URL + "observations.json", this.observation).then(res => {
                            this.observation = res.data
                            this.getElement()
                        })
                    }
                }
            }
        },
        computed:{          
            encounter_id: function(){
                return this.$props.encounter
            },
            show_dialog: function(){
                let show=false;
                if (Object.prototype.hasOwnProperty.call(this.observation, 'logs_obs') && this.observation.logs_obs.length > 0) {
                    show=true
                }
                return show
            }

        },
        components: {
            BaseTextArea,
            BaseInput,
            BaseSelect,
            BaseCheckbox 
        }
    }
</script>
<style>
    .unidad-form{
    position: absolute;
    font-size: 10px;
    right: 6px;
    top: 10px;
    color: #9d9c99;
}
.unidad-form .fa-angle-down{
    z-index: -10
}


 .v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
  transform: translateY(-15px) scale(0.75);
  background-color: white;
  color: #666 !important;
}

.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  border: 1px solid #9e9e9e !important;
}

.v-messages__message {

  color: #f46464 !important;
}
.v-application--is-ltr .v-text-field__prefix {
  text-align: right;
  padding-right: 0px;
  font-size: 11px;
  color: gray;
}

.v-text-field fieldset, .v-text-field .v-input__control {
  color: #9e9e9e;
}

.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-label, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-label {
  top: 8px;
  padding-left: 3px;
  padding-right: 3px;
}

.v-text-field--outlined .v-label--active {
  transform: translateY(-15px) scale(0.75);
}
.btn-history{
    background-color: white;
  margin-top: -40px;

}

.btn--history.v-size--default {
  height: 22px;
  width: 22px;
}

.btn--history.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon {
  height: 20px;
  font-size: 20px;
  width: 20px;
}


</style>