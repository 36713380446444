import axios from 'axios'
import store from '../store'

export default() => {
    return axios.create({
        baseURL: `${process.env.VUE_APP_DOMAINURL}`,
        headers: {
            'Authorization': `${store.state.user.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}