<template>
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="600" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!permissions.add_tags" icon color="white" :disabled="!permissions.add_tags" >
                <v-icon size="30px">{{icon}}</v-icon>
            </v-btn>
            <v-btn v-else icon color="white" v-bind="attrs" v-on="on"  @click="getTags">
                <v-icon size="30px">{{icon}}</v-icon>
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card>
                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{modal_name}}</v-toolbar>
                <v-card-text>
                    <span class="ghost">{{ reload_select }}</span>
                    <v-col cols="12" class="mt-1">
                        <p>Cree o agregue etiquetas con palabras clave que le ayude a describir aspectos relevantes del paciente.</p>
                        <v-row justify="center" class="mt-3">
                            <h4>{{ listen_tag_id }}</h4>
                            <v-col cols="9" class="pt-0 pb-0 col-search">
                                <span v-if="!show_input" >
                                    <SelectSearch v-model="tag_id" :valuex="tag_id" element="element" :parameter="`[model:'${model}']`" icon="mdi-magnify" outlined label="Etiqueta" model="tags" :reloader="reload_select" @count_data="change_btn_active()" @count_nil="change_btn_inactive()" @label_search="get_searching"></SelectSearch>
                                </span>
                                <span v-else>
                                    <BaseInput v-model="tag_label" type="text" validador="required" element="element" outlined label="Nombre"></BaseInput>
                                </span>
                            </v-col>                           
                            <v-col cols="3" class="pt-0 pb-3">
                                <v-btn v-if="show_btn" color="primary" class="btn-tag" elevation="0" disabled>
                                    <v-icon>mdi-tag-plus</v-icon>
                                    <span class="d-none d-sm-flex pl-1">Nueva etiqueta</span>
                                </v-btn>
                                <v-btn v-else color="primary" class="btn-tag" elevation="0"  @click="show_color = true">
                                    <v-icon>mdi-tag-plus</v-icon>
                                    <span class="d-none d-sm-flex pl-1">Nueva etiqueta</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class=" pt-0 pb-5" v-if="show_color">
                                <label class="text-body-2 ml-1 pb-2 pa-0 color-text-glya">Seleccione un color para su nueva etiqueta</label>
                                <v-row class="d-flex justify-space-around">
                                    <div class="mt-3 " v-for="color in temporales.colores" :key="color">
                                        <v-col class="pa-1">
                                            <v-btn fab x-small type="button" name="button" elevation="0" @click="select_color(color)" :color="color" ></v-btn>
                                        </v-col>
                                    </div>
                                </v-row>
                            </v-col>
                            <v-col cols="12" v-if="show_alert == true" class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message ml-3">¡El campo es requerido!</div>
                                </div>
                            </v-col>  
                            <v-col cols="12" class=" pt-5">
                                <v-card class="pa-3" elevation="0" min-height="100" outlined>
                                    <label class="label_tags color-text-glya">Etiquetas seleccionadas</label>
                                    <!-- <h4>{{ tags.length }} {{ listen_tag_id }}</h4> -->
                                    <v-row class="d-flex justify-start mt-1">
                                        <div class="ma-1" v-for="tag_show in tags" :key="tag_show.id">                                           
                                            <ShowTag @send_tag_id="delete_tag_id" close :tag_item="tag_show"></ShowTag>
                                        </div>
                                    </v-row>
                                    
                                </v-card>
                            </v-col>
                        </v-row>                                            
                    </v-col>                                         
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end mt-3">
                    <v-btn @click="close_action()" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                    <v-btn v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                    <v-btn v-else @click="save_action()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

</template>

<script>
    import SelectSearch from '@/components/forms_items/SelectSearch.vue';
    import BaseInput from '@/components/forms_items/BaseInput.vue';
    import ShowTag from '@/components/views/ShowTag.vue';
    // import axios, { Axios } from 'axios'
    import Pluralize from 'pluralize'
    import AxiosServices from '@/services/AxiosServices';
    // import axios from 'axios'

    export default {
        name: 'FormTag',
        mounted() {
            this.$store.dispatch("reseterror")
            this.getPermission()
            // this.getTags()
        },
        data(){
        return{            
            temporales:{
                colores:['black','red','pink','purple','indigo', 'blue', 'light-green', 'amber', 'orange', 'blue-grey'],         
            },
            tag: { color: "", name: "", client_id: this.$store.state.user.client_id, description: "", model: this.$props.model },
            tag_id:'none',
            tag_ids:[],
            tags:[],
            dialog:false,
            show_btn:false,
            show_color:false,
            patient:{tags:[]},
            model_plural: Pluralize(this.$props.model.toLowerCase()),
            tag_label:"",
            show_alert:false,
            permissions:{}


        }},
        props: {
            modal_name: {
                type: String,
            },
            label: {
                type: String
            },
            icon: {
                type: String,
                default: "mdi-pencil-circle"
            },
            model: {
                type: String,
                default: 'patient'
            },
            id: {
                default:""
            }
        },
        methods:{
            pre_new: function(){
                this.show_color=true
                this.create=true
            },
            change_btn_active: function(){
                this.show_btn = true
            },
            get_searching: function(label){
                this.tag_label=label
            },
            change_btn_inactive: function(){
                this.show_btn = false
            },            
            getTags:function(){
                AxiosServices.patientTagsList(this.$route.params.id).then(res=>{
                    this.tags=res.data.data                    
                })
            },
            delete_tag_id: function(id_tag){
                let temp=this.tags
                function remove_tag(obj){
                    if (obj.id != id_tag){
                        return true
                    }
                    return false
                }
                this.tags=temp.filter(remove_tag)
            },
            saveTag:function(tag){
                if (this.$props.model=='patient'){
                    AxiosServices.patientTagsCreate(this.$route.params.id,tag).then(res=>{
                        this.tags.push(res.data.data)
                        this.$store.dispatch("addNotification", res.data)
                        this.resetTag()                        
                    })
                }
            },
            resetTag:function(){
                this.show_btn = false
                this.show_color = false
                this.show_alert = false
                this.tag_label = ''
                this.tag_if = ''
            },
            select_color: function(color){
                this.tag.color=color
                if (!this.verror) {
                    this.tag.name=this.tag_label
                    this.saveTag(this.tag)
                } else {
                    this.$store.dispatch("show_required")
                }
            },
            setArray:function(tags_object){
                let tag_ids=[]
                let patient={}
                let tags={'tags':[]}
                tags_object.forEach(tag => {
                    tag_ids.push(tag.id)
                });
                tags['tag_ids']=tag_ids
                patient['patient']=tags
                return patient
            },
            close_action(){
                this.tag_label = ''
                this.resetTag()
                this.dialog = false 
                this.getTags()
                this.$emit('updatetags');
            },
            save_action: function(){
                this.$store.dispatch("show_required")
                if (!this.show_color){
                    AxiosServices.patientUpdate(this.$route.params.id,this.setArray(this.tags)).then(res=>{
                        this.$store.dispatch("addNotification", res.data)
                        this.$emit('updatetags');
                    })
                    this.dialog = false 
                }else {
                    this.$store.dispatch("show_required")
                    this.show_alert=true
                }
                this.getTags()
                this.$emit('updatetags');
            },
            seterror: function(){
                this.$store.dispatch("reseterror")
                // this.$store.dispatch("putValidations")
            },            
            add_tag: function(tag){
                console.log('antes de poner llega esto ',tag);             
                this.tags.push(tag)
            },
            set_show_btn:function(){
                this.show_btn=false
            },
            getTag:function(tag_id){
                let data={}
                AxiosServices.tagsDetail(tag_id).then(res => {
                    data=res.data.data
                    if (!this.tags.includes(data)) {
                        this.add_tag(data)
                    }
                }).catch((error) => {
                    this.$store.dispatch("addNotification", error.response.data);
                })                
            },
            getPermission: function (){
                AxiosServices.patientDetail(this.$route.params.id).then(res => {
                    this.permissions=res.data.data.permissions
                }).catch(error => {
                    console.log('el error es', error);
                })
            },

        },
        computed:{
            verror:function(){
                return this.$store.state.form.reserror
            },
            reload_select(){
                return !this.dialog
            },
            listen_tag_id:function(){
                if(this.tag_id != 'none' && this.tag_id != null){                    
                    this.getTag(this.tag_id)                    
                }
                // if(this.tag_id != 'none' & this.tag_id != '' & this.tag_id != null & !this.tags.includes(this.tag_id)){
                //     this.add_tag()
                // }                
                return null
            },
            show_input:function(){
                let show=false
                if(this.tag_label.length>0 && this.show_color==true){
                    this.set_show_btn()
                    show=true
                }
                return show
            }
        },       

        components: {
            SelectSearch,
            ShowTag,
            BaseInput      
        },
    }
</script>

<style>
    .negative-margin-50{
    margin-top: -50px;
}
.btn-tag{
    position: absolute;
    right: 30px;
    margin-top: 0px;
    height: 32px !important;
    padding: 0px 5px;
}
.theme--light.btn-tag.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(232, 232, 232) !important
}
.label_tags{
    position: absolute;
background: white;
margin-top: -25px;
padding: 0px 10px;
}
.v-text-field--outlined .v-input__prepend-outer, .v-text-field--outlined .v-input__append-outer {
    margin-top: 5px;
    margin-left: 3px;
    position: absolute;
}
.col-search .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px 28px;
}
.col-search .v-text-field--outlined .v-label--active {
    transform: translateY(-15px) translateX(-15px) scale(0.75);
}
.col-search > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label--active {
    transform: translateY(-15px) translateX(-15px) scale(0.75);
}
</style>
