<template>
    <div class="inputglya inputdate">
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-top="0">
            <template v-slot:activator="{ on, attrs }" >
                <v-text-field
                :label="label_show"
                ref="menu"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @input="updateValue"                
                v-on="on"
                v-model="date"
                :outlined="outlined"
                :error-messages="nameErrors"
                :class="filter"
                @keydown="checkKey"
                ></v-text-field>
            </template>
            
            <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="limitDateMax"
                :min="limitDateMin"
                @input="addDate"
                locale="es"
                width="250px"
                :disabled="disabled"
                @keydown="checkKey"
            ></v-date-picker>
        </v-menu>

        <v-menu
        ref="menu"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-top="60"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                v-if="dateTime"
                v-bind="attrs"
                v-on="on"
                ></div>
            </template>

            <v-time-picker
                class="timer"
                v-if="menu3"
                v-model="time"
                full-width
                ampm-in-title
                @click:minute="addTime(time)"
                :disabled="disabled">
            </v-time-picker>
        </v-menu>

    </div>
</template>
    



<script>

    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    export default {

        // props:["value","label"],
        props:{
            values: {}, label: {}, outlined:{default:false}, filter:{},
            validador: {
                default: 'none'
            },
            limitType: {default:"none"},
            dateTime:{
                type:Boolean,
                default: false },
                disabled:{
                type:Boolean,
                default:false
            },
            valueinit:{
                type:String,
                default:'none'
            }
        },
        components: { },

        model:{
            prop:"value",
            event:"blur"
        },
        mounted() {            
            this.review()
            // this.formatingDate(this.$attrs.value !== undefined ? this.$attrs.value : new Date())
            this.formatingDate(this.$attrs.value)
        },
        
        data() {
            return {
                menu2 : false,
                menu3: false,
                activePicker: null,
                date: "",
                menu: false,
                fromvuex: this.$store.state.validations2[this.$props.validador],
                id: `${this.$props.label} ${Math.floor(Math.random() * 1000000)}`,
                minl: 5,
                showlabel: false,
                time:"12:36",
                datefull:null,
                eval:false
                
            };
        },
        watch: {
            menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        },
        methods: {
            checkKey(event) {
                const code = event.keyCode || event.which;
                const notAllowedKeys = [8, 46];

                if (notAllowedKeys.includes(code)) {
                    event.preventDefault();
                    console.log("La tecla de borrado está deshabilitada");
                }
            },
            
            updateValue(value) {
                this.menu2=false
                this.menu3=false
                this.$emit('blur', value);
                this.eval_errors()                
            },
            addDate(value){
                this.datefull = value + ' ' + this.time
                this.menu2 = false
                this.menu3 = true                
                if(!this.$props.dateTime){
                    this.updateValue(this.datefull)
                }
            },
            addTime(value){
                this.datefull = this.date + ' ' + value
                this.menu3 = false
                this.updateValue(this.datefull)
            },
            set_label: function () {
                this.showlabel = true
                return this.showlabel
            },
            eval_errors: function () {                
                if (this.nameErrors.length == 0) {
                    this.$store.dispatch("puterror", { [this.id]: 0 })
                } else {
                    this.$store.dispatch("puterror", { [this.id]: 1 })
                }
            },
            formatingDate(date) {
                if (date !== undefined){
                    const full_date = new Date(new Date(date + "T00:00:00.000Z").getTime() + 86400000);
                    this.date = new Date(full_date.getTime() - full_date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
                    this.time = full_date.toLocaleTimeString()
                }
            },
            review: function(){
                if (this.$props.valueinit !== 'none' && this.fromvuex['required'] !== undefined) {
                    this.$store.dispatch("puterror", { [this.id]: 0 })                    
                }else{
                    this.$store.dispatch("puterror", { [this.id]: 1})
                }
            }

        },
        computed: {
            reeval: function(){
                if(this.nameErrors.length == 0){
                    this.$store.dispatch("puterror", { [this.id]: 0 })
                }
                return true
            },
            eval_required_show: function () {
                return this.$store.state.show_required
            },
            label_show: function () {
                let label = this.$props.label
                if (this.showlabel) {
                    label = `${this.$props.label}${'*'}`                    
                }
                return label
            },
            hola(){
                return this.date.length
            },
            nameErrors() {
                let errors = []
                if (!this.$v.date.required && this.fromvuex['required'] !== undefined) {
                    if (this.eval_required_show) {
                        errors.push('¡El campo es requerido!')
                        // if (this.showlabel){
                        //     errors.push('¡El campo es requerido!')
                        // } else {
                        //     errors = errors.filter((item) => item !== ' ')
                        //     errors.push('ccccc')                            // this.eval_errors()         
                        // }
                    } else {
                        errors.push(' ')                            // this.eval_errors()         
                    }
                    this.set_label()    
                } 
                return errors
            },
            limitDateMin() {
                let limit = '1890-01-01' 
                if (this.$props.limitType == 'expiration') {
                    limit = new Date(Date.now()).toISOString().substr(0,10)                  
                } 
                return limit
            },
            limitDateMax() {
                let limit = '2300-01-01'                
                if (this.$props.limitType == 'birthdate') {
                    limit = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                }
                if (this.$props.limitType == 'menor') {
                    let yesterday = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
                    // limit = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
                    limit=new Date(yesterday.setMonth(yesterday.getMonth() - (12*18))).toISOString().substr(0, 10)
                }
                return limit
            }
        },       
        mixins: [validationMixin],
        validations: function () {
            if (this.minl > 0) {
                return {
                    date: eval(this.fromvuex)
                }
            }
            return {
                x: {
                    required
                }
            }
        }
    }
</script>

<style scoped>
.v-date-picker-title__date > div {
    display: none !important;
}
.v-date-picker-title__year {
    margin-bottom: 0px;
}
.v-date-picker-table--date .v-btn {
    height: 25px;
    width: 25px;
}
.v-date-picker-table {

    height: 200px;
}


.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
    height: auto;
    font-size: 40px;
}
.v-picker__title {

    padding: 10px 16px;
    width: 264px;
}

.v-picker__body > div {
    width: 100%;
}
.v-time-picker-clock__container {
    flex-basis: 220px;
}
.timer > .v-picker__body > div {
    width: 90%;
}
 .v-menu__content {

    min-width:auto !important;

}
.v-date-picker-table--month td {
  height: 40px;

}
</style>