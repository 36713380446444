<template>
    <div>
        <v-row>
            <v-col>       
                <TitleView title="Inventario"></TitleView>
            </v-col>
                <v-spacer></v-spacer>
            <v-col class="d-flex align-end flex-column">
                <FormBatch :createsForm="true" icon="mdi-plus-circle-outline" color="indigo" modal_name="Crear Stock"></FormBatch>
            </v-col>
        </v-row>
        <GdiTable model="stock" ver ></GdiTable>
    </div>
</template>
<script>

import FormBatch from '@/components/forms/FormBatch.vue'
import GdiTable from '@/components/views_items/GdiTable.vue'
import TitleView from '@/components/views/TitleView.vue'
import axios from 'axios'

export default {
    name: 'IndexBatches',
    data() {
        return {            
            batches:[],
        }
    },    
    mounted() {
        this.get_batches()
    },
    methods: { 
        get_batches: function () {
            axios.get(this.$URL + "stocks.json?client_id=6335b5ef456c62360e23baf3" ).then(res => {
                console.log('llega esto', res.data)
                this.batches=res.data
            })
        },
    },
    props: {
        create_form: {
            type: Boolean,
            defaul: false
        },        
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        }

    },
    computed: {
    },

    components: {
        FormBatch,
        GdiTable,
        TitleView
    },
}

</script>