<template>
    <v-dialog transition="dialog-bottom-transition" max-width="700" v-model="dialog" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="color" v-bind="attrs" v-on="on"  @click="get_vaccines" dark>
                {{modal_name}} &nbsp; <v-icon >{{icon}}</v-icon> 
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card>
                <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{modal_name}}</v-toolbar>
                <v-card-text>                    
                    <v-row >
                        <v-col cols="12" class="mt-5">                          
                            <v-row>
                                <v-col cols="3" md="2" class="pt-0 pb-0">
                                    <BaseCheckbox v-model="stock.batch" color="indigo" positive="Si" negative="No" label="¿Agregar Lote?"></BaseCheckbox>
                                </v-col>
                                <v-col cols="9" md="4" class="pt-0 pb-0">
                                    <BaseSelect v-model="stock.type" type="text" model="stock" list="Type" label="Tipo de insumo" :valuex="stock.type" validador="required"></BaseSelect>
                                </v-col>
                                <v-col v-if="stock.type=='Biológico'" cols="12" md="6" class="pt-0 pb-0">
                                    <BaseSelect v-model="vaccine_type" type="text" model="medication" list="Vaccine_type" label="Tipo de Biológico" :valuex="vaccine_type">
                                    </BaseSelect>
                                </v-col>
                                <v-col v-if="stock.type=='Dispositivo'" cols="12" md="6" class="pt-0 pb-0">
                                    <SelectSearch label="Seleccione Dispositivo" model="supply" v-model="stock.medical_supply_id" :validador="stock.type=='Dispositivo' ? required : none" :valuex="stock.medical_supply_id"></SelectSearch>
                                </v-col>
                                <v-row v-if="vaccine_type.length>0 && stock.type=='Biológico'" class="full-width">
                                    <p class="mb-0 pl-5 full-width">Seleccione uno de los siguentes Biológicos</p>
                                    <v-col cols="6" class="mb-0" v-for="vaccine in vaccines[vaccine_type]"  :key="vaccine.id">                                            
                                        <v-btn @click="stock.medical_supply_id = vaccine._id" :color="vaccine._id == stock.medical_supply_id ? 'info' : 'grey'" dark block>
                                            <v-card class="text-caption wrap-text" color="transparent" elevation="0">
                                                {{vaccine.tradename}}
                                            </v-card>
                                        </v-btn>
                                    </v-col>
                                </v-row>                                
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                    <BaseDate v-model="stock.expiration_date" label="Fecha de expiración" validador="required" limitType="expiration" :valueinit="stock.expiration_date"></BaseDate>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <BaseInput v-model="stock.serial" type="text" :label="stock.batch==true ? 'Serial de lote' : 'Referencia'" validador="required"></BaseInput>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <BaseInput v-model="stock.cuantity" type="text" label="Cantidad (únidades)" validador="quantity" ></BaseInput>
                                </v-col>
                            </v-row>                        
                        </v-col>                        
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn @click="dialog=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar
                        <v-icon right dark> mdi-close</v-icon>
                    </v-btn>
                    <v-btn @click="saveAction" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror"> Guardar <v-icon right dark> mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>

import BaseInput from '@/components/forms_items/BaseInput.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
import SelectSearch from '@/components/forms_items/SelectSearch.vue';
import BaseDate from '@/components/forms_items/BaseDate.vue'
import axios from 'axios'


export default {
    name: 'FormBatch',
    data() {
        return {
            stock: {serial:"",type:"",expiration_date:null,batch:false},
            dialog: false,
            vaccine_type:"",
            vaccines:{}
        }
    },
    mounted() {
        this.get_stock()
    },    
    methods: {

        get_vaccines: function () {
            this.vaccines=this.$store.state.vaccines  
            console.log("carga....", this.$store.state.vaccines )
            this.$store.dispatch("reseterror")
            this.$store.dispatch("putValidations")
            if (this.$props.create_form==false) {
                console.log('antes de ------------------------')
                axios.get(this.$URL + "stocks.json", this.$props.stock_id).then(res => {
                    console.log('llega estooooooooooxxxxxxxx', res.data)
                    this.stock=res.data    
                })              
            }
        },
        saveAction: function (){
            this.$store.dispatch("show_required")
            if (!this.verror) {
                this.stock.client_id = this.$store.state.user.client_id
                this.stock.batch = true
                if (this.createsForm == true) {
                    axios.post(this.$URL + "stocks.json", this.stock).then(res => {
                        console.log('stock created', res.data);
                        this.$router.push({ name: 'inventory', params: { reload: true } }).catch(() => { })
                        this.dialog = false
                    })
                } else {
                    axios.patch(this.$URL + "stocks/" + this.stock.id + ".json", this.stock).then(res => {
                        this.$store.dispatch("get_vaccines", this.$route.params.id)
                        this.dialog = false
                        this.$router.push({ name: 'inventory', params: { id: res.data, reload: true } }).catch(() => { })
                    })
                }
            } else {
                this.$store.dispatch("show_required")
            }
        }
    },
    computed:{
            verror:function(){
                return this.$store.state.form.reserror
            },
            batchRequired:function(){
                let value = this.stock.batch
                if (this.stock.type == 'Biológico') {
                    value = true
                }
                return value
            }
    },
    props: {
        createsForm: {
            type: Boolean,
            defaul: true
        },
        stock_id:{
            type: String
        },
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        },
        color: {
            type: String,
            default: "primary"
        },
        modal_name: {
            type: String,
            default: ""
        }

    },  
    components: {
        BaseInput,
        BaseSelect,
        BaseCheckbox,
        SelectSearch,
        BaseDate

    },
}

</script>

<style>
    .full-width{
        width: 100%;
    }
    .wrap-text{
        word-wrap: anywhere;
    }
</style>