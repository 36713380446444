<template>
    <div>
        <v-row>
            <v-col>       
                <TitleView title="Suministros"></TitleView>
            </v-col>
                <v-spacer></v-spacer>
            <v-col class="d-flex align-end flex-column">
                <FormSupply :createsForm="true" icon="mdi-plus-circle-outline" color="indigo" modal_name="Crear Insumo"></FormSupply>
            </v-col>
        </v-row>
        <GdiTable model="supply" ver ></GdiTable>
    </div>
</template>
<script>

import FormSupply from '@/components/forms/FormSupply.vue'
import GdiTable from '@/components/views_items/GdiTable.vue'
import TitleView from '@/components/views/TitleView.vue'
// import axios from 'axios'

export default {
    name: 'IndexBatches',
    data() {
        return {            

        }
    },    
    mounted() {

    },
    methods: { 

    },
    props: {
        create_form: {
            type: Boolean,
            defaul: false
        },        
        icon: {
            type: String,
            default: "mdi-pencil-circle"
        }

    },
    computed: {
    },

    components: {
        FormSupply,
        GdiTable,
        TitleView
    },
}

</script>
