<template>
    <div>
        <v-alert
        dense
        text
        outlined
        :color="color"
        :icon="icon"
      >
      <p class="text-md-button pa-0 ma-0 "> {{title}}</p>
      </v-alert>
    </div>

</template>

<script>
    export default {
        name: 'ViewTitle',
        props: { 
            title:{
                type:String,
                default:'TITULO'
            },
            icon:{
                type:String,
                default:'mdi-checkbox-blank-circle-outline'
            },
            color:{
                type:String,
                default:'primary'
            }
        },
        components: {
     
        }
    }

</script>