<template>
    <div>     
        <TitleView title="Ver Lote"></TitleView>
        <v-row>
            <v-col cols="12" class="pa-2">
                <div>
                    <v-card class="mx-auto">
                        <v-card-title class="indigo d-sm-none">
                            <v-icon color="white" class="mr-2">mdi-tag</v-icon>
                            <h4 class="white--text"> Lote: {{stock.serial}}</h4>
                        </v-card-title>
                        <v-card-text class="text-body-1 pa-3">
                            <v-row>       
                                <v-col cols="12" sm="3" class="pa-0 text-center indigo d-flex align-center flex-column">
                                    <v-col class="d-none d-sm-block d-print-block align-self-center">
                                        <v-icon size="70" color="white">mdi-tag</v-icon>
                                        <h3 class="white--text"> Lote: {{stock.serial}}</h3>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-row>
                                        <v-col cols="6" sm="4" md="3">
                                            <label class="indigo--text text-subtitle-2">Tipo: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.type == '' || stock.type == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                    Dato Incompleto</v-chip>
                                                </span>
                                                <span v-else>{{stock.type}}</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="8" md="6">
                                            <label class="indigo--text text-subtitle-2">Nombre: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.medical_supply_id.text == '' || stock.medical_supply_id.text == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                    Dato Incompleto</v-chip>
                                                </span>
                                                <span v-else>{{stock.medical_supply_id.text}}</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="4" md="3">
                                            <label class="indigo--text text-subtitle-2">Serial: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.serial == '' || stock.serial == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                    Dato Incompleto</v-chip>
                                                </span>
                                                <span v-else>{{stock.serial}}</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="8" md="3">
                                            <label class="indigo--text text-subtitle-2">Fecha de vencimiento: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.expiration_date_format == '' || stock.expiration_date_format == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                    Dato Incompleto</v-chip>
                                                </span>
                                                <span v-else>{{stock.expiration_date_format}}</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="4" md="3">
                                            <label class="indigo--text text-subtitle-2">Cantidad: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.cuantity == '' || stock.cuantity == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                    Dato Incompleto</v-chip>
                                                </span>
                                                <span v-else>{{stock.cuantity}}</span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="8" md="3">
                                            <label class="indigo--text text-subtitle-2">Estado: </label>
                                            <p class="mb-0">
                                                <span v-if="stock.active == true"><v-chip class="pa-0 pl-3 pr-3" color="success" small>
                                                    Activo</v-chip>
                                                </span>
                                                <span v-else><v-chip class="pa-0 pl-3 pr-3" color="error" small>
                                                    Inactivo</v-chip></span>
                                            </p>
                                        </v-col>
                                        <v-col cols="6" sm="8" md="3">
                                            <FormBatch :createsForm="false" icon="mdi-pencil-circle" color="indigo lighten-3" modal_name="Editar"></FormBatch>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>

            <v-col cols="12">
                <div>
                    <v-card class="mx-auto pa-5">
                        <v-row>
                            <v-col cols="12" md="4" class="pb-0 col-form-icon">
                                <BaseDate label="Fecha de expiración" v-model="filter.date1" outlined="true" filter="filter" :valueinit="filter.date1"></BaseDate>
                            </v-col>
                            <v-col cols="12" md="4" class="pb-0 col-form-icon">
                                <BaseDate label="Fecha de expiración" v-model="filter.date2" outlined="true" filter="filter" :valueinit="filter.date2"></BaseDate>
                            </v-col>
                            <v-col cols="12" md="4">
                            <v-btn elevation="2" block color="indigo darken-4" dark>
                                Filtrar
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-col>      
        </v-row>

        <v-col cols="12" class="pa-0 mt-5">
            <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr class="indigo">
                        <th class="text-left">
                          Fecha Aplicación
                        </th>
                        <th class="text-left">
                          Paciente
                        </th>
                        <th class="text-left">
                          Comentarios
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in desserts"
                        :key="item.name"
                      >
                        <td>{{ item.date }}</td>
                        <td>{{ item.namePatient }}</td>
                        <td><v-chip v-if="item.comments == ''" class="pl-3 pr-3" dark outlined color="red lighten-3"> Sin descripción</v-chip></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
        </v-col>
    </div>

</template>
<script>
    import axios from 'axios'
    import TitleView from '@/components/views/TitleView.vue'
    import FormBatch from '@/components/forms/FormBatch.vue'
    import BaseDate from '@/components/forms_items/BaseDate.vue'

    export default {
    
        mounted(){
            this.getstock()
        },
        
        data(){
            return{  
                stock:{},
                desserts:[

                    {date:'10/09/2022',namePatient:'Alejandra Herrera Paez',comments:''},
                    {date:'22/01/2023',namePatient:'Jose Miguel Rodriguez ',comments:''},
                    {date:'17/02/2023',namePatient:'Cristian salazar Alvarado',comments:''},
                ],
                filter:{date1:new Date(new Date().getTime() - 604800000).toISOString().substr(0, 10), date2:new Date(Date.now()).toISOString().substr(0,10)}
        }

                
            },
            methods:{   
                
                getstock:function(){
                    console.log("entro!");
                    axios.get(this.$URL + "stocks/" + this.$route.params.id + ".json").then(res => {
                        console.log("trae aaa", res.data.stock );
                        
                        this.stock = res.data.stock
                    })
                }

                    
            },
            computed: {
                
            },
            components:{
                TitleView,

                FormBatch,
                BaseDate
        }
        
    }
    </script>
