<template>
    <v-container class="pa-2">
        <v-row>
            <v-col class="pa-0"> <h2 class="pt-4">Observaciones</h2></v-col>
            <v-col cols="12" md="6" class="_order-1 text-center text-md-right">
                <v-dialog
                    v-model="dialogNewObservation" 
                    max-width="500px"
                    transition="dialog-bottom-transition"
                >
                    <template v-slot:activator="{ on,attrs }">
                        <v-btn @click="dialogNewObservation = true" color="primary" dark v-bind="attrs" v-on="on">Crear Observación</v-btn>
                    </template>
                    <template v-slot:default>
                        <v-card>
                            <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Crear Observación</v-toolbar>
                            <v-card-text>
                                <v-container grid-list-xs>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-select
                                                v-model="new_observation.observation_type"
                                                :items="observations_types"
                                                item-text="name"
                                                item-value="id"
                                                label="Tipo"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="12">
                                            <v-textarea
                                                v-model="new_observation.value_text"
                                                label="Observación"
                                                outlined
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn @click="dialogNewObservation=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                <v-btn @click="createObservation" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="new_observation.observation_type==null || new_observation.value_text==null">Crear Observación<v-icon right dark > mdi-check</v-icon></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>                             
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <template  v-if="observations.length==0">
                    <v-alert type="warning" :value="true" dense>
                        No hay ninguna observación registrada            
                    </v-alert>
                </template> 
                <template v-else>
                    <v-card v-for="o in observations" :key="o.id" :color="observation_icon_color(o.observation_type).color+' lighten-5'" elevation="1" rounded="rounded" class="d-flex flex-row mb-2" >
                        <div class="ma-3 avatarcolumn" >
                            <v-avatar size="40" :color="observation_icon_color(o.observation_type).color" class="white-text">
                                        <v-icon color="white">{{observation_icon_color(o.observation_type).icon}}</v-icon>
                            </v-avatar>
                            <div class="text-caption">{{ observation_icon_color(o.observation_type).text }}</div>
                        </div>
                        <v-card class="rounded" color="white my-2 py-1 px-2 mr-2" min-width="120px" max-width="120px">
                            <div class="text-body-1">{{ o.user_name }}</div>
                            <div class="text-caption">{{ o.observed_at | formatTimeZone(o.time_zone)}}</div>
                            <div class="text-caption blue--text"> ({{ o.timespan_text }})</div>

                        </v-card>
                        <v-card class="rounded my-2 py-1 pl-2 pr-4 mr-2 flex-grow-1" color="white"  >
                            <div class="text-body-2">{{ o.value_text }}</div> 
                        </v-card>
                        <v-btn
                            v-if="o.erasable"
                            @click="deleteObservation(o.id)"
                            icon
                            dark
                            small
                            class="ma-2"
                            color="red darken-2"
                            style="position: absolute; top: -15 px; right: 0;"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import AxiosServices from "@/services/AxiosServices";
    export default {
        name: 'ObservationsPatientList',
        props: {
            patient_id: {
            default: "",
            },
            pid: String,
        },
        data() {
            return {
                dialogNewObservation: false,
                observations: [],
                observations_types: [],
                new_observation: {
                    observation_type: null,
                    value_text: null
                }
            };
        },
        methods: {
            getObservationsList:function() {
                AxiosServices.patientObservationsList(this.patient_id)
                .then(response => {
                    console.log(response.data);
                    this.observations = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            deleteObservation:function(observation_id) {
                AxiosServices.deleteObservation(observation_id)
                .then(response => {
                    console.log(response.data);
                    this.getObservationsList();
                })
                .catch(error => {
                    console.log(error);
                });
            },
            createObservation:function() {
                AxiosServices.patientsCreateObservation(this.patient_id, this.new_observation)
                .then(response => {
                    console.log(response.data);
                    this.getObservationsList();
                    this.dialogNewObservation = false;
                    this.new_observation = {
                        observation_type: null,
                        value_text: null
                    };
                })
                .catch(error => {
                    console.log(error);
                });
            },
            getObservationsTypesList:function() {
                AxiosServices.listingsSimple('observations_types')
                .then(response => {
                    console.log(response.data);
                    this.observations_types = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            observation_icon_color:function(observation_type) {
                switch (observation_type) {
                    case 0:
                        return {icon: 'mdi-hospital-box', color: 'pink', text: 'Nota de enfermería'};
                    case 1:
                        return {icon: 'mdi-doctor', color: 'indigo', text:  'Evolución Médica'};
                    case 2:
                        return {icon: 'mdi-location-enter', color: 'green', text: 'Admisión'};
                    case 3:
                        return {icon: 'mdi-location-exit', color: 'orange', text: 'Salida/Epicrisis'};
                    case 4:
                        return {icon: 'mdi-chat-alert-outline', color: 'grey', text: 'Otro'};
                    default:
                        return {icon: 'mdi-chat-alert-outline', color: 'grey', text: 'Otro'};
                }
            }      
        },
        computed: {
            // Your computed properties go here
        },
        mounted() {
            this.getObservationsList();
            this.getObservationsTypesList();
        }
    };
</script>

<style scoped>
    .avatarcolumn {
        max-width: 80px;
        min-width: 80px;
        display: block;
        width: 80px;
        /*border: solid 1px red;*/
        text-align: center;
    }
</style>