<template>
    <div>
        <h2> Formulario Generico...</h2>   

            <v-card class="mx-auto">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <Element type="enfermedad"  :encounter="encounter_id" :save="update" validator="textRequired_M"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="fc"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="fr"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="spo2"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="temp" :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="tasistolica" :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="tadiastolica"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" sigla></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="glucometria"  :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="talla"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="circunferenciaabdominal"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="imc"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="clasificacionimc"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="postradocama"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="amputado"  :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="miembroamputado"  :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="examenfisico"  :encounter="encounter_id" :save="update" validator="numericVitalRequired" ></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="origenenfermedad"  :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="edadanos" :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="peso" :encounter="encounter_id" :save="update" validator="numericVitalRequired"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="pesonacer" :encounter="encounter_id" :save="update" validator="none"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="integridadpiel" :encounter="encounter_id" :save="update" validator="required"></Element>
                        </v-col>
                        <v-col cols="6" md="2" sm="3" class="pt-0">
                            <Element type="heridacuracion" :encounter="encounter_id" :save="update"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="sitioedema" :encounter="encounter_id" :save="update" validator="textRequired_XS"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="aspectogeneral" :encounter="encounter_id" :save="update" validator="required"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="subjetivo" :encounter="encounter_id" :save="update" validator="textRequired_XS"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="objetivo" :encounter="encounter_id" :save="update" validator="textRequired_XS"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="analisis" :encounter="encounter_id" :save="update" validator="textRequired_XS"></Element>
                        </v-col>
                        <v-col cols="6" md="3" sm="4" class="pt-0">
                            <Element type="plan" :encounter="encounter_id" :save="update" validator="textRequired_XS"></Element>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>

    </div>
</template>
<script>
import Element from '@/components/forms/FormElement.vue'
export default {
    data() {
        return {            
            saved: false
        }
    },
    props: {
        encounter: {
            type: String
        },
        update:{
            type: Boolean,
            default: false
        }
    },
    mounted() {        
    },
    methods: {       
    },
    computed: {
        encounter_id: function(){
            return this.$props.encounter
        }        
    },
    components: {
        Element
    }
}
</script>