<template>
    <div>
        <v-chip
            dark 
            :color="tag.color" 
            :close="close"
            @click:close="send_id()">
            {{tag.name}}
        </v-chip>
    </div>
</template>
<script>
    export default {

        data() {
            return {
                tag:this.$props.tag_item
            }
        },
        props: {
            tag_id: {
                type: String,
                default: ""
            }, 
            close: {},
            tag_item:{}
        },
        methods: {           
            send_id: function(){
                this.$emit('send_tag_id', this.tag.id)
            }
        }
    }
</script>