<template>
    <div>
        <v-row>
            <v-col>
                <TitleView title="Usuarios"></TitleView>
            </v-col>
            <v-col class="d-flex align-end flex-column">
                <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="800" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="indigo" v-bind="attrs" v-on="on"  @click="getdispatch" dark>
                            Crear usuario &nbsp; <v-icon>mdi-plus-circle-outline</v-icon> 
                        </v-btn>
                    </template>
                    <template v-slot:default>
                        <v-card>
                            <v-toolbar color="indigo lighten-1 text-h6" dark height="50">{{createForm==true ? "Nuevo usuario" : "Editar usuario"}}</v-toolbar>
                            <v-card-text>
                                <v-col cols="12" class="mt-5">
                                    <v-row>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                                            <BaseInput v-model="user.first_name" type="text" label="Nombre" validador="namesSurnamesRequired" element="element" ></BaseInput>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                                            <BaseInput  v-model="user.last_name" type="text" label="Apellido" validador="namesSurnamesRequired" element="element" ></BaseInput>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                                            <BaseInput  v-model="user.email" type="text" label="Correo Electronico" validador="emailRequired" element="element" ></BaseInput>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0" >
                                            <SelectSearch label="Indicativo" model="prefixes" v-model="user.prefix" :prefixes="true" validador="required"
                                            :valuex="user.prefix == null ? {id:prefix_default,text:prefix_default} : {id:user.prefix, text:user.prefix}"></SelectSearch>                                                            
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                                            <BaseInput  v-model="user.cellphone" type="text" label="Teléfono (WhatsApp)" validador="phoneRequired" element="element" ></BaseInput>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                                            <SelectSearch label="Pais Residencia" model="countries2" v-model="user.country_id" validador="required"
                                            :valuex="country_valuex"></SelectSearch>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0 pb-0" >
                                            <SelectSearch label="Ciudad" model="cities" v-model="user.city_id" :country="user.country_id" validador="required"
                                            :valuex="city_valuex"></SelectSearch>
                                        </v-col> 
                                        <v-col cols="12" sm="8" class="pt-3 pb-0" v-if="!createForm && showpassword == false">
                                            <v-btn outlined block small class="white--text mt-3" color="indigo" @click="showpassword = true" >
                                                <v-icon small>mdi-lock</v-icon> Cambiar contraseña
                                            </v-btn> 
                                        </v-col>
                                        <v-col  v-if="showpassword == true || createForm" cols="12" sm="4" class="pt-0 pb-0">
                                            <InputPassword v-model="user.password" @passwordVuex="putPasswordVuex"></InputPassword>
                                        </v-col>
                                        <v-col  v-if="showpassword == true || createForm" cols="12" sm="4" class="pt-0 pb-0">
                                            <InputPassword v-model="password_confirmation" confirmation label="Confirmación de contraseña" validador="password_confirmation"></InputPassword>
                                        </v-col>  
                                    </v-row>                                            
                                </v-col>
                                <v-col cols="12" class="mt-5">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header color="success">
                                                <h4 class="white--text">Nofiticaciones</h4>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-col>
                                                    <v-row class="notifications">          
                                                        <v-col cols="12">
                                                            <p class="mb-0 color-text-glya"> <b>Prioridades</b></p>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-checkbox
                                                            v-model="user.priority_high"
                                                            label="Alta"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-checkbox  
                                                            v-model="user.priority_medium"                                    
                                                            label="Media"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-checkbox
                                                            v-model="user.priority_low"
                                                            label="Baja"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <v-divider></v-divider>
                                                        <v-col cols="12">
                                                            <p class="mb-0 color-text-glya"><b>Medios de notificación</b></p>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-checkbox
                                                            v-model="user.media_whatsapp"
                                                            label="WhatsApp"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <v-col>
                                                            <v-checkbox
                                                            v-model="user.media_email"
                                                            label="Correo electrónico"
                                                            ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>                                        
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-end mt-3">
                                <v-btn @click="clearForm()" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                <v-btn v-if="verror" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small disabled> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                <v-btn v-else @click="saveAction()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>  
        <v-col>   
            <div>
                <v-alert v-if="messageResetSesion == true" shaped outlined prominent type="warning">
                Para actualizar tu zona horaria la sesión se cerrara en 10 segundos.
                </v-alert>
            </div>          
            <v-data-table :headers="headers" :items="users" :server-items-length="totalItems" class="elevation-1 GdiTable" item-key="name"
            :footer-props="{'items-per-page-options': [5, 10, 20],'items-per-page-text': 'Alertas por página'}"
            @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                <template v-slot:[`item.city_id_name`]="{item}">
                    {{item.city_id_name}} <span v-if="item.country_id_name">({{item.country_id_name}})</span>
                </template>
                <template v-slot:[`item.cellphone`]="{item}">
                    {{item.cellphone!=null ? item.prefix : ''}} {{item.cellphone}}
                </template>
                <template v-slot:[`item.actions`]="{item}">
                    <v-btn @click="openUpdate(item.id)" v-on="on" color="indigo lighten-1" outlined small>{{item.none}}<v-icon class="mr-1" small>mdi-pencil</v-icon> editar</v-btn> 
                </template>
            </v-data-table>
        </v-col>

    </div>
</template>
<script>
    import BaseInput from '@/components/forms_items/BaseInput.vue'
    import TitleView from '@/components/views/TitleView.vue'
    import SelectSearch from '@/components/forms_items/SelectSearch.vue';
    import AxiosServices from '@/services/AxiosServices'
    import InputPassword from '@/components/forms_items/InputPassword.vue'
    export default {
        name: "IndexUsers",
    
        mounted(){
            this.getUsers()
        },
        
        data(){
            return{  
                headers: [
                    { text: 'Nombres', value: 'first_name'},
                    { text: 'Apellidos', value: 'last_name' },
                    { text: 'Ciudad', value: 'city_id_name' },
                    { text: 'Email', value: 'email'},
                    { text: 'Teléfono (Whatsapp)', value: 'cellphone'},          
                    { text: 'Acciones', value: 'actions', active: true, sortable: false},
                ],
                users:[],
                createForm:false,
                show: false,
                user:{first_name:'',last_name:'',email:'',password:'', cellphone:'',prefix:'',country_id:null,media_email:false, media_whatsapp:false,priority_high:false,priority_medium:false,priority_low:false},
                dialog:false,
                test:2,
                on:false,
                password_confirmation:'',            
                idUserUpdate:'',
                resetSesion: false,
                messageResetSesion: false,
                showpassword: false,
                totalItems: 10,
                per_page:10,
                page:1,
            }

        },
        watch:{
            'user.city_id'(newValue, oldValue) {
                if (oldValue === undefined ){
                    this.resetSesion=false
                }else{
                    this.resetSesion=true 
                }
                
            },
            'user.country_id'(newVal, oldVal){
                if (oldVal != undefined && newVal != undefined) {
                    this.clean_city()
                }
            }
        },
        methods:{ 
            updatePage(page){
                this.page = page
                this.getUsers()
            },
            updateItemsPerPage(per_page){
                this.per_page = per_page
                this.page = 1
                this.getUsers()
            },
            clearForm:function(){
                this.$store.dispatch("reseterror")
                this.getUsers()
                this.resetUser()
                this.showpassword = false
            },
            clean_city:function(){
                this.user.city_id=null
            },
            getdispatch:function(){
                this.createForm = true
                this.$store.dispatch("reseterror")
            },
            putPasswordVuex:function(value){
                this.$store.dispatch('putPassword',value)
            },
            getUsers:function(){
                AxiosServices.userList({page: this.page, per_page: this.per_page}).then(res => {
                    this.users=res.data.data.data
                    this.totalItems = res.data.data.total
                    this.page = res.data.data.page
                }).catch(error => {
                    console.log(error);
                })
                this.resetUser()
            },
            userCreate(user){
                let user_full = {}
                user_full['user'] = user  
                user_full['user'].country_id == null ? this.country_id_default : user_full['user'].country_id           
                AxiosServices.userCreate(user_full).then(res => {
                    this.$store.dispatch("addNotification", res.data)
                    this.clearForm()
                }).catch(error => {
                    this.$store.dispatch("addNotification", error.response.data)
                    console.log(error);
                    this.clearForm()
                })
            },            
            userUpdate(user_id,user){
                AxiosServices.userUpdate(user_id,user).then(res =>{
                    this.$store.dispatch("addNotification", res.data)
                    this.clearForm()
                    this.$store.dispatch("reseterror")
                }).catch(error => {
                    console.log(error);
                    this.clearForm()
                })
            },
            saveAction:function(){
                if(this.createForm){
                    this.user.country_id = this.user.country_id == null ? this.country_id_default : this.user.country_id
                    this.userCreate(this.user)
                }else{
                    let user = {user:this.user}
                    this.userUpdate(this.user.id,user)
                    if ((this.user.id == this.loggedUser.id) && this.resetSesion == true) {
                        this.messageResetSesion = true
                        setTimeout(() => {
                            this.logOut()
                            }, 10000); 
                        
                    }
                }
            },   
            logOut(){
                this.$store.dispatch("logOut",this.$route.name);
                this.$store.commit('SET_LAYOUT', 'ClearLayout')
                if (window.location.pathname !== '/') {
                window.location.replace('/');
                } else {
                window.location.reload();
                }
            },   
            resetUser:function(){   
                this.$store.dispatch("reseterror")
                this.dialog = false
                this.user.first_name = ''
                this.user.last_name = ''
                this.user.email = ''
                this.user.prefix = ''
                this.user.cellphone = ''
                this.user.city_id = null
                this.user.country_id = null
                this.user.password = undefined
                this.password_confirmation = undefined
                this.user.media_email=false
                this.user.media_whatsapp=false
                this.user.priority_high=false
                this.user.priority_medium=false
                this.user.priority_low=false
                this.createForm = false
            },
            openUpdate:function(userId){
                this.clearForm()
                this.dialog=true
                this.createForm = false
                this.$store.dispatch("reseterror")
                AxiosServices.userDetail(userId).then(res => {
                    this.user=res.data.data
                    this.user.password=''
                    this.password_confirmation=''
                    this.idUserUpdate = userId                    
                }).catch((error) => {
                    this.$store.dispatch("addNotification", error.response.data);
                    this.$store.dispatch("render404");
                })            
            },
        
        },
        computed: {
            verror:function(){
                var show=false
                if (this.$store.state.form.reserror){
                    show=true
                }
                return show
            },
            city_valuex: function(){
                return this.user.city_id == null ? {id:null,text:''} : {id:this.user.city_id,text:this.user.city_id_name}
            },
            country_valuex: function(){
                return this.user.country_id == null ? {id:this.country_id_default,text:this.country_name_default} : {id:this.user.country_id,text:this.user.country_name}
            },
            load_country:function(){
                let ret = this.createForm
                if (!this.createForm){
                    ret = this.user.country_id>0 ? true : false
                }
                if (this.user.country == null){
                    ret = true
                }
                return ret
            },
            loggedUser:function(){
                return this.$store.state.user
            },
            country_id_default:function(){
                return  parseInt(localStorage.getItem('user_country_id'))
            },
            country_name_default:function(){
                return  localStorage.getItem('user_country_name')
            },
            prefix_default:function(){
                return  localStorage.getItem('user_prefix')
            },
        },
        components:{
            TitleView,
            BaseInput,
            SelectSearch,
            // FormCaregiver,
            InputPassword
        }
    }
</script>
<style scoped>
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }
    .notifications .col{
        padding-top: 5px;
        padding-bottom: 0;
    }
    .v-input--selection-controls > .v-input__control > .v-input__slot > .v-label{
        font-size: 15px;
    }
    .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 45px;
    }
    .v-divider {
        border-width: 0.5px;
    }
</style>