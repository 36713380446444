<template>
  <div>
    <div ref="chartdivAlertPerUsers" class="graph2"></div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AxiosServices from "@/services/AxiosServices";
export default {
  name: "alertsTotalChart",
  data() {
    return {
      data: [],  
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: async function () {
      this.buildchart()
      let users={}
      await AxiosServices.reportsClientAlertsOpenedMetrics(this.$store.state.user.client_id)
      .then(res => {
        Object.keys(res.data.data.by_user_and_priority).forEach(user_id => {
          AxiosServices.userDetail(user_id)
          .then(res => {
            users[user_id]=`${res.data.data.first_name} ${res.data.data.last_name}`;
          })
          .catch(error => {console.log(error);})         
        })
      })
      .catch(error => {console.log(error);})
      .finally(() => {
        AxiosServices.reportsClientAlertsOpenedMetrics(this.$store.state.user.client_id)
        .then(res => {
          let data = []
          Object.keys(res.data.data.by_user_and_priority).forEach(user_id => {
            let user_data_data = res.data.data.by_user_and_priority[user_id]
            user_data_data['user'] = users[user_id]
            data.push(user_data_data)            
          })  
          this.data=data
          
        })
        .catch(error => {
          console.log('error en get',error);
        })
        .finally(() => {
          this.makeseries();
        })
      })
    },  
    buildchart: function () {
      let root = am5.Root.new(this.$refs.chartdivAlertPerUsers);
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          pinchZoomX: false,
          pinchZoomY: false,
          panX: false,
          wheelX: false,
          wheelY: false,
          maxTooltipDistance: 0,
          layout: root.verticalLayout,
        })
      );
      var xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });
      xRenderer.grid.template.setAll({
        //location: 1,
        strokeOpacity: 0.0,
      });
      var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          numberFormat: "#",
          calculateTotals: true,
          renderer: xRenderer,
        })
      );
      let yRendered = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      });
      yRendered.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.9em",
      });
      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "user",
          renderer: yRendered,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
          
        })
      );

      this.xAxis = xAxis;
      this.yAxis = yAxis;
      //this.series = series;
      this.root = root;
      this.chart = chart;
      this.legend = legend;
      // this.makeseries();
    },
    makeseries: function () {
      let root = this.root;
      let data=this.data
      let seriesdef = [
        { nombre: "Bajo", cod: "low" },
        { nombre: "Medio", cod: "medium" },
        { nombre: "Alto", cod: "high" },
      ];
      let bar_color = {
        low: 0x1976d2,
        medium: 0xf9a825,
        high: 0xff5252
      };
      for (let element of seriesdef) {
          var series = this.chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
              name: element.nombre,
              stacked: true,
              xAxis: this.xAxis,
              yAxis: this.yAxis,
            //baseAxis:this.yAxis,
            //clickable: true,
              valueXField: element.cod,
              valueXShow: "valueX",
              categoryYField: "user",
              fill: am5.color(bar_color[element.cod]),
            })
          );
        series.columns.template.setAll({
          fillOpacity: 0.7,
          strokeWidth: 0,
        });
        series.bullets.push(function (root2,series2,dataitem2) {
          let visible=dataitem2.get("valueX")>0;
          // let visible=true
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueX}",
              //text: series.height(),
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              fontSize: 13,
              populateText: true,
              visible: visible,
              masa:(root2,series2) //JUAQUIN
            }),
          });
        });
        series.data.setAll(data);
        this.legend.data.push(series);
      }
        this.yAxis.data.setAll(data);
    },
  },
};
</script>
<style>
.graph2 {
  height: 300px;
}
</style>
