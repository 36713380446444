<template>
  <div>
    <!-- <v-col v-if="type==4">
      <v-row>
        <v-col class="pb-0 col-form-icon">
          <SelectSearch 
            class="serach-tag"
            :valuex="tag_id" 
            element="element" 
            icon="mdi-magnify" outlined 
            label="Etiqueta"
            :multiple="true" 
            model="tags">
          </SelectSearch>            
        </v-col>
  
        <v-btn @click="reload" color="indigo white--text" small class="mt-3">
          Filtrar
        </v-btn>
      </v-row>
    </v-col> -->
    <div ref="chartcompliance" class="graph2"></div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import AxiosServices from "@/services/AxiosServices";
// import SelectSearch from "../forms_items/SelectSearch.vue";
export default {
  name: "complianceChart",
  data() {
    return {
        data:[
            {labelm:"[bold]Incumplidas[/]",value:0,settings:{fill: am5.color(0xFF8686)}},
            {labelm:"Cumplidas\n[bold]sin recordatorio[/]",value:0,settings:{fill: am5.color(0x5E9FDF)}},
            {labelm:"Cumplidas\n[bold]con recordatorio[/]",value:0,settings:{fill: am5.color(0x4B5ED6)}}
        ],
        start_date:this.$props.start_at,
        end_date:this.$props.end_at,
        noData:false,
        tag_id:'none',
    }
  },
  props: {
    start_at:{default:(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0])},
    end_at:{default:new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]},
    reload:{default:false},
    type:{default:''}
  },
  mounted() {
    // this.buildchart();
    this.getData();
  },
  components:{
    // SelectSearch
  },
  methods: {
    getData:function () {
      this.noData=true
      AxiosServices.reportsClientMeasurementsAdherence(this.$store.state.user.client_id,this.start_date,this.end_date)
      .then(res => {
        let values =  Object.values(res.data.data)
        if (values.reduce((acumulador, valorActual) => acumulador + valorActual, 0) > 0 ){
          this.setDateFormat(res.data.data)
          this.noData=false
        } else {
          this.noData=true
        }
      })
      .catch(error => {
        console.log('error en get',error);
      })
      .finally(()=>{
        this.buildchart()
      })
    },
    reloadProps:function(){
      this.start_date=this.$props.start_at
      this.end_date=this.$props.end_at
    },
    setDateFormat:function(response){
      let build_data = [{labelm:"[bold]Incumplidas[/]",value:response['unfulfilled_activities'],settings:{fill: am5.color(0xFF8686)}},
            {labelm:"Cumplidas\n[bold]sin recordatorio[/]",value:response['fulfilled_activities_without_reminder'],settings:{fill: am5.color(0x5E9FDF)}},
            {labelm:"Cumplidas\n[bold]con recordatorio[/]",value:response['fulfilled_activities_with_reminder'],settings:{fill: am5.color(0x4B5ED6)}}]
      this.data=build_data
    },
    buildchart: function () {
      let root = am5.Root.new(this.$refs.chartcompliance);
      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          height:260,
        // centerX: am5.percent(25),
        // centery: am5.percent(100),
        })
      );

      let legend = chart.children.push(am5.Legend.new(root, {
  centerX: am5.percent(50),
  x: am5.percent(50),
  y: am5.percent(90),
  layout: root.gridLayout,
  clickTarget: "none"


}));

      chart.children.unshift(am5.Label.new(root, {
        text: "Adherencia\nMediciones ",
        fontSize: 16,
        fontWeight: "600",
        fontFamily:"Roboto Condensed",
        textAlign: "center",
        fill: am5.color(0x777777),
        x: am5.percent(50),
        centerX: am5.percent(50),
        y: am5.percent(45),
        centery: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
        
      }));

      

      // let series1 = chart.series.push(
      //   am5percent.PieSeries.new(root, {
      //     name: "Cumplimiento",
      //     fill: am5.color(0xffffff),
      //     categoryField: "labelm",
      //     valueField: "value",
      //     radius: am5.percent(95),
      //     innerRadius: am5.percent(50),
      //     alignLabels:false,
      //   })
      // );
      // series1.labels.template.setAll({
      //   fontSize: 12,
      //   fill: am5.color(0x777777),
      //   fontFamily:"Roboto Condensed",
      //   text: "{labelm}",
      //   textAlign:"start",
      //   oversizedBehavior:"truncate",
      //   //visible:false
          
      //   });
      // series1.slices.template.set("visible",false)
      // series1.ticks.template.setAll({stroke:am5.color(0x000000),strokeOpacity:0.5})
      // series1.data.setAll(this.data)
      
      // SERIES2 Esta serie es solo para insertar otro label... 
      let series2 = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Cumplimiento",
          categoryField: "labelm",
          valueField: "value",
          radius: am5.percent(95),
          innerRadius: am5.percent(50),
          // alignLabels: false,
          // labels:false,
          // strokeOpacity:0
          
        })
      );
      series2.labels.template.setAll({
     
        visible:false
    
        });
      series2.slices.template.setAll({
        fillOpacity:1,
        strokeOpacity:0,
        strokeWidth: 0,
        stroke:am5.color(0xffffff),
        templateField: "settings"
      });
      series2.ticks.template.set("visible",false)
      series2.data.setAll(this.data)
      legend.data.setAll(series2.dataItems);

      

    },
  },
  watch: {
    start_at(newValue, oldValue) {
      if (newValue != undefined && newValue != oldValue){
        this.reloadProps()
      }
    }    
  },
};
</script>
<style scoped>
.graph2 {
  height: 330px;
}
</style>
