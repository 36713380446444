<template>
    <v-data-table
    dense
    :headers="data.headers"
    :items="data.items"
    item-key="name"
    :search="search"
    class="elevation-1 GdiTable"
  >
  <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar...."
          class="mx-4"
        ></v-text-field>
  </template>
  
  <template v-slot:[`item.actions`]="{ item }">
    <template v-if="false">{{item}}</template>
    <v-row>

      <div v-show="ver">
        <v-icon small class="mr-2" @click="modaltest(item)"> mdi-eye {{item.showurl}}</v-icon>
      </div>
      <div v-show="editar">
        <v-icon small class="mr-2" @click="modaltest()" > mdi-pencil</v-icon>
      </div>
      <div v-show="borrar">
        <v-icon small class="mr-2" @click="modaltest()" > mdi-delete</v-icon>
      </div>
    </v-row>
  </template>

  </v-data-table>
</template>

<script>

/* eslint-disable */

import axios from "axios";
export default {

name: 'GdiTable',
mounted() {
  this.getTable();
              
},
data (){
  return {
    search: '',
    data:{headers:[4,4,4],items:[]},
    serverOptions:
      {
      page: 1,
      rowsPerPage: 20
      }
  }
},
methods: {
  getTable: function(){
    axios.get(this.$URL + `sdatatable/${this.$store.state.user.client_id}/${this.model}`).then(res=>{
      this.data=res.data
      console.log("trae esto",res);
    })
  },

  modaltest: function(item){
    console.log("elitem", item.nameurl);
    this.$router.push({name:item.nameurl,params:{id:item.showurl}})

  }
},
props:{
    model:{
      type:String
    },
    ver:{
      type:Boolean
    },
    editar:{
      type:Boolean
    },
    borrar:{
      type:Boolean
    }

  }
}


</script>
<style>
.GdiTable thead{
  background: #6371C2;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: rgb(255, 255, 255);
}
</style>
