<template> 
    <div v-if="client.have_metabase">
        <div class="metabasedash">
            <iframe
                :src=iframeUrl
                ref="metabaseIframe"
                frameborder="0"
                width="100%"
                height="1000"
                allowtransparency
                @load="adjustIframeHeight"
            ></iframe>
        </div>
    </div>
    <div v-else>
        Cliente sin BI
    </div>

</template>
<script>
    import AxiosServices from "@/services/AxiosServices";
    //import jwt from "jsonwebtoken";

    export default {
        name:"metabaseDash",
        created() {
            this.fetchclientdetails()
        },
        data(){
            return {
                iframeUrl:"https://www.google.com",
                client:{have_metabase:false}
            }
        },
        methods:{
            fetchclientdetails: async function(){
                await AxiosServices.clientDetail(this.$store.state.user.client_id).then(
                    res=>{
                        this.client=res.data.data
                        this.iframeUrl=res.data.data.metabase_url
                    }
                )
            },
            adjustIframeHeight() {
      // Ajusta la altura del iframe basado en el contenido
                const iframe = this.$refs.metabaseIframe;
                iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
                }
        }

    }
    
</script>

<style>
</style>