<template>
  <div>
    <v-card class="mx-auto" :class="redirectPoiniter ? 'pointer' : 'nopointer'">
      <v-card-title class="lighten-1 white--text" :class="colorestado(patient.status)" max-height="50px">
        <div  @click="redirectPatient">
          <h4 class="white--text info-card-patient" :class="redirectPoiniter ? 'pointer' : 'nopointer'">
              <span class="text-uppercase">{{patient.first_name }}  {{patient.last_name}}</span> | <v-chip class="white--text brightness" :class="colorestado(patient.status)">{{patient.status_name}}</v-chip>
          </h4>
        </div>
        <v-spacer></v-spacer>
        <div v-show="editform">
            <FormBasicData :patient_id="patient_id" :createsForm="false" modal_name="Editar datos basicos" @updatePatient="getPatient"></FormBasicData>
        </div>
      </v-card-title>
      <v-card-text @click="redirectPatient" class="text-body-2">
        <v-avatar size="100px" class="avatar-patient" :class="redirectPoiniter ? 'pointer' : 'nopointer'">                  
          <img alt="Femenino" v-if="patient.gender_name == 'Femenino'" :src="profileF">
          <img alt="Masculino" v-else-if="patient.gender_name == 'Masculino'" :src="profileM">
          <img alt="Indeterminado" v-else :src="profile">
        </v-avatar>
        <div class="info-card-patient mt-2">
          <v-row>
            <v-col cols="12" md="8">
              <p class="mb-0"> {{patient.id_type_name}} {{patient.nid}} / {{patient.country_name}}   
                <v-chip color="indigo" outlined small v-if="patient.time_zone != user.time_zone">
                  <v-icon x-small class="mr-2">mdi-clock</v-icon>{{ new Date() | formatDateSimple(patient.time_zone)}}
                </v-chip>  
              </p>
              <p class="mb-1"><span>{{ patient.gender_name }}</span>, {{patient.age}} años    | Grupo Sanguineo : 
                <span v-if="patient.rh_factor_name == null || patient.blood_type_name == null"> <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Dato Incompleto</v-chip> </span>
                <span v-else>{{patient.blood_type_name}} {{patient.rh_factor_name}}</span>
              </p>
              <p class="mb-1">Fecha de nacimiento: {{patient.birth_date}}</p>
              <p v-if="patient.country_id == 26" class="mb-1"> EAPB:
                <span v-if="patient.eapb_name==null"> <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Dato Incompleto</v-chip></span>
                <span v-else>{{patient.eapb_name}} / {{patient.regime_name}} </span> 
                  <!-- <v-chip class="pa-0 pl-3 pr-3" color="success" outlined></v-chip> -->
              </p>
            </v-col>
            <v-col cols="12" md="4" v-show="editform">
              <v-alert outlined class="pa-4 mb-1 riesgos alert-show" :color="statusbutton(patient.status)" prominent border="left" >
                <v-row class="d-flex justify-space-between ml-1">
                  <span :color="statusbutton(patient.status)" class="text-body-2"><b>Estado:</b> {{patient.status_name}}</span>
                  <span v-if="patient.status == 2" class="text-body-2 error--text">Para activar paciente, completa la <b>información de contácto.</b></span>
                  <v-col cols="4" class="pt-0 pb-0">
                    <div class="checkbox_item citem_1">
                      <label class="checkbox_wrap" >
                        <input @click="activate()" type="checkbox" v-model="status" name="checkbox" class="checkbox_inp" :disabled="!permissions.change_status" >
                        <span v-if="permissions.change_status" class="checkbox_mark" :disabled="!permissions.change_status"></span>
                      </label>
                    </div>
                  </v-col>
                  <v-dialog transition="dialog-bottom-transition" v-model="dialogStatus" max-width="500" persistent>
                    <template v-slot:default>
                      <v-card>
                        <v-card-text class="pb-0">
                          <v-col class="pa-5 mt-3 text-center">
                            <p>Recuerde que en estado <b>Inactivo</b>, se suspenderán también las órdenes y actividades que el paciente tenga programadas, dejará de recibir recordatorios y no podrá enviar sus mediciones.</p>
                            <p>¿Desea Inactivar a <b>{{patient.first_name }}  {{patient.last_name}}</b>?</p>
                          </v-col>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-end">
                          <v-btn @click="closeDialogStatus()" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                          <v-btn @click="saveAction()" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small> Inactivar <v-icon right dark > mdi-check</v-icon></v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-row>
              </v-alert>
              <v-alert v-if="patient.gateway=='' && patient.card_id==''" outlined class="pa-4 riesgos alert-show" color="error" prominent border="left" >
                <v-row class="d-flex justify-space-between ml-1">
                  <span class="text-body-2"> <v-icon color="error">mdi-router-wireless-off</v-icon>Sin Glady</span>
                </v-row>
              </v-alert>
              <v-alert v-else outlined class="pa-4 riesgos alert-show" color="success" prominent border="left" >
                <v-row class="d-flex justify-space-between ml-1">
                  <span class="text-body-2"> <v-icon color="success">mdi-router-wireless</v-icon> 
                    <v-icon v-show="patient.card_id!=''" class="mult-gateway" color="green darken-4" size="12px">mdi-account-multiple</v-icon>
                    {{patient.card_id!='' ? 'Glady Multipaciente' : 'Glady Asignado'}}</span>
                </v-row>
              </v-alert>
            </v-col>
                  <!-- <v-col cols="12" md="4">
                      <v-alert outlined class="pa-2 riesgos" icon="mdi-alert" color="warning" prominent border="left">
                          <div class="color-text-glya">
                              <h4>Alergias</h4>
                              <v-col class=" message-no-data pa-0">
                                  <h5>No registra alergias</h5>
                              </v-col>
                              <ul class="text-body-2">
                                  <li class="line-height-glya">Betalactámicos</li>
                                  <li class="line-height-glya">Metacarbamol</li>
                              </ul>
                          </div>
                        </v-alert>
                      </v-col> -->
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import FormBasicData from "@/components/forms/FormBasicData.vue";
import profileF from "@/assets/perfilesvacf2.jpg";
import profileM from "@/assets/perfilesvacm2.jpg";
import profile from "@/assets/perfilesvac.jpg";
import AxiosServices from "@/services/AxiosServices";

export default {
  props: {
    patient_id:{},
    editform: {
      type: Boolean,
      default: true,
    }
  },
  mounted() {
    this.getPatient()
  },
  data() {
    return {
      patient: { status: "Incompleto" },
      exteriorDate:'',
      profileF,
      profileM,
      profile,
      permissions:{},
      status:null,
      dialogStatus:false,
    };
  },
  methods: {
    colorestado: function (estado) {
      switch (estado) {
        case 0:
          return "grey";
        case 1:
          return "success";
        case 2:
          return "red";
        case 3:
          return "black";
      }
    },
    statusbutton: function (estado) {
      switch (estado) {
        case 1:
          return "success";
        case 0:
          return "red";
        default:
          return "grey";
      }
    },
    redirectPatient:function(){
      if (this.$route.name != 'patient' && this.$route.name != 'portal_patient'){
        this.$router.push({ name: 'patient', params: { id: this.patient_id, reload: true } }).catch(() => { })
      }
    },
    getPatient: function () {
      AxiosServices.patientDetail(this.patient_id).then((res) => {
        this.patient = res.data.data;
        this.permissions = res.data.data.permissions
        this.$emit('reloadData');
        this.patient.status == 1 ? this.status=true : this.status=false
      }).catch((error) => {
        if (error.code !='ECONNABORTED') {
          this.$store.dispatch("addNotification", error.response.data);
          this.$store.dispatch("render404");          
        }        
      });
    },

    activate() {
      if (this.patient.status == 1) {
        this.dialogStatus=true   
        this.patient.status = 0     
      } else {
        // Si la cuenta está desactivada, mostrar el modal de confirmación
        this.patient.status = 1
        this.saveAction()

      }
    },
    closeDialogStatus() {
      this.dialogStatus=false  
      this.status=true
      this.patient.status = 1     
    },
    saveAction() {
      AxiosServices.patientUpdate(this.patient.id, this.patient).then(res => {
        this.dialogStatus = false
        this.getPatient()
        this.$store.dispatch("addNotification", res.data)
        window.location.reload();
        }).catch((error) => {
        if (error.code !='ECONNABORTED') {
          this.$store.dispatch("addNotification", error.response.data);
          // this.$store.dispatch("render404");          
        }        
      });
    },
  },

  computed:{
    user:function(){
      return this.$store.state.user
    },
    redirectPoiniter(){
      let redirec = false
      if (this.$route.name != 'patient' && this.$route.name != 'portal_patient'){
        redirec = true
      }
      return redirec
    }
  },
  components: {
    FormBasicData,

  },
};
</script>
<style>
.header-card-patient {
  padding-left: 130px;
}
.info-card-patient {
  padding-left: 115px;
}
.avatar-patient {
  position: absolute !important;
  top: 15px;
}
.v-chip.v-size--default {
  height: 22px;
}
.pointer {
  cursor: pointer;
}
.nopointer {
  cursor:default;
}
.brightness{
  filter: brightness(90%);
}
.alert-show{
  max-width: 250px;
}
@media (max-width: 600px) {
  .header-card-patient {
    padding-left: 100px;
  }
  .info-card-patient {
    padding-left: 5px;
  }
  .avatar-patient {
    /* width: 70px !important;
    height: 70px !important;
    padding-right: 10px; */
    display: none !important;
  }
}




.checkbox_item .checkbox_wrap .checkbox_inp{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark{
	display: inline-block;
	position: relative;
	border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after{
	content: "";
	position: absolute;
	transition: all 0.5s ease;
}



/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark{
	background: red;
	width: 50px;
	height: 25px;
	/* padding: 2px; */
}
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:disabled, .checkbox_item.citem_1 .checkbox_wrap .checkbox_mark[disabled=disabled]{
	background: rgb(175, 175, 175);

}



.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before{
	top: 2px;
	left: 2px;
	width: 21px;
	height: 21px;
	background: #fff;
	border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
	background: #69cb69;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
	left: 26px;
}
.mult-gateway{
  position: absolute;
  background-color: white;
  border-radius: 10px;
  left: -7px;
  bottom: -8px;
}
</style>
