<template>
    <div cols="12" :class="margin">
        <label :class="element ? 'element-switch' : 'v-label'">{{label}}</label>
        <v-switch class="switchglya" :color="color" v-model="checkBox" @change="updateValue"
            :label="checkBox == false || checkBox == undefined ? negative : positive"></v-switch>
    </div>
</template>
<script>



export default {

    name: "CheckBox",   

    props: {
        color:{
            type:String,
            default: "primary"
        },
        label: {
            type: String
        },
        negative:{
            type: String,
            default:"Sí",
        },
        positive: {
            type: String,
            default:"No",
        },
        margin: {
            type: String,
            default:"",
        },
        element: {
            type: Boolean,
            default: false
        },

    },
    model: {
        prop: "value",
        event: "blur"
    },
    data() {
        return {
            checkBox:this.$attrs.value
        }
    },
    methods: {
        updateValue(value) {
            value=this.checkBox
            this.$emit('blur', value);
            this.$emit('changeglya');
        },
        changeglya() {
            this.$emit('changeglya');
        }, 
    },     
    components: {
    },

}

</script>
<style>
.switchglya .v-messages {
  display: none !important;
}
.switchglya .v-application--is-ltr .v-messages {
  display: none !important;
}
</style>